const ch = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/hedefleme-nasil-yapilir-hedefleme-turleri-nelerdir",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_targeting_text: "您可以設定廣告活動的目標對象策略。",
    campaign_form_budget_text:
      "選擇廣告活動的預算類型並設定預算金額。當花費達到設定的預算時，廣告活動將自動停止。",
    campaign_form_creative_text: "您可以上傳要在廣告活動中使用的內容。",
    invoice_modal_help_text:
      "如果點擊「選擇付款方式」按鈕以繼續操作，即視同您已確認即將支付的款項金額將無法退款。",
    invoice_general_error_modal_title: "餘額儲值失敗！",
    invoice_general_error_modal_text: "發生錯誤。",
    invoice_unknown_error_modal_text: "發生了意外錯誤。",
    invoice_product_id_error_modal_text: "商品ID 為必填欄位。",
    invoice_signature_error_modal_text: "簽名驗證失敗。",
    invoice_transaction_error_modal_text: "交易ID 已被使用。",
    invoice_store_error_modal_text: "該商店不屬於指定的公司。",
    invoice_balance_error_modal_text: "商店餘額不足。",
    invoice_error_modal_title: "餘額加值失敗！",
    invoice_error_modal_text: "發生錯誤。",
  },
  marketplaceDependentTable: {
    CPC: "CPC",
    CPM: "CPM",
    RoAS: "ROAS",
  },
  marketplaceDependentSettings: {
    api_link: "https://docs.gowit.com/reference/getting-started-1",
    auto_impression_link: "https://docs.gowit.com/reference/sendevent",
    auction_link:
      "https://docs.gowit.com/docs/what-type-of-auctions-are-offered",
    product_link: "https://docs.gowit.com/docs/filters#what-is-a-product-rate",
    stock_link: "https://docs.gowit.com/docs/filters#what-is-a-product-stock",
    targeting_link:
      "https://docs.gowit.com/docs/filters#what-is-a-demographic-targeting-feature",
    bidding_link: "https://docs.gowit.com/docs/bidding-types",
  },
  sider: {
    user: {
      campaign: "廣告活動",
      report: "報表",
      campaigns: "廣告活動",
      reports: "報表",
      invoices: "發票",
      academy: "學院",
    },
    admin: {
      campaign: "廣告活動",
      advertisers: "廣告主",
      agencies: "代理商",
      invoices: "發票",
      finance: "財務",
      transactions: "交易",
      coupons: "優惠券",
      access: "使用權限",
      users: "平台使用者",
      invited: "已邀請",
      synchronization: "同步紀錄",
      activity: "活動紀錄",
      report: "報表",
      settings: "設定",
      general: "一般資訊",
      integration: "整合資訊",
      advertiser: "廣告主設定",
      auction: "拍賣設定",
      single: "廣告活動設定",
      campaigns: "廣告活動",
      reports: "報表",
      admin_report: "報表",
      ad: "廣告",
      placement: "廣告版位",
      creatives: "廣告內容",
      review: "廣告審查",
      restrictions: "限制設定",
      console: "数据来源",
      dev: "Dev. Tools",
      auctions: "拍卖",
    },
  },
  components: {
    adminBar: {
      rn: "您目前正在查看",
      visible: "廣告主畫面。",
      back: "返回管理員檢視模式",
    },
    advertiserSelect: {
      placeholder: "選擇一個廣告主",
    },
    articles: {
      date: "您的廣告活動的開始和結束日期？",
      budget: "什麼是預算？選擇預算時要考慮那些因素？",
      bid: "什麼是出價？什麼是出價類型？",
      targeting: "目標類型及如何找到正確的目標受眾？",
      frequency: "報表上的傳送類型是什麼？",
      type: "如何製作自訂報表？",
      product: "選擇商品時應考量哪些因素？",
      store: "選擇商店時應考量哪些因素？",
    },
    breadcrumb: {
      finance: "交易",
      settings: "一般資訊",
      access: "平台使用者",
    },
    bulkUpdate: {
      list: "選取的清單",
      archive: "封存選取的項目",
      pause: "暫停選取的項目",
      activate: "啟用選取的項目",
      selected: "選取了 {{value}} 個單位",
      bid: "編輯選取的出價",
      bidLabel: "出價",
      bidHelp: "將更新選取的行的出價。",
      row: "{{value}} 行",
      count: "已選取：",
      updateAllBids: "編輯出價",
      updateAllBidsText: "您可以編輯表格上所有行的出價。",
      updatingAllBids: "您正在編輯所有行的出價。",
      updateAllBidsHelp: "表格上所有行的出價將被更新。",
    },
    datasource: {
      entity: "您的实体是什么？",
      method: "您的导入方法是什么？",
      configurations: "设置您的配置",
      mapping: "字段映射",
      emptyEntity: "您的<strong>实体</strong>是什么？",
      emptyEntityText:
        "选择您要添加数据的实体类型。此选择将定义您的数据结构，并确定导入所需的字段。实体可能包括产品或广告商。选择后，您将能够配置特定于此实体的数据字段。",
      productEntityText:
        "产品实体代表与单个产品相关的数据。包括产品ID、名称、描述、价格、筛选器和库存信息等字段。该实体旨在捕获和管理每个产品的详细信息。",
      advertiserEntityText:
        "广告商数据源表示与广告商相关的数据。包括广告商ID、名称和状态等字段。此数据类型旨在捕获和管理每个广告商的详细信息。",
      selectEntity: "选择实体",
      selectEntityDescription:
        "选择您要添加数据的实体类型。此选择将定义您的数据结构，并确定导入所需的字段。实体可以包括产品或广告商。选择后，您将能够配置与此实体相关的特定数据字段。",
      product: "产品",
      productText:
        "产品实体表示与单个产品相关的数据。它包括产品ID、名称、描述、价格、筛选器和库存信息等字段。此实体旨在捕获和管理每个产品的详细信息。",
      seeFields: "查看 {{value}} 数据模式",
      how: "查看使用方法",
      advertiser: "广告商",
      advertiserText:
        "广告商实体表示与广告商相关的数据。它包括广告商ID、名称以及其他相关详细信息。为了确保正确的集成，必填字段包括 'ID' 和 '名称'。此实体有助于组织和维护广告商的关键信息。",
      soon: "即将推出",
      schema: "{{value}} 数据架构",
      required: "必填",
      showFields: "查看其他字段",
      emptyMethod: "您的<strong>导入方法</strong>是什么？",
      emptyMethodText:
        "选择您希望从选定源导入数据的方法。根据您选择的方法，系统将自动调整导入过程，以正确处理数据。每种方法都有其优势，因此请选择最适合您需求的方法。",
      filledMethod: "导入方法：<strong>{{value}}</strong>",
      googleFeedUrl: "Google Feed URL",
      googleFeedFile: "Google Feed 文件上传 (XML)",
      googleFeedDirectXml: "直接 XML 上传（请求体）",
      filledMethodText:
        "使用此选项通过提供feed URL或上传支持格式的文件，从您的{{value}}中导入数据。",
      selectMethod: "选择导入方法",
      googleFeedText:
        "Google Feed用于通过提供feed URL或上传支持格式的文件，直接从您的Google Feed中导入数据。此功能通过在产品feed集成期间自动管理广告商创建来简化流程。",
      feedUrl: "Feed URL",
      feedUrlText: "从源头直接获取 Google Feed 数据的特定 URL。",
      fileUpload: "文件上传 (XML)",
      fileUploadText: "可以使用 XML 格式手动导入 Google Feed 文件。",
      requestBody: "直接XML上传（请求体）",
      requestBodyText:
        "一种直接集成方法，可通过请求正文中的 XML 导入 Google Feed 数据，而无需传统的文件上传。（功能即将推出",
      configTitle: "设置<strong>配置</strong>",
      configText:
        "定义数据源的基本设置。正确配置这些设置可确保数据导入过程顺利进行，并确保您的数据在系统中保持最新。这些设置在保存数据源后无法更改。",
      name: "数据源名称",
      nameHelp: "为此数据源配置输入一个唯一的名称。这将帮助您稍后识别该源。",
      url: "Feed URL",
      urlHelp: "提供可以获取feed的完整URL。系统将定期从此链接获取数据。",
      cycle: "选择周期",
      cycleHelp: "指定系统从提供的feed URL获取更新的频率。",
      fetch: "获取并映射",
      mappingTitle: "字段<strong>映射</strong>",
      mappingText:
        "在此部分中，您将把数据源中的字段映射到我们系统中的相应字段。系统字段具有特定要求，任何必需字段都必须正确映射，以确保成功导入。对于数据源中没有匹配数据的某些必需字段，您可以选择设置默认值。这有助于确保在导入过程中不会丢失关键信息。完成字段映射后，您必须验证映射，以确保所有必需字段都已正确匹配，然后再继续。",
      validate: "验证映射",
      system: "系统字段",
      imported: "已导入字段",
      preview: "预览",
      hideFields: "隐藏其他字段",
      feedUrlLink: "Google产品数据规范",
      upload: "上传 .XML 文件",
      uploaded: "文件已上传！",
      uploadText: "或拖放至此处",
      metaFeedText:
        "Meta Feed 支持使用各种格式导入数据，包括 Feed URL 和文件上传。其他支持的格式和字段配置将很快推出。",
      metaFeedUrlText: "用于直接获取 Meta Feed 集成数据的专用 URL 源。",
      metaFeedUrl: "Meta Feed URL",
      xml: "直接 XML 代码",
      xmlHelp: "直接输入 XML 代码以从您的源导入数据。",
      api: "API集成",
      apiText:
        "API 集成提供与指定 API 端点的连接，使数据同步得以实现，并将信息连续流入您的系统。",
      apiButtonText:
        "提供与指定 API 端点连接的数据检索设置。此集成通过向我们的 API 发送请求实现数据同步，确保更新信息在变化发生时直接流入您的系统。",
      impression: "曝光次數",
      impressionText:
        "展示实体表示与广告浏览相关的数据。这包括广告 ID、会话 ID 以及其他相关详细信息。此实体通过记录广告每次出现在屏幕上的情况来帮助衡量广告的可见性。",
      click: "點擊數",
      clickText:
        "点击实体捕获与用户点击广告时的交互相关的数据。字段包括广告 ID、会话 ID 以及其他相关详细信息，以提供用户互动的见解。此实体通过记录每次广告点击，支持对用户行为的详细分析。",
      sale: "銷售",
      saleText:
        "销售实体包含因广告互动而产生的所有转化或购买的数据。确保将所有销售事件发送给我们，以确保准确的跟踪和报告。这些数据使我们能够提供关于客户类型的详细见解，例如类别购买者、新客户和现有客户，以及直接和间接转化。",
      productSchema: {
        id: "产品ID",
        name: "产品名称",
        advertiser_id: "广告商ID",
        image_url: "产品图片的URL",
        description: "产品描述",
        price: "产品价格（多个地点返回-1）",
        filters: "应用于产品的筛选器",
        buybox: "指示产品是否有buybox",
        sub_class_names: "产品的子类名称",
        brand: "产品品牌",
        retailer_taxonomy: "产品的零售商分类",
        google_taxonomy: "产品的Google分类",
        product_type: "产品类型",
        global_identifier: "产品的全球标识符",
        global_identifier_type: "全球标识符类型（GTIN或ASIN）",
        rating: "产品评分",
        stock: "产品库存（多个地点返回-1）",
        sku: "产品SKU",
        barcode: "产品条形码",
        price_range: "产品价格范围",
        status: "产品状态",
        retailer_taxonomy_id_structure: "零售商分类ID结构",
        process_id: "流程ID",
      },
      advertiserSchema: {
        id: "广告商ID",
        name: "广告商名称",
        time_zone: "广告商的时区",
        email: "广告商的电子邮件地址",
        language: "广告商的语言",
        status: "广告商状态",
        process_id: "广告商的流程ID",
      },
    },
    download: {
      campaigns: "廣告活動",
      advertisers: "廣告主",
      agencies: "代理商",
      transactions: "交易",
      coupons: "優惠券",
      users: "平台使用者",
      sync_logs: "同步紀錄",
      reports: "報表",
      query: "成效報表",
      PRODUCTS: "商品",
      KEYWORDS: "關鍵字",
      NEGATIVE_KEYWORDS: "排除的關鍵字",
      AGE_RANGES: "年齡",
      CITIES: "城市",
      PLATFORMS: "平台",
      GENDERS: "性別",
      PLACEMENTS: "廣告版位",
      CUSTOMERS: "顧客",
      CATEGORIES: "類別",
      NEGATIVE_CATEGORIES: "排除的類別",
      CREATIVES: "廣告內容",
    },
    inbox: {
      title: "收件箱",
      empty: "您没有通知。",
      unread: "仅未读",
      default: "全部",
      archived: "已归档",
      readAll: "全部标记为已读",
      archiveAll: "全部归档",
      archiveRead: "归档已读",
      preferences: "通知偏好",
      global: "全局偏好",
      balance: "余额不足",
      categories: "出价过低（类别）",
      keywords: "出价过低（关键词）",
      products: "产品已停止",
    },
    info: {
      firstTipCreate:
        "透過推薦的設定輕鬆製作成功的廣告活動。推薦設定能幫您提升廣告成效。",
      secondTipCreate:
        "為了提高廣告活動的成效，您可以在廣告活動中選擇庫存充足、評分高，並且是在黃金購物車獲勝的商品。",
      thirdTipCreate:
        "提高廣告預算能夠增加您的商品的能見度，從而提高商品的點擊率和銷售量。",
      forthTipCreate:
        "透過在廣告活動中設定高出價便能確保您的商品在更好的版位顯示。",
      survey: "通过进行快速调查帮助我们改进。",
    },
    invite: {
      selected: "選取了 {{value}} 個單位",
      invite: "邀請已選取的項目",
      reinvite: "重新邀請已選取的項目",
    },
    modal: {
      campaign: {
        create: "新增廣告活動",
        success: "成功新增廣告活動",
        successText: "您可以點擊「確定」按鈕繼續前往廣告活動的詳細頁面。",
      },
      campaignDetails: {
        products: "商品選擇",
        keywords: "新增關鍵字",
        update: "更新廣告活動活動",
        negative_keywords: "新增排除關鍵字",
        products_btn: "新增商品",
        keywords_btn: "新增關鍵字",
        negative_keywords_btn: "新增排除關鍵字",
        update_btn: "更新",
        create_agency: "新增代理商",
        update_agency: "更新代理商",
        create_agency_btn: "新增",
        update_agency_btn: "更新",
        create_user: "新增平台使用者",
        create_user_marketplace: "新增管理員使用者",
        create_user_marketplace_btn: "新增",
        create_user_advertiser: "新增廣告主使用者",
        create_user_advertiser_btn: "新增",
        select_advertiser: "選擇廣告主",
        select_advertiser_btn: "繼續",
        update_advertiser: "更新廣告主",
        update_advertiser_btn: "更新",
        invoice: "餘額加值",
        invoice_btn: "選擇付款方式",
        coupons: "定義優惠券",
        coupons_btn: "定義優惠券",
        transactions: "餘額加值",
        transactions_btn: "餘額加值",
        categories: "新增類別",
        categories_btn: "新增類別",
        negative_categories: "新增排除的類別",
        negative_categories_btn: "新增類別",
        transaction_details: "交易詳情",
        settings: "儲存更改",
        settings_btn: "儲存",
        creatives: "新增廣告內容",
        creatives_btn: "新增廣告內容",
        approve_balance: "餘額加值",
        approve_balance_btn: "確認交易",
        approve_coupon: "新增優惠券",
        approve_coupon_btn: "確認優惠券",
        payment_options: "餘額加值",
        payment_options_btn: "儲值",
        add_user: "邀請新的平台使用者",
        add_user_btn: "寄送邀請",
        targeting_settings: "廣告主選擇",
        targeting_settings_btn: "選擇",
        update_transaction: "編輯發票號碼",
        update_transaction_btn: "儲存",
        select_user: "選擇平台使用者的類型",
        select_user_btn: "繼續",
        select_language: "更改語言",
        select_language_btn: "更改",
        campaign_form: "廣告活動摘要",
        campaign_form_btn: "新增廣告活動",
        update_bid: "編輯出價",
        update_bid_btn: "編輯",
        update_dayparting: "廣告投放時段",
        update_dayparting_btn: "更新",
        reject_creative: "拒绝创意",
        reject_creative_btn: "确认",
      },
      selectCampaign: {
        title: "選擇您的廣告活動類型",
        text: "請選擇您的廣告活動類型。",
        soon: "即將",
        sponsored_product_availability_title: "贊助商品",
        sponsored_product_availability_text:
          "向搜尋相關的關鍵字或查看類似商品的消費者推銷。",
        sponsored_brand_availability_title: "贊助品牌",
        sponsored_brand_availability_text:
          "用豐富、吸引人的廣告內容幫助消費者發現您的品牌和商品。",
        sponsored_display_availability_title: "贊助展示廣告",
        sponsored_display_availability_text:
          "透過觸及站內外的相關受眾來擴展業務。",
        sponsored_store_availability_title: "贊助商店",
        sponsored_store_availability_text:
          "擴大您的商店範圍，幫助消費者發現您的商店。提供鎖定目標的購物體驗。",
        sponsored_video_availability_title: "赞助视频",
        sponsored_video_availability_text:
          "通过视频广告的吸引元素接触您的目标受众并增加销售额。",
        sponsored_display_with_product_availability_title: "贊助展示廣告與產品",
        sponsored_display_with_product_availability_text:
          "透過針對站內外有相關興趣的消費者，有效推廣您的產品。",
        sponsored_video_with_product_availability_title: "贊助視頻廣告與產品",
        sponsored_video_with_product_availability_text:
          "使用動態且吸引人的視頻內容提升產品能見度並吸引受眾。",
      },
      success: {
        advertisers_success_title: "已寄出邀請",
        advertisers_success_text:
          "已寄送邀請信至您輸入的 Email 信箱。使用者需點擊邀請連結、建立帳號，才能使用帳號。",
        reports_success_title: "成功建立報表",
        reports_success_text: "點擊「確定」按鈕以繼續查看報表詳情頁面。",
        invited_success_title: "已邀請使用者",
        invited_success_text:
          "為了讓使用者出現在清單中，使用者必須點擊邀請信並新增密碼。",
        agencies_success_title: "成功新增代理商",
        agencies_success_text: "nan",
        multiple_advertisers_success_title: "邀請已寄出",
        multiple_advertisers_success_text: "選取的廣告主已被邀請。",
        invoice_success_title: "成功加值餘額！",
        invoice_success_text: "已成功加值餘額。您可以新增廣告活動了。",
        create_advertiser_success_title: "新增廣告主成功！",
        create_advertiser_success_text: "需邀請廣告主才能啟用。",
        payment_success_title: "已收到您的餘額加值請求！",
        payment_success_text:
          "資金轉帳或 EFT 付款獲得核准後，資金將轉入您的帳戶。審核過程可能需要 2 ~ 3 個工作日。",
        craftgate_success_title: "已收到您的餘額加值請求！",
        craftgate_success_text: "您的餘額即將轉入您的帳戶。",
        file_success_title: "文件上传成功！",
        file_success_text:
          "您的文件已成功上传并处理。您现在可以继续进行下一步。",
        xml_success_title: "XML 处理成功！",
        xml_success_text: "XML 数据已成功处理。您现在可以继续进行下一步。",
      },
      error: {
        craftgate_title: "無法加值餘額！",
        craftgate_text: "信用卡加值失敗。",
        file_title: "XML 文件上传错误",
        file_text:
          "处理您的 XML 文件时出现问题。请确保文件格式正确并符合所需结构。如问题仍然存在，请联系支持以获取进一步帮助。",
        xml_title: "XML 处理错误",
        xml_text:
          "处理 XML 数据时出现问题。请确保 XML 结构和内容正确。如果问题仍然存在，请联系支持以获得进一步帮助。",
      },
      warning: {
        campaign_warning_title: "您确定要离开此页面吗？",
        campaign_warning_text: "您所做的更改将不会被保存!",
      },
    },
    navbar: {
      search: "輸入內容以搜尋...",
      help: "協助和支援",
      site: "返回賣家面板",
    },
    navbarPopover: {
      account: "更換帳號",
      language: "更改語言",
      logout: "登出",
    },
    notification: {
      formTitle: "表格尚未完成填寫！",
      formText: "請填寫空白和必填欄位！",
      paused: "停止 {{value}} 成功。",
      active: "啟動 {{value}} 成功。",
      campaigns: "廣告活動",
      reports: "報表",
      products: "商品",
      keywords: "關鍵字",
      negative_keywords: "排除的關鍵字",
      categories: "類別",
      negative_categories: "排除的類別",
      placements: "廣告版位",
      users: "使用者",
      advertisers: "廣告主",
      agencies: "代理商",
      ad_placements: "廣告版位",
      creatives: "廣告內容",
      account_users: "使用者",
      statusError: "發生錯誤。",
      activeReport: "已封存報表。",
      archivedReport: "已取消封存報表。",
      activeCampaign: "已封存廣告活動。",
      pausedCampaign: "已封存廣告活動。",
      archivedCampaign: "已取消封存廣告活動。",
      activeAgency: "已封存代理商。",
      archivedAgency: "已取消封存代理商。",
      invitedAdvertiser: "已封存廣告主。",
      activeAdvertiser: "已取消封存廣告主。",
      archivedAdvertiser: "已封存廣告主。",
      archived_reports_bulk: "已封存報表。",
      archived_campaigns_bulk: "已封存廣告活動。",
      archived_users_bulk: "已封存使用者。",
      archived_advertisers_bulk: "已封存廣告主。",
      archived_agencies_bulk: "已封存代理商。",
      archived_account_users_bulk: "已封存使用者。",
      keyword: "請至少新增一個關鍵字。",
      category: "請至少新增一個類別。",
      invite: "{{value}} 封邀請信已成功寄出。",
      full_invite: "已成功寄出所有邀請信。",
      rejected: "已成功拒絕廣告內容。",
      accepted: "已成功驗證廣告內容。",
      uninvitedAdvertiser: "已成功啟用廣告主。",
      settings_success: "已成功儲存設定。",
      previous_invite_sent_too_recently:
        "{{value}} 封邀請信寄出得太頻繁。請稍後再試。",
      user_email_is_already_registered: "{{value}} 個 Email 信箱已註冊。",
      copiedCampaign: "已成功複製廣告活動。",
      copyError: "複製廣告活動時發生錯誤。",
      editableFieldError: "請輸入大於最小值的數值，否則自動調整。",
      active_account_user: "使用者已成功封存。",
      archived_account_user: "使用者已成功地取消封存。",
      bid: "已更新 {{value}} 行的出價。",
      newBid: "新的「出價」：{{value}}。",
      advertiserBalanceLowSubject: "您的余额即将用完！",
      advertiserBalanceLowBody:
        "您的广告主余额已降至临界水平。立即充值以确保您的广告不中断运行。",
      campaignKeywordsBelowMinBidSubject: "活动 #{{value}} 中的关键词出价过低",
      campaignKeywordsBelowMinBidBody:
        "您的活动 (ID: {{value}}) 中的所有关键词出价均低于市场的最低出价要求。更新您的出价以提高可见性。",
      campaignCategoriesBelowMinBidSubject: "活动 #{{value}} 中的类别出价过低",
      campaignCategoriesBelowMinBidBody:
        "您的活动 (ID: {{value}}) 中的所有类别出价均低于最低出价要求。更新您的出价以提高可见性。",
      campaignProductsStoppedSubject: "活动 #{{value}} 中的产品已停用",
      campaignProductsStoppedBody:
        "您的活动 (ID: {{value}}) 中的产品已暂停。请考虑添加新产品，以确保您的活动有效运行。",
      balanceAction: "充值余额",
      productAction: "添加新产品",
      bidAction: "更新出价",
      segments: "段",
    },
    suggestedTag: {
      text: "推薦",
    },
    campaignCard: {
      graph: "在圖表上顯示",
      hide: "隱藏",
      message: "過去 30 天",
    },
    campaignForm: {
      firstStep: {
        keywordStepTitle: "關鍵字資訊",
        categoryStepTitle: "類別資訊",
        targetingStepTitle: "受眾資訊",
        campaignInfoTitle: "廣告活動資訊",
        campaignInfoText:
          "輸入要顯示在廣告活動表上的名稱。顧客不會看到這個名稱。建議使用與想要打廣告的商品相關的名稱。",
        campaignLabel: "廣告活動的名稱",
        campaignSub: "輸入您的活動名稱。",
        dateInfoTitle: "廣告活動的日期",
        dateInfoText: "設定您的廣告活動執行的期間。",
        startDateLabel: "開始日期",
        startDateSub: "選擇廣告活動的開始日期。",
        stopped: "持續",
        stoppedText: "您的廣告活動將從今天開始，並持續執行到您停止活動為止。",
        stoppedSub: "開始「日期」：{{value}}",
        defined: "開始 - 結束日期",
        definedText: "您可以選擇廣告活動執行的日期範圍。",
        budget: "預算",
        totalBudget: "總預算上限",
        totalBudgetLabel: "總預算",
        dailyBudgetLabel: "每日預算",
        dailyBudgetExtra: "廣告活動每日的支出將是您設定的最大金額。",
        dailyBudgetFilter: "每日預算",
        dailyBudgetText: "您可以設定每日預算。",
        totalBudgetExtra: "您的廣告活動每天將支出您設定的最大金額。",
        flexibleBudgetLabel: "每日預算",
        totalBudgetFilter: "總預算",
        totalBudgetText: "您可以設定您的總預算。",
        bid: "出價",
        bidText:
          "設定您的廣告活動出價類型。您的廣告支出是根據您選擇的出價類型的每次點擊。",
        dynamicBid: "自動出價",
        dynamicBidText: "將自動決定每次點擊的花費。",
        targetAcosTitle: "目標 ACoS",
        targetAcosText: "選擇您希望將多少銷售收入用於廣告。",
        default: "預設",
        advanced: "進階",
        fixedBid: "固定出價",
        fixedBidText:
          "設定關鍵字和類別每次點擊的固定費用。您的廣告活動將根據您的目標偏好顯示給顧客。",
        productTitle: "商品選擇",
        productText: "選擇您想要打廣告的商品。",
        acosTooltip:
          "ACoS 代表單次銷售的廣告成本，亦即要用多少百分比的銷售收入來打廣告。例如，某一檔廣告活動的 ACoS 值如果是 10％，則每賣出 10 元就會花費 1 元的廣告費。",
        autoTargeting: "自動設定目標",
        autoTargetingText: "將鎖定最適合您的廣告活動的關鍵字或類別。",
        manualTargeting: "手動設定目標",
        manualTargetingText: "您可以鎖定要顯示您的廣告活動的關鍵字或類別。",
        suggestedBidText: "建議「出價」：",
        acosError: "ACoS 應介於 0001 ~ 100 之間。",
        creativeTitle: "廣告內容資訊",
        videoText: "您可以上傳要在廣告活動中使用的內容。",
        urlTitle: "導向網址",
        urlText:
          "這是使用者點擊您的廣告後被帶到的頁面。您必須將使用者導到商場上的頁面。",
        displayDraggerTitle:
          "上传 <strong>.PNG, .JPG, .GIF</strong> 文件以上传创意",
        displayDraggerText: "或拖放到此處",
        creativeLabel: "廣告內容名稱",
        size: "大小：",
        listWarningSize: "廣告內容的檔案大小上限為 5120 KB。",
        listWarningType: "適用 .jpg .png 和 .gif。",
        listWarningRecommended:
          "您可以查看廣告版位的廣告內容尺寸和適用的內容大小。",
        modalTitle: "廣告內容的尺寸",
        modalText:
          "您可以上傳所有列出的尺寸的廣告內容。上傳建議尺寸的廣告內容可提升廣告效果。",
        recommended: "建議大小",
        accepted: "可用大小",
        creativeError: "請輸入廣告內容的名稱。",
        urlError: "請輸入網址。",
        budgetError: "您的預算必須超過{{value}}。",
        budgetErrorMessage: "請輸入您的預算。",
        invalid: "無效的大小",
        advancedOptions: "進階選項",
        placements: "廣告版位",
        placementsText: "決定廣告要顯示在哪些區域。",
        in_search: "搜尋結果",
        in_search_text: "您的廣告顯示在相關的搜尋結果中。",
        in_product_detail_page: "商品詳情頁",
        in_product_detail_page_text: "您的廣告顯示在相關的商品詳情頁面上。",
        in_category: "類別",
        in_category_text: "您的廣告顯示在相關的類別頁面上。",
        in_home_page: "首頁",
        in_home_page_text: "您的廣告顯示在首頁上。",
        in_collection: "系列",
        in_collection_text: "您的廣告顯示在相關的系列頁面上。",
        storeTitle: "商店選擇",
        storeText: "選擇您想要投放廣告的商店。",
        info: "一般資訊",
        advertiser: "廣告主「名稱」：",
        budgetType: "預算「類型」：",
        bidType: "出價「類型」：",
        standart: "自動出價",
        fixed: "固定出價",
        max_acos: "目標 ACoS",
        fixed_info: "出價：",
        max_acos_info: "ACoS：",
        targeting: "目標對象",
        custom: "自訂",
        durationText:
          "如果您的廣告活動每天都達到每日預算目標，廣告活動將維持活躍狀態 <strong>{{value}} 天</strong>。",
        dayParting: "廣告投放時段",
        dayPartingText: "決定廣告活動將於星期幾和哪些時段保持活躍狀態。",
        allTimes: "全時段",
        allTimesText: "廣告活動將整天 24 小時保持活躍狀態。",
        scheduled: "選擇日期範圍",
        scheduledText: "指定廣告將在星期幾和哪些時段保持活躍狀態。",
        dayPartingTableText:
          "請勾選下列<strong>選取框</strong>指定要在星期幾和哪些時段投放。如需選擇一天當中的所有時段，您可以勾選<strong>全天</strong>的選項。",
        allDay: "全天",
        clear: "清除所選",
        creativeLanguageTitle: "创意语言",
        creativeLanguageText: "选择您的创意所用的语言。",
        creativeLanguageSearch: "搜索语言",
        autoTargetingProductWarning:
          "为了启用自动定位，必须从<strong>关联产品</strong>步骤中选择至少<strong>“1”</strong>个产品。",
        connectedProductsTitle: "关联产品",
        connectedProductsText: "与上述创意相关的，为您精心挑选的产品。",
        showProducts: "显示关联产品",
        showProductsText:
          "启用此选项以添加与您的创意相关的产品。关联相关产品有助于提高广告的相关性。",
        promoteProduct: "推广产品",
        promoteProductText:
          "此功能确保您添加到广告活动中的产品在显著位置展示。",
        promoteProductWarning: "请注意，一旦选择该选项，将无法修改。",
        closeProducts: "关闭关联产品",
        closeProductsText:
          "不建议禁用与创意相关的产品，这可能会降低广告的相关性。",
        spendingTitle: "支出分配",
        spendingText: "定义广告预算的时间分配和强度。",
        spendingSub: "确定您的支出分配。",
        asap: "尽快",
        front_loaded: "前载",
        evenly: "均匀",
        frontPercentage: "百分比",
        multiRegion: "区域选择",
        multiRegionText: "您可以在多个或特定区域运行您的广告活动。",
        allRegions: "所有区域",
        allRegionsText: "您的广告活动将会在所有区域发布。",
        selectedRegions: "选择的区域",
        selectedRegionsText: "您的广告活动将仅在选择的区域发布。",
        regionPlaceholder: "选择地区",
        evenlyText: "活动稳步进行，所有时间段均给予了相等的关注。",
        asapText: "预算快速消耗，以期在活动早期取得快速结果。",
        front_loadedText:
          "在初期集中大量精力，随后逐渐减少，以维持活动的长期存在。",
        displayUploadTitle: "通过图片文件创建创意",
        displayUploadText: "您可以使用 .JPEG、.PNG 或 .GIF 文件创建您的创意。",
        display_upload_second_drawer_title: "步骤 2：通过图片文件添加",
        display_upload: "上传图片",
        display_upload_drawer: "上传图片文件以在广告中显示。",
        changeDisplay: "更换图片",
        displayErrorTitle: "图片有错误！",
        displayErrorText: "您尝试上传的图片尺寸不正确。",
        newDisplay: "上传新图片",
        bulk: "批量上传",
        scriptUploadTitle: "通过脚本代码创建创意",
        scriptUploadText: "您可以使用脚本代码创建您的创意。",
        script: "脚本代码",
        script_drawer: "输入要在广告中显示的代码。",
        creativeSizeTitle: "广告尺寸",
        creativeSizeText: "为您的创意选择广告尺寸。",
        creativeSizeSearch: "选择尺寸",
        vast_second_drawer_title: "步骤 2：通过使用 VAST 标签添加",
        script_second_drawer_title: "步骤 2：通过使用脚本代码添加",
        video_upload_second_drawer_title: "步骤 2：通过视频文件添加",
        code: "编辑代码",
        preview: "预览",
        errorStates: {
          name: "請輸入廣告活動名稱。",
          keywordErr: "關鍵字必須是不重複的。",
          advertiserErr: "廣告主尚未啟用。",
          placementsError: "請至少選擇一個廣告版位。",
          productLimitErr: "最多可以加入 250 個商品。",
          sameKeyword: "目標關鍵字和否定關鍵字中不能包含相同的關鍵字。",
          duplicateKeyword: "關鍵字已經存在於廣告活動中。",
          duplicateNegativeKeyword: "否定關鍵字已經是廣告活動的一部分。",
          generalErr: "發生錯誤。",
          keywordLimitErr: "次广告活动最多可添加100个关键字。",
          categoryLimitErr: "次广告活动最多可添加100个类别。",
          frontPercentage: "前载百分比必须在0到100之间。",
          creativeDimensionErr: "请上传具有正确尺寸的创意。",
          languageErr: "请添加一种语言。",
          sizeErr: "请选择一个尺寸。",
          script: "请输入有效的 HTML 创意。",
        },
      },
      secondStep: {
        wordTargeting: "目標關鍵字",
        wordTargetingText: "決定要針對哪些關鍵字顯示廣告。",
        categorySub: "決定您的廣告要在哪些類別中顯示。",
        autoTargeting: "自動設定目標",
        autoTargetingText:
          "您的廣告將在最相關的關鍵字和類別中顯示。您可以在廣告活動詳情頁面上停止關鍵字。",
        manualTargeting: "手動設定目標",
        manualTargetingText: "您可以幫廣告設定關鍵字。",
        defaultBid: "預設出價",
        keywordTitle: "目標關鍵字",
        keywordText: "新增要顯示廣告的關鍵字。",
        negativeTitle: "排除關鍵字",
        negativeText: "新增您不希望顯示廣告的關鍵字。",
        placementTitle: "目標類型",
        placementText: "您可以針對特定關鍵字或類別讓廣告活動顯示。",
        search: "目標關鍵字",
        searchText: "設定廣告活動應顯示的目標關鍵字。",
        categories: "目標類別",
        categoriesText: "設定廣告活動應顯示的目標類別。",
        categoryTargeting: "目標",
        categoryTargetingTitle: "決定廣告將顯示在哪些類別中。",
        categoryTargetingText: "新增將顯示廣告的類別。",
        category: "目標類別",
        negativeCategoryTitle: "排除的類別",
        negativeCategoryText: "新增您不希望顯示廣告的類別。",
        autoCategoryText: "您的廣告將顯示在最適合的類別中。",
        manualCategoryText: "您可以設定廣告的目標類別。",
        placement: "目標",
        targetingText: "您可以為廣告活動設定目標策略。",
      },
      thirdStep: {
        targeting: "受眾選擇",
        targetingText: "決定哪些受眾將看到您的商品。",
        autoTargeting: "自動設定目標",
        autoTargetingText: "您的廣告將顯示在最相關的關鍵字和類別中。",
        manualTargeting: "手動設定目標",
        manualTargetingText: "您可以決定哪些受眾將看到您的商品。",
        platform: "目標平台",
        platformText: "決定要將廣告顯示給哪些平台使用者看。",
        tablet: "平板",
        mobile: "手機",
        app: "手機應用程式",
        desktop: "桌面",
        age: "目標的年齡範圍",
        ageText: "決定要向哪個年齡群打廣告。",
        city: "目標城市",
        cityText: "確定廣告要在哪些城市顯示。",
        allCities: "所有城市",
        selectedCities: "選取的城市",
        cityPlaceholder: "選擇城市",
        customer: "目標顧客",
        customerText: "根據購物紀錄決定要向那些顧客顯示廣告。",
        presentCustomers: "現有顧客",
        presentCustomersText: "目標對象是已訂購您的廣告商品的顧客。",
        newCustomers: "新顧客",
        newCustomersText: "目標對象是從未訂購過廣告商品的顧客",
        gender: "目標性別",
        genderText: "決定要向哪個性別顯示您的廣告。",
        female: "女性",
        male: "男性",
        audience: "受众选择",
        audienceText: "确定您希望广告展示的目标受众。",
        segment: "分段定位",
        segmentText: "确定您希望广告展示的目标分段。",
        allSegments: "所有分段",
        selectedSegments: "所选分段",
        segmentPlaceholder: "选择分段",
      },
    },
    columnPopover: {
      title: "編輯欄位",
    },
    empty: {
      product_title: "請選擇商品。",
      store_title: "請選擇商店。",
      dimensions_title: "您尚未新增維度。",
      product_text: "點擊左側商品清單上的新增按鈕來新增要打廣告的商品。",
      store_text: "您可以新增要打廣告的商店。",
      dimensions_text: "您可以新增維度來自訂廣告活動結果的詳細程度。",
      null_title: "沒有資料",
      null_text: "表格中沒有數據。",
      categories_title: "請新增目標類別。",
      categories_text:
        "輸入類別或點擊建議類別表上的新增按鈕，指定您希望廣告要在哪些類別中顯示。",
      keywords_title: "新增目標關鍵字。",
      keywords_text:
        "輸入詞彙或點擊建議關鍵字清單上的新增按鈕，指定廣告要針對哪些關鍵字顯示。",
      negative_keywords_title: "您尚未新增排除關鍵字。",
      negative_keywords_text: "輸入詞彙來指定不要顯示廣告的排除用關鍵字。",
      negative_categories_title: "您尚未新增排除的類別。",
      negative_categories_text:
        "您可以指定排除的類別，不讓廣告在那些類別中顯示。",
      campaigns_table_title: "您尚未新增廣告活動",
      campaigns_table_text: "點擊新增廣告活動來製作您的第一檔廣告活動。",
      reports_table_title: "您尚未製作任何報表",
      reports_table_text: "點擊新增報表來製作您的第一份報表。",
      invoices_table_title: "您尚未儲值任何餘額",
      invoices_table_text: "您可以聯絡您的業務經理以儲值廣告餘額。",
      advertisers_table_title: "您的帳號沒有連結任何廣告主",
      advertisers_table_text: "請聯絡您的客戶經理。",
      agencies_table_title: "您尚未新增任何代理商",
      agencies_table_text: "點擊「新增代理商」按鈕以加入新的代理商。",
      transactions_table_title: "您尚未儲值任何餘額",
      transactions_table_text:
        "點擊「餘額加值」按鈕後便能幫廣告主儲值廣告餘額。",
      coupons_table_title: "您尚未新增任何優惠券",
      coupons_table_text: "點擊「定義優惠券」按鈕來幫您的廣告主定義優惠券。",
      account_users_table_title: "您尚未新增任何平台使用者",
      account_users_table_text: "點擊「新增平台使用者」按鈕以新增使用者。",
      archived_table_reports_title: "沒有封存的報表",
      archived_table_campaigns_title: "沒有封存的廣告活動",
      archived_table_agencies_title: "沒有封存的代理商",
      archived_table_account_users_title: "沒有封存的使用者",
      archived_table_advertisers_title: "沒有封存的廣告主",
      invited_table_users_title: "沒有受邀的使用者",
      searched_table_title: "未找到相符的紀錄。",
      searched_table_text: "nan",
      searched_title: "未找到相符的紀錄。",
      searched_text: "nan",
      products_table_title: "您尚未加入任何商品。",
      products_table_text: "點擊新增商品按鈕來加入您要打廣告的商品。",
      keywords_table_title: "您尚未新增任何關鍵字。",
      keywords_table_text: "輸入詞彙來指定要顯示廣告的關鍵字。",
      categories_table_title: "您尚未新增任何類別。",
      categories_table_text: "輸入詞彙來指定要顯示廣告的類別。",
      negative_keywords_table_title: "您尚未新增任何排除的關鍵字。",
      negative_keywords_table_text:
        "輸入詞彙來指定不要顯示廣告的排除用關鍵字。",
      negative_categories_table_title: "您尚未新增任何排除的類別。",
      negative_categories_table_text: "您可以指定不希望廣告顯示的排除類別。",
      sync_logs_table_title: "沒有同步紀錄的資料",
      sync_logs_table_text: "沒有找到同步紀錄的資料。",
      ad_placements_table_title: "您尚未新增任何廣告版位。",
      ad_placements_table_text: "點擊新增廣告版位按鈕來新增廣告版位。",
      creatives_table_title: "尚未新增廣告內容！",
      creatives_table_text: "您可以稍後在此區塊查看新增的廣告內容。",
      creatives_relations_table_title: "您尚未新增任何廣告內容。",
      creatives_relations_table_text: "您可以從廣告活動表單中新增廣告內容。",
      creativeForm_title: "尚未新增任何廣告內容。",
      creativeForm_text: "廣告內容的建議「尺寸」：",
      invites_table_title: "您尚未邀請任何使用者。",
      invites_table_text: "點擊新增使用者按鈕來邀請使用者。",
      review_title: "目前沒有廣告內容需要審查。",
      review_text:
        "所有廣告內容都已批准或尚無新的廣告內容。待批准的廣告內容將顯示在這個畫面上。",
      accepted_table_creatives_title: "尚無已批准的廣告內容！",
      accepted_table_creatives_text: "所有廣告內容已被拒絕或尚無新的廣告內容。",
      rejected_table_creatives_title: "尚無被拒絕的廣告內容！",
      rejected_table_creatives_text: "所有廣告內容都已批准或尚無新的廣告內容。",
      pending_table_creatives_title: "尚未新增廣告內容！",
      pending_table_creatives_text:
        "所有廣告內容已完成審查或尚無新的廣告內容。",
      blacklist_table_title: "沒有找到關鍵字限制。",
      blacklist_table_text: "點擊「新增關鍵字」按鈕來新增限制。",
      cities_table_title: "您尚未加入任何城市。",
      cities_table_text: "nan",
      age_ranges_table_title: "您尚未加入任何年齡範圍。",
      age_ranges_table_text: "nan",
      genders_table_title: "您尚未加入任何性別。",
      genders_table_text: "nan",
      platforms_table_title: "您尚未加入任何平台。",
      platforms_table_text: "nan",
      customers_table_title: "您尚未加入任何顧客。",
      customers_table_text: "nan",
      report_loading_title: "報表產生中",
      report_loading_text:
        "報表產生中。根據報表的複雜度和資料量，可能需要一些時間才能完成。",
      data_sources_table_title: "未找到数据源",
      data_sources_table_text:
        "目前没有数据源。添加一个数据源以开始查看导入详细信息和历史记录。",
      logs_title: "未找到日志",
      logs_text:
        "当前此类别下没有记录的日志。一旦发生相关事件，日志将显示在此处。",
      segments_table_title: "您还没有添加任何分段。",
      segments_table_text: "",
    },
    endDatePicker: {
      label: "結束日期",
      campaigns: "選擇廣告活動結束的日期。",
      reports: "選擇報表的結束日期。",
      button: "無結束日期",
    },
    reportForm: {
      title: "報表資訊",
      nameTitle: "報表名稱",
      nameText: "輸入要在報表的表格中顯示的名稱。",
      nameSub: "輸入您的報表名稱。",
      reportTypeTitle: "提供方式",
      reportTypeText: "選擇提供報表的類型。產生的報表將根據您的偏好提供。",
      static: "固定",
      staticText: "報表只會跑一次並且在報表中顯示。",
      scheduled: "預約",
      scheduledText: "選擇報表提供的頻率和收件者。",
      frequency: "選擇報表的頻率。",
      daily: "每日",
      weekly: "每週",
      monthly: "每月",
      sendDate: "選擇您要收到第一份報表的日期。",
      firstDay: "每個月的第一天",
      lastDay: "每個月的最後一天",
      exportType: "選擇報表格式。",
      mail: "輸入要收到報表的 Email 信箱。",
      dateTitle: "報表日期",
      dateText: "選擇報表的開始和結束日期。",
      startDateSub: "選擇報表的開始日期。",
      reportKindTitle: "報表類型",
      reportKindText:
        "輕鬆透過預設報表來衡量成效。透過自訂報表功能，您可以新增維度、指標和篩選條件來製作自訂報表。",
      predefined: "預設報表",
      predefinedPlaceholder: "選擇預設報表的類型",
      special: "自訂報表",
      breaks: "維度",
      breaksText: "選擇報表中要包含的維度。",
      breaksPlaceholder: "搜尋維度",
      maxWarning: "*最多可以在報表中使用 4 個維度。",
      clear: "清除",
      metrics: "指標",
      metricsText: "選擇報表上要包含的指標。",
      filters: "篩選條件：",
      filtersText: "選擇維度和指標要套用的篩選條件。",
      product: "商品的成效",
      product_category: "類別的成效",
      product_brand: "品牌的成效",
      keyword: "關鍵字的成效",
      campaign: "廣告活動的成效",
      advertiser: "廣告主的成效",
      agency: "代理商的成效",
      errorStates: {
        date: "請選擇日期。",
        mail: "請輸入 Email 信箱。",
        type: "請選擇報表類型。",
        metric: "請至少選擇一個指標。",
      },
      dimensions: {
        campaignName: "廣告活動名稱",
        campaignBidType: "出價類型",
        campaignBudgetType: "預算類型",
        product: "商品",
        categoryName: "類別頁面",
        keywordText: "關鍵字",
        productBrand: "商品品牌",
        productName: "商品",
        day: "日",
        week: "週",
        month: "月",
        year: "年",
        hour: "小時",
        advertiser: "廣告主 ID",
        advertiserName: "廣告主",
        agency: "代理商 ID",
        agencyName: "代理商",
        customerDeviceType: "顧客裝置類型",
        saleProductName: "間接銷售商品",
        placementId: "廣告版位 ID",
        customerType: "顧客類型",
        productRetailerTaxonomy: "商品類別",
        adPlacementCustomKey: "廣告版位 ID",
        adPlacementName: "廣告版位名稱",
        placementText: "版位文字",
        in_search: "搜尋結果",
        in_category: "類別頁面",
        in_product_detail_page: "商品詳情頁",
        in_home_page: "首頁",
        campaignAdType: "廣告活動類型",
        campaignBudget: "預算",
        campaignFixedBid: "廣告活動固定出價",
        keywordBid: "關鍵字出價",
        categoryBid: "類別出價",
        advertiserResourceID: "賣家 ID",
        productSKU: "商品 ID",
        customerDeviceEnvironmentType: "顧客的裝置環境類型",
        campaignDailyBudget: "每日預算",
        campaignTotalBudget: "總預算",
      },
      metric: {
        sumImpression: "曝光數",
        sumViewableImpression: "可見曝光量",
        sumClick: "點擊數",
        CTR: "點擊率",
        CVR: "轉換率",
        sumSale: "銷售",
        ACoS: "ACoS",
        sumDirectSale: "直接銷售",
        sumIndirectSale: "間接銷售",
        sumDirectSaleAmount: "直接銷售金額",
        sumIndirectSaleAmount: "間接銷售金額",
        sumSaleAmount: "銷售金額",
        sumSpend: "支出",
        sale: "銷售",
        sumVideoStart: "视频开始",
        sumVideoComplete: "视频完成",
        VCR: "视频完成率",
        viewabilityRate: "可见率",
        deliveryRate: "投放率",
        adResponseYieldRate: "广告响应收益率",
        fillRate: "填充率",
        sumPostClickSale: "点击后销售",
        sumPostViewSale: "展示后销售",
        sumPostClickSaleAmount: "点击后销售金额",
        sumPostViewSaleAmount: "展示后销售金额",
        sumPostClickDirectSale: "点击后直接销售",
        sumPostClickIndirectSale: "点击后间接销售",
        sumPostViewDirectSale: "展示后直接销售",
        sumPostViewIndirectSale: "展示后间接销售",
        sumPostClickDirectSaleAmount: "点击后直接销售金额",
        sumPostClickIndirectSaleAmount: "点击后间接销售金额",
        sumPostViewDirectSaleAmount: "展示后直接销售金额",
        sumPostViewIndirectSaleAmount: "展示后间接销售金额",
        ACoSByClick: "点击后 ACoS",
        ACoSByView: "展示后 ACoS",
        RoASByClick: "点击后 RoAS",
        RoASByView: "展示后 RoAS",
        CVRByClick: "点击后轉換率",
        CVRByView: "展示后轉換率",
      },
    },
    selectFilter: {
      title: "篩選選項",
      placeholder: "選擇篩選條件",
      budgetOpt: "剩餘預算",
      budgetAdvertiser: "廣告主名稱",
      placeholderCondition: "選擇條件",
      bigger: "大於",
      smaller: "小於",
      equal: "等於",
      notEqual: "不等於",
      any: "包含",
      add: "新增篩選條件",
      placeholderValue: "選擇值",
      enter: "輸入值",
      date: "選擇日期",
    },
    searchBar: {
      keywords: "新增關鍵字...",
      negative_keywords: "新增關鍵字...",
      categories: "新增類別...",
      negative_categories: "新增類別...",
    },
    settingsHeader: {
      general: "一般資訊",
      generalText: "有關商場的一般資訊",
      integration: "整合資訊",
      integrationText: "執行資料傳輸所需的整合資訊",
      advertiser: "廣告主設定",
      advertiserText: "與廣告主相關的設定",
      auction: "拍賣設定",
      auctionText: "拍賣相關設定",
      campaign: "廣告活動設定",
      campaignText: "與設置廣告活動相關的設定",
      bid: "出價限制",
      bidText: "平台範圍內的出價規則設定",
      conversion: "轉換",
      conversionText: "與平台轉換目標相關的設定",
      category: "類別限制",
      categoryText: "限制您的平台上顯示的類別",
      keyword: "關鍵字限制",
      keywordText: "限制在您的平台上搜尋的關鍵字",
    },
    stepsNavbar: {
      report_create: "新增報表",
      campaign_create: "新增廣告活動",
      report_edit: "更新報表",
      step: "下一步是{{value}}",
      advertiser: "廣告主：",
    },
    suggestionCard: {
      addAll: "新增全部",
    },
    table: {
      keywords: {
        keyword: "關鍵字",
        matchType: "相符類型",
        suggested: "建議出價",
        dynamic: "自動",
        volume: "搜尋量",
        competition: "競爭",
        low: "少於1000",
        medium: "1K-5K",
        high: "5K-10K",
        highest: "10K以上",
        search: "新增關鍵字...",
        suggestTitle: "建議的關鍵字",
        suggestText: "針對您的商品所推薦的關鍵字。",
        negativePlaceholder: "新增排除關鍵字...",
        exact: "搜尋與您的關鍵字完全相符的詞彙。",
        phrase: "搜尋含部分關鍵字的詞彙，且詞彙的排列順序一樣。",
        broad: "搜尋含部分關鍵字的詞彙，忽略詞彙的排列順序。",
        warning: "最小值必須為 {{value}}。",
      },
      products: {
        productSearch: "搜尋商品、SKU 或條碼。",
        storeSearch: "搜尋商店",
        loading: "載入中",
        title: "請選擇商品。",
        text: "點擊左側商品清單上的新增按鈕來加入您想要打廣告的商品。",
        productSelected: "選取的商品",
        storeSelected: "選取的商店",
        remove: "全部移除",
        price: "價格",
        stock: "庫存",
        rating: "評分",
        drawerTitle: "批次選擇商品",
        sku: "SKU 或條碼清單",
        skuText: "在下方欄位中逐行輸入要新增的商品 SKU 或條碼。",
        categories: "所有類別",
        bulk: "批次選擇",
        all: "顯示全部",
        selected: "顯示選取的商品",
        total: "總共有 <strong>{{value}} 件商品</strong>。",
        variable: "變數",
      },
      categories: {
        suggestTitle: "推薦類別",
        suggestText: "為您的商品推薦的類別",
      },
      result: {
        campaignName: "廣告活動名稱",
        campaign: "廣告活動 ID",
        campaignBidType: "出價類型",
        campaignBudgetType: "預算類型",
        advertiserName: "廣告主名稱",
        agencyName: "代理商名稱",
        product: "商品",
        productName: "商品",
        categoryText: "類別",
        keyword: "關鍵字 ID",
        keywordText: "關鍵字的文字",
        keywordMatchType: "相符類型",
        productBrand: "商品品牌",
        day: "日",
        week: "週",
        month: "月",
        year: "年",
        hour: "小時",
        sumImpression: "曝光數",
        sumViewableImpression: "可見曝光量",
        sumClick: "點擊數",
        CTR: "點擊率",
        CVR: "轉換率",
        sumSale: "銷售",
        ACoS: "ACoS",
        sumDirectSale: "直接銷售",
        sumIndirectSale: "間接銷售",
        sumDirectSaleAmount: "直接銷售金額",
        sumIndirectSaleAmount: "間接銷售金額",
        sumSaleAmount: "銷售金額",
        sumSpend: "支出",
        campaignStartDate: "開始日期",
        campaignEndDate: "結束日期",
        campaignStatus: "狀態",
        campaignBudget: "預算",
        advertiser: "廣告主",
        agency: "代理商",
        weekOf: "第{{value}}週",
        customerDeviceType: "顧客裝置類型",
        saleProductName: "間接銷售商品",
        placementId: "廣告版位 ID",
        customerType: "顧客類型",
        categoryName: "類別頁面",
        productRetailerTaxonomy: "商品類別",
        adPlacementCustomKey: "廣告版位 ID",
        adPlacementName: "廣告版位名稱",
        placementText: "版位文本",
        in_search: "搜尋結果",
        in_category: "類別頁面",
        in_product_detail_page: "商品詳情頁",
        in_home_page: "首頁",
        in_collection: "系列",
        campaignAdType: "廣告活動類型",
        campaignFixedBid: "廣告活動固定出價",
        keywordBid: "關鍵字出價",
        categoryBid: "類別出價",
        advertiserResourceID: "賣家 ID",
        productSKU: "商品 ID",
        customerDeviceEnvironmentType: "顧客的裝置環境類型",
        campaignDailyBudget: "每日預算",
        campaignTotalBudget: "總預算",
        sumVideoStart: "视频开始",
        sumVideoComplete: "视频完成",
        VCR: "视频完成率",
        viewabilityRate: "可见率",
        deliveryRate: "投放率",
        adResponseYieldRate: "广告响应收益率",
        fillRate: "填充率",
        sumPostClickSale: "点击后销售",
        sumPostViewSale: "展示后销售",
        sumPostClickSaleAmount: "点击后销售金额",
        sumPostViewSaleAmount: "展示后销售金额",
        sumPostClickDirectSale: "点击后直接销售",
        sumPostClickIndirectSale: "点击后间接销售",
        sumPostViewDirectSale: "展示后直接销售",
        sumPostViewIndirectSale: "展示后间接销售",
        sumPostClickDirectSaleAmount: "点击后直接销售金额",
        sumPostClickIndirectSaleAmount: "点击后间接销售金额",
        sumPostViewDirectSaleAmount: "展示后直接销售金额",
        sumPostViewIndirectSaleAmount: "展示后间接销售金额",
        ACoSByClick: "点击后 ACoS",
        ACoSByView: "展示后 ACoS",
        RoASByClick: "点击后 RoAS",
        RoASByView: "展示后 RoAS",
        CVRByClick: "点击后轉換率",
        CVRByView: "展示后轉換率",
      },
    },
    states: {
      campaigns_not_started: "廣告活動尚未開始。",
      campaigns_date_passed: "廣告活動已超過預計結束的日期。",
      campaigns_status_paused: "您已暫停廣告活動。",
      campaigns_out_of_daily_budget: "您的廣告活動的每日預算已用完。",
      campaigns_out_of_total_budget: "您的廣告活動已達到總預算限制。",
      campaigns_products_stopped: "商品未啟用。",
      campaigns_keywords_stopped: "關鍵字未啟用。",
      campaigns_categories_stopped: "類別未啟用。",
      campaigns_status_archived: "您已封存廣告活動。",
      campaigns_creatives_stopped: "沒有活躍的活動廣告內容。",
      campaigns_insufficient_balance: "您的餘額不足。",
      campaigns_low_bid: "出價低於最低出價。",
      campaigns_regions_stopped: "地區未啟用。",
      products_status_paused: "您已暫停商品。",
      products_low_stock: "因庫存限制，狀態已停止。",
      products_low_rating: "因評分限制，狀態已停止。",
      products_buybox_loser: "因黃金購物車的限制，狀態已停止。",
      products_product_paused: "商品未啟用。",
      products_product_deleted: "商品未啟用。",
      keywords_status_paused: "您已暫停關鍵字狀態。",
      keywords_low_bid: "出價低於最低出價。",
      categories_status_paused: "類別定位已停止。",
      categories_low_bid: "出價低於最低出價。",
      creatives_paused: "您已暫停廣告內容的狀態。",
      creatives_pending: "廣告內容審核中。",
      creatives_rejected: "廣告內容被拒絕。",
      creatives_note: "拒绝原因:",
    },
    payment: {
      amount: "要「收取」金額：",
      title: "付款方式",
      subtitle: "廣告餘額將以含增值稅的價格開具發票給您。",
      wire_transfer: "銀行轉帳／電匯",
      beymen_milestone_payment: "里程碑付款",
      koctas_milestone_payment: "里程碑付款",
      bank: "銀行資訊",
      helpUpper:
        "您的付款經銀行轉帳或電匯批准後將轉出您的金額。審批過程可能需要",
      helpSecondary: "2 ~ 3 個工作日。",
      ref: "參考「編號」：",
      info: "在交易說明中正確輸入您的參考號碼對於追蹤轉帳而言非常重要。",
      tax: "增值稅：",
      verifyAmount: "從您的「帳戶」中提取的金額：",
      toters_milestone_payment: "從錢包轉帳",
      product_placeholder: "搜尋商店",
      craftgate: "信用卡",
    },
    tour: {
      prev: "返回",
      next: "下一步",
      done: "完成導覽",
      title: "歡迎來到廣告平台！",
      intro:
        "有關平台的使用說明，請點擊「開始」並完成平台的導覽。點擊退出按鈕便能跳過導覽。",
      menu: "功能欄",
      menuText: "用左側的功能欄切換頁面。",
      campaigns: "廣告活動",
      campaignsText:
        "在「廣告活動」頁面上，除了可以管理廣告活動，也能查看廣告的點擊數和銷售量。",
      reports: "報表",
      reportsText:
        "您可以挑選不同的維度來建立報表，並分析廣告的成效。您可以從這些報表查看廣告展示的次數、在哪裡顯示（例如搜尋結果或類別頁面）以及銷售數據。",
      reportsTextSub: "新增報表時，您可以選擇預設報表或新增自訂報表。",
      invoices: "發票",
      invoicesText:
        "在這個區塊，您可以查看為廣告活動儲值的餘額、獲得的優惠券以及所有的廣告費用。",
      academy: "學院",
      academyText: "您可以在這裡可以找到有關使用平台的必要資訊。",
      widgets: "統計數據",
      widgetsText:
        "您可以逐步查看您建立的廣告活動的成效。點擊「顯示圖表」按鈕查看每日廣告的成效的詳細資訊。",
      table: "廣告活動資料表",
      tableText:
        "您可以在這個區塊查看您建立的廣告活動及其成效。您可以隨時暫停廣告活動。點擊廣告活動名稱以查看詳細資訊。",
      button: "新增廣告活動",
      buttonText: "點擊「新增廣告活動」按鈕開始建立您的第一檔廣告活動。",
      modal: "廣告活動類型",
      modalText: "在這裡，您可以看到可選擇的廣告活動類型。",
      type: "贊助商品",
      typeText:
        "您可以建立一檔贊助商品的廣告活動，在搜尋結果或類別頁面的頂部版位顯示主打商品。",
    },
  },
  pages: {
    acc: {
      campaigns: {
        button: "新增廣告活動",
        placeholder: "搜尋廣告活動",
        errorTitle: "未選擇廣告主",
        errorText: "請選一個廣告主。",
        table: {
          standart: "自動",
          max_acos: "自動",
          fixed: "固定",
          daily: "每日",
          flexible: "每日",
          total: "總計",
          budgetLimit: "上限為 {{value}}",
          stopped: "您已停止廣告活動。",
          product: "贊助商品",
          display: "贊助展示",
          store: "贊助商店",
          default: "預設畫面",
          checkUp: "檢查畫面",
          display_with_product: "贊助展示廣告與產品",
          video_with_product: "贊助視頻廣告與產品",
        },
      },
      campaignDetails: {
        subtext: "您可以編輯廣告活動的資訊。",
        products: "商品",
        on: "活躍",
        keywords: "關鍵字",
        negativeKeywords: "排除關鍵字",
        targeting: "受眾",
        city: "城市",
        age: "年齡",
        gender: "性別",
        platform: "平台",
        customers: "顧客",
        placements: "廣告版位",
        in_search: "搜尋結果",
        in_category: "類別",
        placementPlaceholder: "搜尋廣告版位",
        date: "開始－結束「日期」：",
        bid: "出價：",
        maxAcos: "% 最大 ACoS",
        fixed: "固定",
        standart: "自動",
        daily: "每日",
        total: "總計",
        flexible: "每日",
        none: "無",
        keywordsBtn: "新增關鍵字",
        keywordsPlaceholder: "搜尋關鍵字",
        keywordsSub: "新增要展示廣告的關鍵字。",
        negative_keywords_btn: "新增排除關鍵字",
        negative_keywords_placeholder: "搜尋排除關鍵字",
        negative_keywords_modal_subtitle: "新增不要展示廣告的關鍵字。",
        negative_categories_btn: "新增排除類別",
        negative_categories_placeholder: "搜尋排除類別",
        negative_categories_modal_subtitle: "新增不要展示廣告的類別。",
        productsBtn: "新增商品",
        productsPlaceholder: "搜尋商品",
        productsSub: "選擇要打廣告的商品。",
        cities_placeholder: "搜尋城市",
        age_ranges_placeholder: "搜尋年齡範圍",
        genders_placeholder: "搜尋性別",
        platforms_placeholder: "搜尋平台",
        customers_placeholder: "搜尋顧客類型",
        new: "新顧客",
        present: "現有顧客",
        keywordTitle: "目標關鍵字",
        keywordText:
          "您的目標將自動鎖定關鍵字。您可以停止不想要當作目標的關鍵字。",
        maxAcosLabel: "最大 ACoS",
        fixedBidLabel: "固定出價",
        productsError: "至少要啟用一件商品。",
        keywordsError: "至少要啟用一個關鍵字。",
        categoriesError: "至少要啟用一個類別。",
        categories: "類別",
        categoriesBtn: "新增類別",
        categoriesPlaceholder: "搜尋類別",
        categoriesSub: "您可以新增目標類別。",
        negativeCategories: "排除類別",
        buyboxError: "商品不是黃金購物車得主。",
        ratingError: "商品評分低於最低評分。",
        stockError: "商品庫存低於最低庫存。",
        notActiveError: "商品未啟用。",
        creatives: "廣告內容",
        creativesBtn: "新增廣告內容",
        creativesPlaceholder: "搜尋廣告內容",
        creativesSub: "新增在廣告中要顯示的廣告內容。",
        creativesLinkTitle: "導向網址",
        creativesLinkText: "點擊您的廣告的使用者將被帶到 {{value}}。",
        winner: "勝者",
        loser: "敗者",
        creativesError: "至少要啟用一個廣告內容",
        pendingError: "不能啟用待審的廣告內容。",
        rejectedError: "不能啟用被拒絕的廣告內容。",
        in_product_detail_page: "商品詳情頁",
        in_home_page: "首頁",
        in_collection: "系列",
        scheduled: "已排期",
        dayParting: "您可以选择广告活动保持活跃的日期和时间段。",
        region: "区域:",
        regionLength: "{{value}} 区域",
        regionsDrawerTitle: "更新区域",
        languages: "{{value}} 语言",
        segments: "分段",
        segments_placeholder: "搜索分段",
      },
      noMatch: {
        title: "抱歉，我們找不到該頁面。",
        btn: "返回首頁",
      },
      reports: {
        button: "新增報表",
        placeholder: "搜尋報表",
        name: "報表名稱",
        table: {
          type: "報表類型",
        },
        form: {
          title: "報表1",
          goBack: "返回",
          create: "新增報表",
        },
      },
      reportDetails: {
        result: "報表結果",
        static: "固定",
        scheduled: "預約",
        ready: "預設報表",
        startDate: "開始日期",
        endDate: "結束日期",
        frequency: "頻率",
        firstDate: "首次發布日期",
        month: "每月的第一天",
        emails: "Email 信箱",
        export: "檔案類型",
        predefined: "預設的報表類型",
        total: "获取总统计数据",
      },
      invoice: {
        balance: "餘額",
        coupon: "優惠券",
        lastPayment: "上次儲值餘額",
        searchPlaceholder: "搜尋發票",
        duplicateCouponsError: "檢測到多個優惠券的定義。請稍候。",
        multipleTopUpsError: "檢測到多次餘額加值。請稍候。",
        limitError: "您正嘗試儲值大筆金額。請檢查金額。",
        minAmountError: "最低付款金額應為 {{value}}。",
        data: {
          typeBudget: "餘額支付",
          typeAd: "廣告費用",
          typeBudgetExplanation: "已儲值廣告餘額。",
          typeAdExplanation: "已支付廣告費用。",
        },
        table: {
          type: "交易類型",
        },
        modal: {
          button: "餘額加值",
          title: "餘額加值",
          subtitle: "輸入要轉入廣告餘額的金額。",
          loadingAmount: "金額",
          differentAmount: "輸入金額。",
          label: "金額",
        },
      },
      academy: {
        more: "進一步了解",
        copy: "複製連結",
        helpTitle: "找不到您的問題的答案？",
        helpText: "如有問題，您可以寄 Email 聯絡支援團隊。",
        contact: "聯絡我們",
        highlights: "亮點",
        read: "閱讀完整文章",
        description:
          "透過 {{value}} 廣告指南的文章，您可以瞭解有關該平台的所有資訊，並輕鬆開始發布您的廣告。",
      },
    },
    admin: {
      advertisers: {
        updateSubtext: "透過將廣告主分配到代理商來幫廣告主分組。",
        modalPlaceholder: "搜尋代理商...",
        search: "搜尋廣告主",
        select_advertiser_subtitle: "請選擇要幫哪一個廣告主新增廣告活動。",
        userSubtext: "您可以邀請廣告主帳戶的使用者。",
        help: "新增使用者的 Email 信箱。",
        deleteSuccess: "該代理商已成功刪除。",
        create: "新增廣告主",
        update: "更新廣告主",
        type: "廣告主「類型」：",
        custom: "自訂",
        customText: "在平台內設定為可共享的供應商的商品所建立的廣告主類型。",
        name: "廣告主名稱",
        nameHelp: "顯示的廣告主名稱。",
        agency: "代理商",
        agencyHelp: "廣告主所屬的代理商。",
        email: "Email 信箱",
        emailHelp:
          "輸入要收到邀請信的 Email 信箱。賣家登入平台時使用的是您輸入的 Email 信箱。",
        product: "商品名稱",
        brand: "品牌",
        retailer_taxonomy: "商品類別",
        advertiser_id: "廣告主 ID",
        filters: "篩選條件：",
        filterWarning: "請新增至少一個篩選條件。",
        typeTitle: "廣告主類型",
        typeSubtext: "您想更改上述所選廣告主類型中的哪一個？",
        default: "預設",
        defaultText: "適用於直接以自家名義在平台上銷售的賣家帳戶。",
        shareable: "可共享",
        shareableText:
          "適用於代表其他人在平台上銷售的賣家帳戶且必須分配給主賣家。",
        generalTitle: "基本資訊",
        generalSubtext: "關於廣告主的基本資訊",
        successText: "已新增所有符合您選擇的賣家品牌的商品。",
        advertiser: "廣告主：",
        drawerFilterWarning: "無法取消設定您新增的篩選條件！",
        filterTitle: "篩選條件",
        keyLabel: "選擇篩選條件",
        opLabel: "選擇條件",
        valueLabel: "輸入值",
        categorySelectLabel: "選擇類別",
        filterKeyError: "請選擇篩選條件。",
        filterOpError: "請選擇條件。",
        filterValueError: "請輸入值。",
        filterCategoryError: "請選擇類別。",
      },
      advertiserSettings: {
        title: "廣告主設定",
        subtext: "與廣告主相關的設定。",
        status: "預設的廣告主狀態",
        statusText:
          "如果新增到平台的廣告主的預設狀態為關閉，則必須在廣告主頁面上將狀態改成啟用，讓廣告主可以登入系統。",
        welcome: "迎賓優惠券",
        welcomeText: "您可以定義廣告主首次來到平台時可使用的迎賓優惠券。",
        warning: "如果您輸入「0」則不會為廣告主定義迎賓優惠券。",
        label: "優惠券餘額",
        language: "語言",
        languageText:
          "這是廣告主的預設介面語言。廣告主可以在自己的介面上更改語言設定。",
        balance: "授權餘額加值",
        balanceText: "這是廣告主授權透過界面將分期款項轉入廣告餘額。",
        open: "開啟",
        closed: "關閉",
        error_title: "您必須輸入一個值。",
        error_text: "如果您不想要定義優惠券，輸入「0」後點擊儲存按鈕。",
        error_text_input: "您必須輸入一個值。",
      },
      agencies: {
        label: "代理商名稱",
        subtext: "輸入代理商名稱",
        subtitle: "您可以新增代理商來幫廣告主分組",
        search: "搜尋代理商...",
        edit: "更新代理商名稱",
      },
      auctionSettings: {
        title: "拍賣設定",
        subtext: "與拍賣相關的設定",
        type: "拍賣類型",
        typeText:
          "選擇廣告主的出價要用哪種拍賣類型評估。您可以在此處查看有關拍賣類型的詳細資訊。",
        warning: "變更後將影響平台上的廣告支出。",
        firstOpt: "第一價格",
        firstOptText: "獲勝的廣告主將支付所出的出價。",
        secondOpt: "第二價格",
        secondOptText: "獲勝的廣告主支付比第二高的出價高1分。",
        link: "拍賣類型",
      },
      campaignSettings: {
        title: "廣告活動設定",
        subtext: "與設置廣告活動相關的設定",
        conversionRange: "轉換範圍",
        conversionRangeText:
          "您可以指定一段時間範圍，讓使用者在這段期間內的購買行為歸因於點擊了廣告。轉換範圍如果設定為 7 天，則使用者在點擊廣告的 7 天內購買商品後，廣告活動的餘額將被扣除。",
        product: "商品評分",
        productText:
          "為廣告主加到廣告活動中的商品設定最低評分要求，並為不符合最低評分要求的商品自訂行為。",
        minimum: "最低商品評分",
        minimumText: "輸入加到廣告活動中的商品的最低評分。",
        behaviour: "設定行為",
        behaviourText: "輸入加到廣告活動中的商品的最低評分。",
        placeholder: "選擇行為",
        stock: "商品庫存",
        stockText:
          "為廣告主要加到廣告活動中的商品設定最低庫存要求，並為不符合庫存要求的商品自訂行為。",
        minimumStock: "最低商品庫存",
        minimumStockText: "輸入要加到廣告活動中的商品的最低庫存量。",
        stockBehaviourText: "設定庫存量低於您選定的最低庫存時會發生的行為。",
        alert: "僅警告",
        block: "警告並封鎖廣告顯示",
        targeting: "人口統計目標",
        targetingText:
          "在廣告活動設定中，選擇是否要啟用人口統計目標的設定步驟。啟用後，廣告活動設定中將增加一個步驟，讓廣告主設定其目標偏好。",
        all: "所有廣告主",
        none: "沒有廣告主",
        selected: "已選取的廣告主",
        format: "廣告格式",
        sponsored: "贊助商品",
        brand: "贊助品牌",
        ad: "視覺廣告",
        rating_warn: "您的商品評分太低！將此商品加到廣告活動會拉低成效。",
        rating_block: "您的商品評分太低！您不能將這件商品加到廣告活動中！",
        stock_warn: "您的庫存太少！將這件商品加到廣告活動將拉低成效。",
        stock_block: "您的庫存太少！您不能將這件商品加到廣告活動中！",
        buybox_warn:
          "該商品不是黃金購物車的得主。將這件商品加到廣告活動將拉低成效。",
        buybox_block:
          "該商品不是黃金購物車的得主。您不能將這件商品加到廣告活動中！",
        buyBoxTitle: "黃金購物車條件",
        buyBoxText:
          "您可以針對廣告主要加到廣告活動中的商品設定黃金購物車得主的條件。",
        buyBoxSubtext:
          "決定加到廣告活動中的商品不是黃金購物車得主時會出現的行為。",
        noWorning: "無",
        warn: "更改後將影響所有活躍的廣告活動。",
        rangeError: "您必須輸入一個值。建議的值為 7 天。",
        rangeErrorInput: "只能輸入 1 - 14 天之間的值。",
        chooseBehaviour: "您必須選擇一個行為。",
        stockError: "您必須輸入最低庫存。",
        allStockError: "您必須輸入最低庫存並選擇行為。",
        ratingError: "您必須輸入最低評分。",
        allRatingError: "您必須輸入最低評分並選擇行為。",
        serverBlock: "警告並封鎖廣告顯示",
        advertisers: "已選取的廣告主",
        advertisersText: "只有您選擇的廣告主可以使用人口統計目標。",
        advertisersBtn: "新增廣告主",
        advertisersSub: "選擇可以設定人口統計目標的廣告主",
        advertisersPlaceholder: "選擇廣告主",
        advertisersLength: "已選取 {{value}} 個廣告主。",
        advertisersTitle: "廣告主",
        general: "一般",
        bid: "出價限制",
        conversion: "轉換",
        conversionSubtext: "與平台上轉換目標相關的設定",
        biddingTypes: "贊助商品可用的出價類型",
        biddingTypesText:
          "在新增廣告活動的步驟中，您可以指定廣告主可以使用的出價類型。",
        biddingTypesWarning: "至少要有一個選項必須保持開啟狀態。",
        auto: "自動出價",
        autoText:
          "自動決定出價的出價類型。支出是根據商品售價和您設定的目標 ACoS 計算。",
        acos: "目標 ACoS",
        acosText: "根據廣告主輸入的 ACoS 比例和商品售價自動計算出價。",
        fixed: "固定出價",
        fixedText:
          "由廣告主決定出價。廣告主可以幫整檔廣告活動設定每個關鍵字和類別的出價。",
        bidWarning: "必須選擇至少一種出價類型。",
        bidText: "與整個平台的出價規則相關的設定",
        bidLabelTitle: "整體最低 CPC",
        bidLabelText: "所有類別和關鍵字每次點擊的最低出價金額。",
        bidLabel: "最低 CPC",
        cpmTitle: "整體最低 CPM",
        cpmText: "所有類別和關鍵字每千次展示的最低出價金額。",
        cpmLabel: "最低 CPM",
        targetAcosTitle: "一般目標 ACoS",
        targetAcosText: "您可以在拍賣中預設目標 ACoS 的最大百分比。",
        targetAcosLabel: "最大 ACoS",
        categoryRelevanceTitle: "一般類別的相關性",
        categoryRelevanceText:
          "決定廣告展示中，贊助商品和自然列出的商品之間應該具有的相關程度。",
        categoryRelevanceLabel: "類別相關性",
        bidTabWarning: "變更後將影響平台上的廣告支出和曝光數。",
        inputErrorZero: "您必須輸入大於零的值。",
        inputErrorAcos: "您必須輸入介於 0 ~100 之間的值。",
        inputErrorRelevance: "您必須輸入大於零的整數。",
        inputError: "您必須輸入一個值。",
        productLink: "商品評分限制",
        stockLink: "商品庫存限制",
        targetingLink: "人口統計目標功能",
        bidLink: "出價類型",
        minThreshold: "最低销售阈值",
        minThresholdText: "这是产品参与拍卖的最低销售阈值。",
        minThresholdLabel: "最低销售阈值",
        minThresholdError: "请输入一个整数。",
        formatTitle: "按格式的归因设置",
        formatText:
          "触点的测量是为了确定其在不同广告格式中的有效性。根据格式，选择展示次数追踪可见性，而点击则聚焦于通过直接互动来衡量转化。",
        sponsoredProductTitle: "赞助产品",
        sponsoredProductText:
          "对于产品广告，转化通过用户与广告的互动来评估，重点在于互动或曝光是否推动了预期结果。",
        sponsoredDisplayTitle: "赞助展示",
        sponsoredDisplayText:
          "展示广告通过分析仅靠可见性是否会影响用户行动，或是否需要更深层次的互动来衡量转化。",
        sponsoredVideoTitle: "赞助视频",
        sponsoredVideoText:
          "对于视频广告，转化的衡量围绕着确定是仅观看内容还是主动互动对实现目标更为有效。",
        postView: "展示后",
        postClick: "点击后",
        reportTitle: "按报告划分转化设置",
        reportText:
          "此选项定义了如何为报告目的应用转化，并根据选定的方法组织和展示数据。",
        marketplaceReport: "市场报告中的指标分离",
        marketplaceReportText:
          "启用此选项后，市场报告中的指标将根据转化进行划分，从而更清晰地了解每个触点的表现。",
        advertiserReport: "广告商报告中的指标分离",
        advertiserReportText:
          "启用此选项后，广告商报告中的指标将根据转化进行划分，帮助您更好地理解不同活动互动的影响。",
        widgetTitle: "小组件转换设置",
        widgetText:
          "小组件转换评估用户在转换旅程中的交互。选择跟踪展示可以捕捉广告的可见性，而跟踪点击则以参与度作为转换的指标进行衡量。",
      },
      console: {
        add: "添加新数据源",
        advertiser: "广告主",
        product: "产品",
        google_feed_url_scheduler: "Google Feed URL",
        google_feed_file: "Google Feed 文件上传 (XML)",
        google_feed_body: "Google Feed 直接 XML",
        meta_feed_url_scheduler: "Meta Feed URL",
        minutes_15: "15分钟",
        minutes_30: "30分钟",
        hour_1: "1小时",
        hours_2: "2小时",
        hours_3: "3小时",
        hours_4: "4小时",
        hours_6: "6小时",
        hours_12: "12小时",
        hours_24: "24小时",
        history: "显示历史记录",
        logs: "显示日志",
        drawer: "日志查看",
        error: "错误",
        warning: "警告",
        success: "成功",
        info: "信息",
        prev: "前 50 条日志",
        next: "后 50 条日志",
        api: "API",
        impression: "曝光次數",
        click: "點擊數",
        sale: "銷售",
        test: "测试",
        curlTest: "测试视图",
        curlWarning: "重要提示：避免在生产环境中执行此操作！",
        request: "请求",
        run: "运行",
        response: "响应",
        hide: "隐藏原始",
        show: "显示原始",
        auctionLogs: "拍卖记录",
      },
      coupons: {
        add: "定義優惠券",
        title: "廣告主資訊",
        subtext: "您可以幫廣告主定義用於廣告活動支出的優惠券。",
        label: "有效期限",
        warning:
          "廣告主必須在輸入的日期前兌換優惠券。如果您不輸入有效日期，優惠券將永久地加到廣告主的餘額中，廣告主可以隨時使用該優惠券。",
        gift_coupon: "禮品券",
        welcome_coupon: "迎賓優惠券",
        gift_coupon_desc: "已定義禮品券。",
        welcome_coupon_desc: "已定義迎賓優惠券。",
        approve: "是否繼續定義優惠券？",
        note: "優惠券說明",
        noteHelp: "提供定義優惠券的原因，例如：黑色星期五優惠券",
      },
      createDatasource: {
        title: "添加数据源",
        save: "保存并运行",
        existsErr: "此URL的数据源已存在。请使用不同的URL。",
        feedErr: "对于此类型的集成，Feed URL不能为空。请提供有效的URL。",
        cycleErr: "对于此类型的集成，周期间隔不能为空。请提供有效的间隔时间。",
        permissionErr: "无法访问提供的URL。请检查URL或权限。",
        generalErr: "发生了一个错误。",
        validateSuccess: "验证成功",
        validateSuccessText: "所有必填字段已正确映射。准备继续。",
      },
      creatives: {
        all: "全部",
        accepteds: "已批准",
        rejecteds: "已拒絕",
        pendings: "待定",
        pending: "待定",
        accepted: "已批准",
        rejected: "已拒絕",
        adModalTitle: "廣告預覽",
        creativeModalTitle: "廣告內容預覽",
        creative: "廣告內容",
        name: "廣告內容名稱",
        search: "搜尋廣告內容",
        rejectSubtitle: "您可以输入拒绝创意的原因，也可以留空继续。",
        note: "请输入拒绝创意的原因。",
      },
      datasourceDetails: {
        entity: "实体:",
        import: "导入类型:",
        cycle: "周期间隔:",
        url: "Feed URL:",
        success: "成功",
        failure: "失败",
        in_progress: "进行中",
        partial_success: "部分成功",
      },
      generalInfo: {
        title: "一般資訊",
        subtext: "關於商場的一般資訊",
        market: "商場名稱",
        marketText:
          "介面上顯示的商店名稱。如需更改此資訊，請聯絡您的業務經理。",
        url: "網址",
        urlText:
          "在介面中和路由中使用的商店連結。如需更改此資訊，請聯絡您的業務經理。",
        country: "國家",
        countryText:
          "設定目標城市時使用的國家。如需更改此資訊，請聯絡您的業務經理。",
        zone: "時區",
        zoneText: "介面中使用的預設時區。如需更改此資訊，請聯絡您的業務經理。",
        currency: "幣值",
        currencyText: "介面上使用的貨幣。無法更改此資訊。",
        zoneIstanbul: "歐洲／伊斯坦堡",
        email: "支援的 Email 信箱",
        emailText: "您的賣家將聯絡的 Email 信箱。",
        emailLabel: "Email 信箱",
        emailError: "您必須輸入Email 信箱。",
        seller: "賣家面板",
        sellerText: "這是讓您的賣家直接透過介面進入您的面板的地址。",
        sellerLabel: "賣家面板",
        marketError: "您需要輸入商場名稱。",
        urlError: "您必須輸入網址。",
      },
      inspect: {
        campaign: "廣告活動：",
        advertiser: "廣告主：",
        url: "網址：",
        size: "大小：",
        status: "狀態：",
        keywords: "關鍵字",
        categories: "類別",
        languages: "语言：",
        regions: "地区：",
      },
      integrationInfo: {
        title: "整合資訊",
        subtext: "執行資料傳輸所需的整合資訊",
        api: "API 安全碼",
        apiText: "透過 API 存取平台所需的安全碼。",
        ftp: "FTP 安全證書",
        ftpText: "存取 FTP 伺服器所需的證書。",
        download: "下載",
        copy: "點擊以複製。",
        copied: "已複製",
        auto: "自動曝光",
        autoText:
          "這是自動記錄所有曝光紀錄而不需要任何事件整合的設定。開啟這個設定時，假設廣告回應中回傳的商品都在您的網站上顯示，則每個商品都會記錄查看指標。關閉此設定時，應根據所需的格式為每次的顯示傳送一個事件請求。",
        link: "曝光事件",
        apiLink: "API 端點",
      },
      transactions: {
        subtext: "您可以為廣告主設定用於其廣告活動支出的餘額。",
        warning: "您可以新增發票號碼。",
        balance_updated: "餘額支付",
        gift_coupon: "禮品券",
        welcome_coupon: "迎賓優惠券",
        refund: "退款",
        spend: "廣告成本",
        ad_balance_loaded_desc: "已儲值廣告餘額。",
        spend_desc: "已支付廣告費用。",
        gift_coupon_desc: "獲得禮品券。",
        welcome_coupon_desc: "獲得迎賓優惠券。",
        ad_balance_refunded_desc: "廣告餘額已退款。",
        credit_card_desc: "透過信用卡更新了餘額。",
        detailsSubtext: "您可以查看有關廣告支出的詳細資訊。",
        spendTab: "廣告成本",
        budgetTab: "預算資源",
        advertiser_budget: "餘額",
        approve: "要繼續儲值餘額嗎？",
        advertiser: "廣告主：",
        balance: "將「儲值」的餘額：",
        update: "您可以新增或編輯發票號碼。",
        title: "發票資訊",
        payment: "付款",
        success: "成功",
        waiting: "等待中",
        failure: "失敗",
        invoiceWarning: "最多可以輸入 30 個字元的發票號碼。",
        note: "餘額上傳註釋",
        noteHelp: "提供上傳此餘額的原因。例如：八月餘額",
      },
      users: {
        label: "Email 信箱",
        subtext: "將寄送確認郵件以進行邀請。",
        invite: "輸入將收到邀請的 Email 信箱。",
        warn: "您不能停止自己的帳戶。",
        error: "您已新增一個現有的 Email 信箱。",
        search: "搜尋平台使用者",
        select: "選擇您要新增的平台使用者類型。",
        advertiser: "廣告主",
        advertiserText:
          "只有您選擇的廣告主帳戶可以使用平台，該帳戶將會是執行廣告活動的人。",
        marketplace: "管理員",
        marketplaceText:
          "在廣告平台上擁有所有權限，可以授予或管理其他平台使用者的使用權限。",
        limited: "限制的權限",
        full: "完整的權限",
      },
      invited: {
        error: "最多每 10 分鐘可以寄出邀請信到同一個 Email 信箱。",
        success: "已重新邀請平台使用者。",
        search: "在已邀請的平台使用者中搜尋",
        title: "邀請詳情",
        info: "一般資訊",
        mail: "Email 信箱：",
        advertiser: "廣告主：",
        urlTitle: "邀請連結",
        link: "如何新增廣告主使用者？",
        single: "單一使用者",
        multiple: "批次使用者",
        advertiserText: "選擇廣告主。",
        draggerTitle: "選擇 <strong>.CSV</strong> 檔以批次上傳 Email 名單",
        draggerText: "或將檔案拖放到此處。",
        csvError: "上傳檔案時發生錯誤。",
        csvInfo:
          "您可以用 CSV 檔案幫不同廣告主帳戶批次邀請平台使用者。點擊下方的連結以瞭解有關 CSV 檔案格式的資訊。",
        restriction: "限制存取權限",
        restrictionText: "啟用此選項時，該使用者僅具有報表的權限。",
        edit: "編輯",
        role: "角色：",
        MARKETPLACE: "管理員",
        MARKETPLACE_REPORTER: "管理員－限報表檢視權限",
        ADVERTISER: "廣告主",
        ADVERTISER_REPORTER: "廣告主－限報表檢視權限",
      },
      sync: {
        file: "檔案",
        api: "API",
        feed: "資料源",
        products_created: "商品已建立。",
        products_updated: "商品已更新。",
        products_deactivated: "商品已停用。",
        products_activated: "商品已啟用。",
        missing_advertisers: "廣告主不存在。",
        advertisers_created: "廣告主已建立。",
        advertisers_updated: "廣告主已更新。",
        advertisers_deactivated: "廣告主已停用。",
        advertisers_activated: "廣告主已啟用。",
        categories_created: "已建立分類。",
      },
      placement: {
        add: "新增廣告版位",
        display: "顯示",
        product: "贊助商品",
        update: "更新廣告版位",
        settings: "一般設定",
        settingsText: "您的廣告版位的一般設定",
        name: "廣告版位名稱",
        nameText: "只有您能看到這個名稱",
        format: "廣告格式",
        formatText: "您的廣告版位將顯示的格式",
        sponsoredText: "相關關鍵字和類別中顯示的商品",
        displayText: "相關關鍵字和類別中顯示的視覺廣告",
        suggestedTitle: "建議尺寸",
        suggestedText: "廣告版位的實際大小。這個大小由廣告主顯示。",
        suggestedError: "最多可新增 1 個尺寸。",
        acceptedTitle: "適用尺寸",
        acceptedText:
          "可出現在您的廣告版位內的其他廣告尺寸。廣告主將顯示尺寸。",
        acceptedError: "請選擇廣告大小。",
        formatError: "請選擇格式。",
        cross_sell: "交叉銷售",
        crossText:
          "防止在相關商品詳細資訊頁面顯示廣告，並防止用列出的商品打廣告。",
        maxAds: "廣告數量上限",
        maxAdsText: "廣告版位可顯示的廣告數量上限。",
        crossTitle: "封鎖廣告的行為",
        crossSub: "廣告版位的實際大小。這個大小由廣告主顯示。",
        crossPlaceholder: "選擇類型。",
        crossProduct: "封鎖列出的商品",
        crossBlock: "封鎖廣告",
        crossError: "選擇行為。",
        targeting: "目標",
        targetingText: "選擇廣告版位群組以設定您的目標投放版位。",
        typeError: "選一個目標選項。",
        targetingPlaceholder: "目標版位",
        search: "搜尋結果",
        category: "類別頁面",
        homepage: "首頁",
        detail: "商品詳情頁",
        collection: "系列",
        minAds: "最低广告数量",
        minAdsText: "该投放位置可以拥有的最低广告数量。",
        minBid: "最低出价",
        minBidText: "这是该投放位置的最低出价金额。",
        videoWarning: "视频广告的广告数量最多为 <strong>“1”</strong>。",
        promoteText: "启用产品促销时，您只能添加一个广告。",
        displayWithProduct: "贊助展示廣告與產品",
        videoWithProduct: "贊助視頻廣告與產品",
        display_with_product: "贊助展示廣告與產品",
        video_with_product: "贊助視頻廣告與產品",
      },
      restrictions: {
        category: "類別",
        keyword: "關鍵字",
        categoryTitle: "根據類別的出價和 ACoS",
        categoryText:
          "您可以輸入僅在指定類別中有效的最低出價和目標 ACoS，並自訂類別相關性。相較於一般設定，系統將優先採用這個欄位的設定。",
        keywordTitle: "關鍵字黑名單",
        keywordText:
          "您可以針對指定的關鍵字限制廣告的顯示。限「獨家」類型的搜尋結果中，只有包含這個詞彙的商品才會是廣告商品。受限於「封鎖」類型的搜尋結果中將不顯示廣告。",
        placeholder: "搜尋關鍵字",
        add: "新增關鍵字",
        info: "一般資訊",
        label: "關鍵字",
        help: "限制搜尋結果用的關鍵字",
        type: "限制類型",
        typeText: "限制關鍵字搜尋結果的類型",
        exclusive: "排他",
        exclusiveText: "搜尋結果中，只有使用您的關鍵字的商品才會顯示。",
        block: "封鎖",
        blockText: "將封鎖搜尋結果中的廣告。",
        all: "显示全部",
        edited: "显示已编辑的类别",
        expand: "全部展开",
        collapse: "全部收起",
      },
    },
    auth: {
      title: "{{value}} 廣告平台",
      accounts: {
        title: "選擇帳號",
        text: "選擇要登入的帳號。",
        logout: "登出",
      },
      login: {
        title: "登入",
        text: "歡迎回來！請輸入您的資訊。",
        remember: "記住我",
        forget: "忘記密碼？",
        button: "登入",
        mail: "Email 信箱",
        password: "密碼",
        mailErr: "請輸入您的 Email 信箱。",
        passwordErr: "請輸入您的密碼。",
        incorrectPassword: "您的密碼不正確！",
        incorrectPasswordText: "請再試一次。",
        recaptcha: "reCAPTCHA 验证错误。",
        recaptchaText: "请再试一次。",
        notActive: "您的帳號已被暫停。",
        notActiveText: "請聯絡支援團隊。",
        multipleAccountErr: "註冊新帳號時發生錯誤。",
        notHaveAnAccount: "沒有註冊過帳號嗎？",
        signup: "註冊",
      },
      signUp: {
        title: "註冊",
        text: "歡迎，{{value}}",
        password: "密碼",
        passwordErr: "請輸入您的密碼。",
        warning:
          "請輸入至少 8 個字元的密碼，其中包含數字、標點符號、大寫和小寫英文字母。",
        already: "是否已經有帳號？",
        successBtn: "登入",
        success: "註冊成功！",
        successText:
          "您已成功完成了 {{value}} 郵件的註冊。點擊登入按鈕以前往面板。",
        help: "您需要什麼幫助？",
        err: "請輸入有效的密碼。",
        userExistErr: "此使用者已存在。請登入以查看您可以使用的帳戶。",
        privacyErr: "請接受所有必要的協議以完成註冊。",
        error: "沒有找到邀請。",
        errorText:
          "您需要收到邀請才能註冊。請透過 {{value}} 聯絡您的業務經理。",
        errorTextNoEmail: "您需要收到邀請才能註冊。請聯絡您的業務經理。",
        errorBtn: "返回首頁",
      },
      password: {
        title: "您忘記密碼了嗎？",
        text: "我們將寄出一個連結以便您重設密碼。請輸入您在平台上使用的 Email 信箱。",
        mail: "Email 信箱",
        mailInputErr: "請輸入您的 Email 信箱。",
        reset: "重設密碼",
        return: "前往登入頁面",
        error: "請輸入要重設密碼的 Email 信箱。",
        recaptchaError: "由于安全原因，找回密码尝试未成功，请刷新页面并重试。",
        check: "前往您的 Email 信箱查看",
        message: "我們寄送了密碼重設連結至 {{value}}。",
      },
      newPassword: {
        title: "設定新密碼",
        text: "新密碼必須與之前使用的密碼不同。",
        help: "輸入至少包含 8 個字元的密碼，包括數字、標點符號、大寫和小寫英文字母。",
        password: "密碼",
        passwordInputErr: "請輸入您的密碼。",
        again: "請再次輸入密碼",
        check: "密碼重設成功",
        message: "您可以用重設的新密碼登入。",
        errTitle: "請輸入有效的密碼。",
      },
      welcome: {
        title: "歡迎！",
        loginText: "如果您有帳號，請點擊登入按鈕以輸入您的資訊。",
        loginBtn: "登入",
        signupText:
          "如果還沒有註冊帳號，點擊下方的按鈕註冊後，設定您的密碼，然後登入您的帳號。",
        signupBtn: "註冊",
      },
    },
  },
  common: {
    save: "儲存",
    selectAll: "全選",
    search: "搜尋",
    edit: "編輯",
    export: "匯出",
    remove: "移除",
    add: "新增",
    column: "欄位",
    archive: "封存",
    removeArchive: "取消封存",
    delete: "刪除",
    filter: "篩選",
    continue: "繼續",
    cancel: "取消",
    cancellation: "取消",
    okay: "確定",
    create: "建立",
    goBack: "返回",
    listed: "已列出",
    archived: "已封存",
    invited: "被邀請者",
    exact: "完全相符",
    phrase: "部分詞彙相符",
    broad: "廣泛相符",
    turkey: "土耳其",
    abd: "美國",
    try: "土耳其里拉",
    usd: "美元",
    iqd: "伊拉克第納爾",
    twd: "新台幣",
    hkd: "港元",
    krw: "韓元",
    jpy: "日元",
    zone: "歐洲／伊斯坦堡",
    clear: "清除",
    completed: "完成",
    notCompleted: "未完成",
    now: "立即",
    checkAll: "全選",
    keyword: "關鍵字",
    negative: "排除關鍵字",
    tr: "土耳其語",
    en: "英語",
    day: "天",
    invite: "邀請",
    accept: "接受",
    reject: "拒絕",
    pageTotal: "顯示的行數：",
    addUser: "新增使用者",
    reinvite: "再次邀請",
    advertiser: "廣告主",
    admin: "管理員",
    new_customer: "新顧客",
    existing_customer: "現有顧客",
    category_buyer: "類別買家",
    invite_url: "查看邀請連結",
    copy: "建立副本",
    or: "或者",
    exit: "退出",
    docs: "查看我们的 API 文档，了解集成和广告投放的信息！",
    apply: "应用",
    reset: "重置",
    read: "标记为已读",
    unread: "标记为未读",
    view: "查看",
    days: {
      monday: "週一",
      tuesday: "週二",
      wednesday: "週三",
      thursday: "週四",
      friday: "週五",
      saturday: "週六",
      sunday: "週日",
    },
    languages: {
      arabic: "阿拉伯语",
      english: "英语",
    },
    table: {
      name: "名稱",
      status: "活躍",
      state: "狀態",
      agency_name: "代理商",
      type: "廣告主類型",
      resource_id: "賣方 ID",
      last_login: "上次登入日期",
      balance: "餘額",
      sum_coupons_amount: "優惠券",
      total_balance: "總餘額",
      impressions: "曝光次數",
      viewable_impressions: "可見曝光量",
      clicks: "點擊",
      CTR: "點擊率",
      spend: "廣告支出",
      sale_amount: "銷售金額",
      direct_sale_amount: "直接銷售金額",
      indirect_sale_amount: "間接銷售金額",
      sale: "銷售",
      direct_sale: "直接銷售",
      indirect_sale: "間接銷售",
      ACoS: "ACoS",
      CVR: "轉換率",
      campaign_name: "名稱",
      advertiser_name: "廣告主",
      ad_type: "廣告類型",
      start_date: "開始日期",
      end_date: "結束日期",
      budget_type: "預算",
      bid_type: "出價類型",
      fixed_bid: "出價",
      custom_key: "ID",
      ad_format: "廣告格式",
      max_ads: "廣告數上限",
      recommended_size: "建議尺寸",
      sizes: "尺寸",
      image_url: "廣告內容",
      policy_status: "狀態",
      transaction_date: "日期",
      invoice_number: "發票號碼",
      transaction_type: "交易類型",
      description: "說明",
      created_by: "製作者",
      amount: "金額",
      spend_details: "詳情",
      note: "備註",
      expire_date: "到期日期",
      account_user_status: "活躍",
      user_email: "Email 信箱",
      account_user_role: "授權",
      user_last_login: "上次登入日期",
      account_user_created_at: "建立日期",
      email: "Email 信箱",
      account_role: "授權",
      created_at: "邀請日期",
      report_name: "名稱",
      report_start_date: "開始日期",
      report_end_date: "結束日期",
      report_type: "報表類型",
      report_delivery_frequency: "頻率",
      report_created_at: "建立日期",
      text: "名稱",
      minimum_bid: "最小 CPC",
      minimum_cpm: "最小 CPM",
      relevance: "相關性",
      restriction_type: "限制類型",
      row_count: "行數",
      message: "作業訊息",
      sync_type: "同步類型",
      file_name: "檔案名稱",
      product: "商品",
      sku: "SKU",
      buybox: "黃金購物車",
      city: "城市",
      age_range: "年齡",
      gender: "性別",
      platform: "平台",
      customer: "顧客",
      category: "類別",
      keyword: "關鍵字",
      match_type: "符合的類型",
      bid: "投標",
      category_volume: "曝光量",
      active: "啟用中",
      paused: "已暫停",
      invited: "已邀請",
      uninvited: "未邀請",
      archived: "已封存",
      on: "啟用中",
      stopped: "已暫停",
      open: "開啟",
      closed: "關閉",
      coupon: "優惠券",
      sync_log_created_at: "日期",
      category_text: "類別",
      keyword_text: "關鍵字",
      creative_name: "名稱",
      invoice: "發票號碼",
      acos: "ACoS",
      sum_click: "點擊數",
      sum_direct_sale: "直接銷售",
      sum_direct_sale_amount: "直接銷售金額",
      sum_impression: "曝光數",
      sum_sale: "銷售",
      sum_sale_amount: "銷售金額",
      sum_spend: "支出",
      spending_type: "支出分配",
      min_ads: "最小广告数量",
      min_bid: "最小出价",
      languages: "语言",
      video_start: "视频开始",
      video_complete: "视频完成",
      VCR: "视频完成率",
      entity: "实体",
      import_method: "导入方法",
      schedule_interval: "周期",
      finish_date: "结束日期",
      id: "ID",
      uuid: "UUID",
    },
    equality: {
      contains: "包含",
      not_contains: "不包含",
      equal: "等於",
      not_equal: "不等於",
      less_than: "小於",
      grater_than: "大於",
      greater_or: "大於或",
      less_or: "小於或",
      any_of: "任一",
      none_of: "不是任一",
    },
    filters: {
      campaignName: "名稱",
      campaignStartDate: "開始日期",
      campaignEndDate: "結束日期",
      campaignStatus: "活躍",
      campaignState: "狀態",
      campaignBudget: "預算",
      campaignBudgetType: "預算類型",
      campaignBidType: "出價類型",
      campaignMaxAcos: "最大 ACoS",
      campaignFixedBid: "固定出價",
      campaignAdType: "廣告類型",
      sumSale: "銷售",
      sumSpend: "支出",
      ACoS: "ACoS",
      productName: "商品",
      productCampaignStatus: "狀態",
      sumClick: "點擊",
      CVR: "轉換率",
      keywordCampaignStatus: "狀態",
      keywordText: "關鍵字",
      keywordMatchType: "符合的類型",
      negativeKeywordCampaignStatus: "狀態",
      negativeKeywordText: "排除關鍵字",
      negativeKeywordMatchType: "符合的類型",
      cityName: "城市",
      cityCampaignStatus: "狀態",
      ageRangeText: "年齡",
      ageRangeCampaignStatus: "狀態",
      genderText: "性別",
      genderCampaignStatus: "狀態",
      platformText: "平台",
      platformCampaignStatus: "狀態",
      customerTargetingText: "客戶",
      customerTargetingCampaignStatus: "狀態",
      placementText: "廣告版位",
      placementCampaignStatus: "狀態",
      reportName: "報表名稱",
      reportStartDate: "開始日期",
      reportEndDate: "結束日期",
      reportType: "報表類型",
      reportDeliveryFrequency: "頻率",
      reportCreatedAt: "建立日期",
      agencyName: "代理商名稱",
      advertiserName: "廣告主",
      advertiserStatus: "廣告主狀態",
      sumSaleAmount: "銷售金額",
      sumDirectSale: "直接銷售",
      sumIndirectSale: "間接銷售",
      sumDirectSaleAmount: "直接銷售金額",
      sumIndirectSaleAmount: "間接銷售金額",
      sumImpression: "曝光數",
      productBrand: "商品品牌",
      CTR: "點擊率",
      categoryName: "類別",
      advertiserId: "廣告主 ID",
      agencyId: "代理商 ID",
      name: "商品名稱",
      brand: "品牌",
      retailer_taxonomy: "商品類別",
      advertiser_id: "廣告主 ID",
      transaction_type: "交易類型",
      advertiser_name: "廣告主",
      created_by: "製作者",
      campaignDailyBudget: "每日預算",
      campaignTotalBudget: "總預算",
    },
    equalityValues: {
      ct: "包含",
      ni: "不包含",
      eq: "等於",
      ne: "不等於",
      in: "任一",
      notin: "不是任一",
      lt: "小於",
      gt: "大於",
      ge: "大於或",
      le: "小於或",
    },
    values: {
      ACTIVE: "活躍",
      PAUSED: "已暫停",
      DAILY: "每日",
      TOTAL: "總計",
      STANDART: "自動",
      FIXED: "固定",
      MAX_ACOS: "最大 ACoS",
      WEEKLY: "每週",
      MONTHLY: "每月",
      EXACT: "完全相符",
      PHRASE: "部分詞彙相符",
      BROAD: "廣泛相符",
      BALANCE_UPDATED: "餘額支付",
      GIFT_COUPON: "禮品券",
      WELCOME_COUPON: "迎賓優惠券",
      SPEND: "廣告成本",
      NOT_STARTED: "廣告活動尚未開始。",
      DATE_PASSED: "廣告活動已超過預計結束的日期。",
      STATUS_PAUSED: "您已暫停廣告活動。",
      OUT_OF_DAILY_BUDGET: "您的廣告活動的每日預算已用完。",
      OUT_OF_TOTAL_BUDGET: "您的廣告活動已達到總預算限制。",
      PRODUCTS_STOPPED: "商品未啟用。",
      KEYWORDS_STOPPED: "關鍵字未啟用。",
      CATEGORIES_STOPPED: "類別未啟用。",
      STATUS_ARCHIVED: "您已封存廣告活動。",
      CREATIVES_STOPPED: "沒有活躍的活動廣告內容。",
      INSUFFICIENT_BALANCE: "您的餘額不足。",
      LOW_BID: "出價低於最低出價。",
      REGIONS_STOPPED: "地區未啟用。",
      PRODUCT: "贊助商品",
      BRAND: "贊助品牌",
      DISPLAY: "贊助展示廣告",
      STORE: "贊助商店",
      VIDEO: "赞助视频",
      DISPLAY_WITH_PRODUCT: "贊助展示廣告與產品",
      VIDEO_WITH_PRODUCT: "贊助視頻廣告與產品",
    },
    month: {
      may: "五月",
      june: "六月",
      september: "九月",
      october: "十月",
      december: "十二月",
    },
  },
};

export default ch;
