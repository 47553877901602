import useApi from "api";
import { Auth, AuthContext } from "context/AuthProvider";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

const useConfigSchema = (enabled: boolean, id = null, queryFilter = "") => {
  const { api, baseURL, roleForDashboard } = useApi();

  const { authToken } = useContext(AuthContext) as Auth;

  const [isEnabled, setIsEnabled] = useState(false);

  const marketplaceRequiresAdvertiserDataAccess =
    id && roleForDashboard === "MARKETPLACE";

  const url = marketplaceRequiresAdvertiserDataAccess
    ? `/advertisers/${id}/configs?group=${queryFilter}`
    : `/configs?group=${queryFilter}`;

  useEffect(() => {
    setIsEnabled(baseURL() !== undefined && authToken !== "" && enabled);
  }, [baseURL, authToken, enabled]);

  const { isLoading, error, data } = useQuery(
    ["configSchema", queryFilter],
    () => {
      const data = api.get(url);
      return data;
    },
    {
      enabled: isEnabled,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return { isLoading, data, error };
};

export default useConfigSchema;
