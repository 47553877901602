import {
	Input,
	Spin,
} from "antd";
import useApi from "api";
import { ReactComponent as LeftOutlined } from "assets/icons/leftOutlined.svg";
import { ReactComponent as RightOutlined } from "assets/icons/rightOutlined.svg";
import cc from "classcat";
import RetailCreativeInspectCard from "components/Card/RetailCreativeInspectCard";
import Empty from "components/Empty";
import RetailFormInput from "components/Form/RetailFormInput";
import RetailPageContainer from "components/Layout/RetailPageContainer";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailText from "components/Typography/RetailText";
import useSettings from "hooks/useSettings";
import useTableFetch from "hooks/useTableFetch";
import {
	ChangeEvent,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { PolicyStatus } from "utils/types";

import cm from "./style.module.scss";

const InspectPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const { data, isLoading, pageSize, page, changePage, changePageSize } =
    useTableFetch("creatives", false, {}, undefined, [
      {
        key: "policy_status",
        op: "eq",
        value: "PENDING",
      },
    ]);

  const { data: settings } = useSettings("MARKETPLACE");

  const [current, setCurrent] = useState(0);

  const [allCreatives, setAllCreatives] = useState<{
    records: any[];
    total: number;
  }>({
    records: [],
    total: 0,
  });

  useEffect(() => {
    if (Array.isArray(data?.data?.records) && data?.data?.records?.length > 0) {
      setAllCreatives((prevCreatives) => {
        const combinedRecords = [
          ...prevCreatives?.records,
          ...data?.data?.records,
        ];

        const uniqueRecords = Array.from(
          new Map(combinedRecords.map((record) => [record.id, record])).values()
        );

        return {
          records: uniqueRecords,
          total: data?.data?.total_records,
        };
      });
    }
  }, [data]);

  useEffect(() => {
    if (data?.data.total_records) changePageSize(1, data.data.total_records);
  }, [changePageSize, data?.data.total_records]);

  const [rejectModalStates, setRejectModalStates] = useState<{
    visible: boolean;
    note: string;
    record: any;
  }>({
    visible: false,
    note: "",
    record: {},
  });

  const notLastCard = current !== allCreatives.total - 1;

  const increment = () => {
    notLastCard && setCurrent((curr) => curr + 1);

    const comparablePageSize = page * pageSize - 1;

    if (current === comparablePageSize) changePage(page + 1);
  };

  const decrement = () => current !== 0 && setCurrent((curr) => curr - 1);

  const updateValue = async (data: any) => {
    const response = await api.patch(`creatives/${data.id}`, {
      policy_status: data.updated_status,
      review_note:
        rejectModalStates.note !== "" ? rejectModalStates.note : null,
    });
    return response;
  };

  const { mutateAsync, isLoading: mutationLoading } = useMutation(updateValue);

  const changeValue = async (data: any, status: PolicyStatus) => {
    try {
      const createdData = { ...data, updated_status: status };
      await mutateAsync(createdData).then(() => {
        setAllCreatives((prev) => ({
          records: prev.records.filter((item) => item.id !== createdData.id),
          total: prev.total - 1,
        }));
        if (!notLastCard) decrement();
      });
    } catch (err: any) {
      console.error(err);
    }
  };

  const onRejectOk = () => {
    changeValue(rejectModalStates.record, "REJECTED");
    setRejectModalStates({
      visible: false,
      note: "",
      record: {},
    });
  };

  const onRejectCancel = () => {
    setRejectModalStates({
      visible: false,
      note: "",
      record: {},
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRejectModalStates({
      ...rejectModalStates,
      note: e.target.value,
    });
  };

  const handleReject = (record: any) => {
    setRejectModalStates({
      visible: true,
      record,
      note: "",
    });
  };

  return (
    <RetailPageContainer>
      <section className={cm.wrapper}>
        {allCreatives?.records?.length === 0 ? (
          <div className={cm.emptyContainer}>
            <Empty type="review" />
          </div>
        ) : (
          <div className={cm.container}>
            <Spin spinning={isLoading || mutationLoading} />
            {allCreatives?.records?.map((record: any, index: number) => (
              <RetailCreativeInspectCard
                record={record}
                index={index}
                current={current}
                marketplaceHasLanguage={
                  settings?.data?.creative_languages?.length > 0
                }
                key={record.id}
                changeValue={() => changeValue(record, "ACCEPTED")}
                handleReject={handleReject}
              />
            ))}
            {!isLoading &&
              (allCreatives?.records?.length > 0 ||
                allCreatives?.total > 0) && (
                <div className={cc(["flex", cm.barContainer])}>
                  {current > 0 && (
                    <div
                      onClick={decrement}
                      className={cc(["flex", cm.barBtn])}
                    >
                      <LeftOutlined />
                    </div>
                  )}
                  <div className={cc(["flex", cm.bar])}>
                    <RetailText family="poppins" weight="medium" size="xs">
                      {current + 1}
                    </RetailText>
                    <RetailText
                      family="poppins"
                      weight="medium"
                      size="xs"
                      className={cm.grayText}
                    >
                      /
                    </RetailText>
                    <RetailText
                      family="poppins"
                      weight="medium"
                      size="xs"
                      className={cm.grayText}
                    >
                      {allCreatives?.total}
                    </RetailText>
                  </div>
                  {notLastCard && (
                    <div
                      onClick={increment}
                      className={cc(["flex", cm.barBtn])}
                    >
                      <RightOutlined />
                    </div>
                  )}
                </div>
              )}
          </div>
        )}
      </section>

      <CampaignDetailsModal
        type="REJECT_CREATIVE"
        subtitle={t("pages.admin.creatives.rejectSubtitle")}
        visible={rejectModalStates.visible}
        onOk={onRejectOk}
        onCancel={onRejectCancel}
      >
        <RetailFormInput
          isFocused={rejectModalStates.note !== ""}
          name="note"
          label={t("pages.admin.creatives.note")}
          className="floating"
        >
          <Input.TextArea
            className={cc(["floating", cm.textarea])}
            onChange={handleInputChange}
            value={rejectModalStates.note}
          />
        </RetailFormInput>
      </CampaignDetailsModal>
    </RetailPageContainer>
  );
};

export default InspectPage;
