import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Input, Spin } from "antd";
import { Log } from "utils/types";
import { getLocalizedRelativeTime } from "utils/helpers";
import { ReactComponent as ClockOutlined } from "assets/icons/clockOutlined.svg";
import { ReactComponent as LeftOutlined } from "assets/icons/leftOutlined.svg";
import { ReactComponent as RightOutlined } from "assets/icons/rightWhiteOutlined.svg";
import { ReactComponent as SearchOutlined } from "assets/icons/searchOutlined.svg";
import { ReactComponent as Minus } from "assets/icons/minusFilled.svg";
import { ReactComponent as Plus } from "assets/icons/plusFilled.svg";
import "highlight.js/styles/vs2015.css";
import Highlight from "react-highlight";
import moment from "moment";
import useApi from "api";

import Empty from "components/Empty";
import RetailText from "components/Typography/RetailText";

import cm from "./style.module.scss";

const AuctionLogsPage = () => {
  const { t, i18n } = useTranslation();

  const { api } = useApi();

  const [search, setSearch] = useState<string>("");

  const [searchState, setSearchState] = useState<{
    debouncedSearch: string;
    timestampsHistory: string[];
    currentTimestamp: string | null;
  }>({
    debouncedSearch: "",
    timestampsHistory: [],
    currentTimestamp: null,
  });

  useEffect(() => {
    const debounce = setTimeout(() => {
      setSearchState({
        debouncedSearch: search,
        timestampsHistory: [],
        currentTimestamp: null,
      });
    }, 500);

    return () => clearTimeout(debounce);
  }, [search]);

  const { data, isLoading } = useQuery(
    ["auctions", searchState.debouncedSearch, searchState.currentTimestamp],
    ({ signal }) => {
      const data = api.post(
        "auction/logs",
        {
          limit: 50,
          ...(searchState.debouncedSearch && {
            search: searchState.debouncedSearch,
          }),
          ...(searchState.currentTimestamp && {
            end: searchState.currentTimestamp,
          }),
        },
        {
          signal,
        }
      );
      return data;
    },
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const [expandedLogs, setExpandedLogs] = useState<{ [key: number]: boolean }>(
    {}
  );

  const toggleExpanded = (index: number) => {
    setExpandedLogs((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleNextPage = async () => {
    setExpandedLogs({});
    if (
      searchState.timestampsHistory.length === 0 &&
      searchState.currentTimestamp !== null
    ) {
      if (searchState.currentTimestamp) {
        setSearchState((prev) => ({
          ...prev,
          timestampsHistory: searchState.currentTimestamp
            ? [searchState.currentTimestamp]
            : [],
        }));
      }
    }

    if (data?.data?.last_timestamp) {
      const lastTimestamp = data.data.last_timestamp;
      setSearchState((prev) => ({
        ...prev,
        timestampsHistory: [...prev.timestampsHistory, lastTimestamp],
        currentTimestamp: lastTimestamp,
      }));
    }
  };

  const handlePreviousPage = async () => {
    setExpandedLogs({});
    if (searchState.timestampsHistory.length > 1) {
      const newHistory = searchState.timestampsHistory.slice(0, -1);
      const previousTimestamp = newHistory[newHistory.length - 1];
      setSearchState((prev) => ({
        ...prev,
        timestampsHistory: newHistory,
        currentTimestamp: previousTimestamp,
      }));
    } else {
      setSearchState((prev) => ({
        ...prev,
        timestampsHistory: [],
        currentTimestamp: null,
      }));
    }
  };

  const renderLogs = (logs: Log[]) => {
    return logs.map((log, index) => {
      const parsedMessage =
        typeof log.message === "string"
          ? JSON.parse(log?.message)
          : log?.message;

      const combinedLog = {
        ...parsedMessage,
        timestamp: log.timestamp
          ? moment(+log?.timestamp / 1e6).format("DD-MM-YYYY HH:mm:ss")
          : "-",
      };

      const isExpanded = expandedLogs[index];

      return (
        <section className={cm.log} key={index}>
          <div className={cm.logHeader}>
            <div className={cm.logHeaderText}>
              <ClockOutlined />
              <RetailText size="xxxs" weight="medium" className={cm.logTime}>
                {getLocalizedRelativeTime(log?.timestamp, i18n.language)}
              </RetailText>
              <RetailText size="xxxs" weight="medium" className={cm.logMessage}>
                {log?.message?.session_id}
              </RetailText>
            </div>
            <button
              className={cm.expandButton}
              onClick={() => toggleExpanded(index)}
            >
              {isExpanded ? <Minus /> : <Plus />}
              {t(`pages.admin.console.${isExpanded ? "hide" : "show"}`)}
            </button>
          </div>
          <div
            className={cm.innerLogWrapper}
            ref={(el) => {
              if (el) {
                el.style.height = isExpanded ? `${el.scrollHeight}px` : "0px";
              }
            }}
          >
            <article className={cm.innerLog}>
              <RetailText
                size="xxxs"
                weight="medium"
                family="poppins"
                className={cm.logText}
              >
                <Highlight className="json">
                  {JSON.stringify(combinedLog, null, 2)}
                </Highlight>
              </RetailText>
            </article>
          </div>
        </section>
      );
    });
  };

  return (
    <section className={cm.pageWrapper}>
      <header className={cm.headerWrapper}>
        <div className={cm.btnContainer}>
          <RetailText weight="bold" className={cm.headerText}>
            {t("pages.admin.console.auctionLogs")}
          </RetailText>
          <section
            className={`${cm.prev} ${
              searchState.timestampsHistory.length === 0 ? cm.disabled : ""
            }`}
            onClick={handlePreviousPage}
          >
            <LeftOutlined />
            <RetailText
              size="xs"
              weight="medium"
              family="poppins"
              className={cm.headerText}
            >
              {t("pages.admin.console.prev")}
            </RetailText>
          </section>
          <section className={`${cm.next} `} onClick={handleNextPage}>
            <RetailText
              size="xs"
              weight="medium"
              family="poppins"
              className={cm.headerText}
            >
              {t("pages.admin.console.next")}
            </RetailText>
            <RightOutlined />
          </section>
        </div>
        <div className={cm.searchContainer}>
          <Input
            prefix={<SearchOutlined />}
            className={cm.searchInput}
            placeholder={t("common.search")}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <button className={cm.searchButton}>{t("common.search")}</button>
        </div>
      </header>
      <section
        className={
          data?.data?.records?.length > 0 && !isLoading
            ? cm.wrapper
            : cm.emptyWrapper
        }
      >
        {isLoading ? (
          <Spin spinning={isLoading} />
        ) : data?.data?.records.length > 0 ? (
          renderLogs(data?.data.records)
        ) : (
          <Empty type="logs" className={cm.empty} />
        )}
      </section>
    </section>
  );
};

export default AuctionLogsPage;
