import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Tag, Checkbox, Form, Radio, Col } from "antd";
import useTableFetch from "hooks/useTableFetch";
import RetailSearchBar from "components/Bar/RetailSearchBar";
import RetailFormRow from "components/Form/RetailFormRow";
import RetailFormInfo from "components/Form/RetailFormInfo";
import RetailTitle from "components/Typography/RetailTitle";

import cc from "classcat";
import cm from "../style.module.scss";

export interface RetailCampaignFormThirdStepProps {
  allSegments: boolean;
  selectedSegments: any[];
  handleChange(value: any, name: string): void;
}

export const RetailCampaignFormThirdStep = ({
  allSegments,
  selectedSegments,
  handleChange,
}: RetailCampaignFormThirdStepProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [checkedList, setCheckedList] = useState<any[]>(selectedSegments || []);
  const [checkAll, setCheckAll] = useState(false);

  const { data } = useTableFetch("segments", false);

  const filteredRecords = data?.data?.records?.filter((item: any) =>
    searchValue
      ? item.name.toLowerCase().includes(searchValue.toLowerCase())
      : true
  );

  const onCheckAllChange = (e: any) => {
    const allRecords = filteredRecords || [];
    const allIds = allRecords.map((item: any) => ({
      id: item.id,
      name: item.name,
    }));
    const newCheckedList = e.target.checked ? allIds : [];
    setCheckedList(newCheckedList);
    setCheckAll(e.target.checked);
    handleChange(
      newCheckedList.map((item: any) => item.id),
      "segments"
    );
  };

  const onCheckboxChange = (id: string, name: string) => {
    const isChecked = checkedList.find((item) => item.id === id);
    const newCheckedList = isChecked
      ? checkedList.filter((item) => item.id !== id)
      : [...checkedList, { id, name }];

    setCheckedList(newCheckedList);
    setCheckAll(newCheckedList.length === filteredRecords?.length);
    handleChange(
      newCheckedList.map((item) => item.id),
      "segments"
    );
  };

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(target.value);

  return (
    <>
      <RetailFormRow className={cc([cm.audienceRow, "form-row"])}>
        <RetailFormInfo
          column={{
            number: "1",
            title: t("components.campaignForm.thirdStep.audience"),
            text: t("components.campaignForm.thirdStep.audienceText"),
          }}
        />
      </RetailFormRow>
      <RetailFormRow className={cm.segmentRow}>
        <RetailFormInfo
          column={{
            number: "",
            title: t("components.campaignForm.thirdStep.segment"),
            text: t("components.campaignForm.thirdStep.segmentText"),
          }}
        />
        <Form.Item name="allSegments">
          <Radio.Group
            className={cc(["flex", cm.relative, cm.column])}
            onChange={({ target }) =>
              handleChange(target.value === "ALL", "allSegments")
            }
          >
            <Radio
              value="ALL"
              className={cc([
                "form-radio",
                cm.targetingCities,
                cm.marginBottom,
              ])}
            >
              <RetailTitle level={5}>
                {t("components.campaignForm.thirdStep.allSegments")}
              </RetailTitle>
            </Radio>
            <Col className={allSegments ? "" : cm.citiesCol}>
              <Radio
                value="SELECTED"
                className={cc([
                  "form-radio",
                  cm.targetingCities,
                  allSegments ? "" : ["expanded", cm.selectedCities],
                ])}
              >
                <RetailTitle level={5}>
                  {t("components.campaignForm.thirdStep.selectedSegments")}
                </RetailTitle>
              </Radio>
              {!allSegments && (
                <>
                  <Row className={cm.tagContainer}>
                    {checkedList.map((item) => (
                      <Tag
                        key={item.id}
                        closable
                        className={cm.tag}
                        onClose={() => onCheckboxChange(item.id, item.name)}
                      >
                        {item.name}
                      </Tag>
                    ))}
                  </Row>
                  <Form.Item name="segments">
                    <Row className={cm.dropdown}>
                      <RetailSearchBar
                        placeholder={t(
                          "components.campaignForm.thirdStep.segmentPlaceholder"
                        )}
                        style={{ marginBottom: "1rem" }}
                        onChange={handleSearch}
                      />
                      <Checkbox
                        onChange={onCheckAllChange}
                        checked={checkAll}
                        indeterminate={
                          checkedList.length > 0 &&
                          checkedList.length < (filteredRecords?.length || 0)
                        }
                      >
                        {t("common.checkAll")}
                      </Checkbox>
                      <div>
                        {filteredRecords?.map((item: any) => (
                          <Checkbox
                            key={item.id}
                            checked={checkedList.some(
                              (checkedItem) => checkedItem.id === item.id
                            )}
                            onChange={() =>
                              onCheckboxChange(item.id, item.name)
                            }
                            className={cm.checkboxItem}
                          >
                            {item.name}
                          </Checkbox>
                        ))}
                      </div>
                    </Row>
                  </Form.Item>
                </>
              )}
            </Col>
          </Radio.Group>
        </Form.Item>
      </RetailFormRow>
    </>
  );
};
