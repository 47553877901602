import { Select } from "antd";
import Form from "antd/lib/form";
import AntdInput from "antd/lib/input/Input";
import cc from "classcat";
import RetailFormInput from "components/Form/RetailFormInput";
import RetailSelect from "components/Select/RetailSelect";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import useCreativeSize from "hooks/useCreativeSize";
import { useTranslation } from "react-i18next";
import { switchFlag } from "utils/helpers";

import cm from "./style.module.scss";

export interface CreativeNameFormProps {
  onFinish(): void;
  form: any;
  formStates: any;
  onChange(e: any): void;
  languages: string[] | null;
  selected?: string;
  handleSize?: (value: string) => void;
}

const CreativeNameForm = ({
  onFinish,
  form,
  formStates,
  onChange,
  languages,
  selected,
  handleSize
}: CreativeNameFormProps) => {
  const { t } = useTranslation();

  const isLanguageSelectable = languages && languages?.length > 0;

  const { data } = useCreativeSize(selected === "SCRIPT");

  const uniqueSizes: string[] = Array.from(
    new Set(data?.data?.records?.flatMap((record: any) => record.sizes))
  );

  return (
    <Form
      onFinish={onFinish}
      form={form}
      autoComplete="off"
      requiredMark={false}
      initialValues={{
        creative_name: formStates.name,
        creative_languages: formStates.creativeLanguages || [],
        creative_size: formStates.size,
      }}
      className={cm.creativeDrawerForm}
    >
      <section className={cm.creativeDrawerFormSection}>
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeNameTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeNameText")}
        </RetailText>
        <RetailFormInput
          isFocused={formStates.name !== ""}
          label={t("components.campaignForm.firstStep.creativeVideoLabel")}
          className="floating"
          name="creative_name"
          rules={[
            {
              required: true,
              message: t("components.campaignForm.firstStep.creativeError"),
            },
          ]}
        >
          <AntdInput
            className={cc(["floating", cm.creativeDrawerInput])}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            onChange={onChange}
            value={formStates.name}
          />
        </RetailFormInput>
      </section>
      {selected === "SCRIPT" && (
        <section className={cm.creativeDrawerFormSection}>
          <RetailTitle level={5} noMargin>
            {t("components.campaignForm.firstStep.creativeSizeTitle")}
          </RetailTitle>
          <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
            {t("components.campaignForm.firstStep.creativeSizeText")}
          </RetailText>
          <Form.Item
            name="creative_size"
            rules={[
              {
                required: true,
                message: t(
                  "components.campaignForm.firstStep.errorStates.sizeErr"
                ),
              },
            ]}
          >
            <RetailSelect
              onClick={(e) => e.preventDefault()}
              placeholder={t(
                "components.campaignForm.firstStep.creativeSizeSearch"
              )}
              className={cm.sizes}
              value={formStates.size}
              onChange={handleSize}
            >
              {uniqueSizes?.map((item) => (
                <Select.Option key={item} value={item} className={cm.option}>
                  <RetailText size="xs">{item}</RetailText>
                </Select.Option>
              ))}
            </RetailSelect>
          </Form.Item>
        </section>
      )}
      {isLanguageSelectable && (
        <section className={cm.creativeDrawerFormSection}>
          <RetailTitle level={5} noMargin>
            {t("components.campaignForm.firstStep.creativeLanguageTitle")}
          </RetailTitle>
          <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
            {t("components.campaignForm.firstStep.creativeLanguageText")}
          </RetailText>
          <Form.Item
            name="creative_languages"
            rules={[
              {
                type: "array",
                required: true,
                min: 1,
                message: t(
                  "components.campaignForm.firstStep.errorStates.languageErr"
                ),
              },
            ]}
          >
            <RetailSelect
              onClick={(e) => e.preventDefault()}
              mode="multiple"
              placeholder={t(
                "components.campaignForm.firstStep.creativeLanguageSearch"
              )}
              className={cm.tags}
              value={formStates.creativeLanguages}
            >
              {languages?.map((item) => (
                <Select.Option key={item} value={item} className={cm.option}>
                  <div className="flex">
                    <RetailText size="xs">
                      {t(`common.languages.${item?.toLowerCase()}`)}
                    </RetailText>
                    <RetailText size="xxs" weight="medium">
                      {switchFlag(item)}
                    </RetailText>
                  </div>
                </Select.Option>
              ))}
            </RetailSelect>
          </Form.Item>
        </section>
      )}
    </Form>
  );
};

export default CreativeNameForm;
