import { ReactComponent as CodeSmallFiled } from "assets/icons/codeSmallFilled.svg";
import { ReactComponent as SearchOutlined } from "assets/icons/searchOutlined.svg";
import RetailText from "components/Typography/RetailText";

import cm from "./style.module.scss";

export interface RetailCreativeImgColumnProps {
  onClick(): void;
  value: string;
  html?: string;
}

const RetailCreativeImgColumn = ({
  onClick,
  value,
  html,
}: RetailCreativeImgColumnProps) => {
  return (
    <div className="flex">
      <div className={cm.openBtn}>
        <SearchOutlined onClick={onClick} />
      </div>
      {html ? (
        <div className={cm.htmlInner}>
          <CodeSmallFiled />
          <RetailText size="xs" weight="bold" className={cm.htmlText}>
            HTML5
          </RetailText>
        </div>
      ) : (
        <img src={value} alt="" className={cm.img} />
      )}
    </div>
  );
};

export default RetailCreativeImgColumn;
