import { Col, Progress } from "antd";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import cc from "classcat";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import useApi from "api";
import { ReactComponent as AddCircleOutlinedThick } from "assets/icons/addCircleOutlinedThick.svg";
import { ReactComponent as RemoveCircleOutlined } from "assets/icons/removeCircleOutlined.svg";
import RetailDatePicker from "components/Form/RetailDatePicker";
import RetailEndDatePicker from "components/Form/RetailEndDatePicker";
import RetailFormInput from "components/Form/RetailFormInput";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailSelect from "components/Select/RetailSelect";
import RetailText from "components/Typography/RetailText";
import { useCurrency } from "context/CurrencyProvider";
import { budgetErrorMessage, validateNumber } from "utils/helpers";
import cm from "../style.module.scss";

export interface CampaignDetailsModalProps {
  data: any;
  activeKey: string;
  cancel: any;
  visible: boolean;
  chosenMinBid: () => any;
  settings: any;
}

const CampaignUpdateModal = ({
  activeKey,
  data,
  visible,
  cancel,
  chosenMinBid,
  settings,
}: CampaignDetailsModalProps) => {
  const { t } = useTranslation();

  const { api } = useApi();

  const { currencySymbol } = useCurrency();

  const [form] = Form.useForm();

  const today = moment();

  const [totalBudget, setTotalBudget] = useState(data.total_budget || "");

  const [dailyBudget, setDailyBudget] = useState(data?.daily_budget || "");

  const [budgetType, setBudgetType] = useState(data.budget_type);

  const [bid, setBid] = useState(data.fixed_bid);

  const [acos, setAcos] = useState(data.max_acos);

  const [date, setDate] = useState(data.end_date);

  const [spending_type, setSpendingType] = useState(data?.spending_type);

  const [front_loaded_percentage, setFrontLoadedPercentage] = useState(
    data?.front_loaded_percentage || ""
  );

  const [totalBudgetVisible, setTotalBudgetVisible] = useState(false);

  const [campaignDuration, setCampaignDuration] = useState<{
    days: number | string;
    color: string | null;
    percentage: number;
    className: string;
  }>({
    days: 0,
    color: null,
    percentage: 0,
    className: "",
  });

  const options = ["ASAP", "FRONT_LOADED", "EVENLY"];

  const isCampaignDurationCalculatetable =
    totalBudget !== "" && dailyBudget !== "";

  const calculateCampaignDuration = useCallback(() => {
    const days = Math.ceil(+totalBudget / +dailyBudget);
    if (dailyBudget === "0" || totalBudget === "0") {
      setCampaignDuration({
        days: 0,
        color: "var(--red-5)",
        percentage: 0,
        className: cm.red,
      });
    } else if (days <= 1)
      setCampaignDuration({
        days,
        color: "var(--red-5)",
        percentage: 15,
        className: cm.red,
      });
    else if (days <= 3)
      setCampaignDuration({
        days: days,
        color: "var(--yellow-6)",
        percentage: 35,
        className: cm.yellow,
      });
    else if (days <= 6)
      setCampaignDuration({
        days: days,
        color: "var(--blue-10)",
        percentage: 75,
        className: cm.blue,
      });
    else if (days >= 7)
      setCampaignDuration({
        days: days,
        color: "var(--green-9)",
        percentage: 100,
        className: cm.green,
      });
  }, [dailyBudget, totalBudget]);

  useEffect(() => {
    if (dailyBudget !== "" && totalBudget !== "") setBudgetType("FLEXIBLE");
    else if (dailyBudget !== "" && totalBudget === "") setBudgetType("DAILY");
    else if (dailyBudget === "" && totalBudget !== "") setBudgetType("TOTAL");
  }, [dailyBudget, totalBudget]);

  useEffect(() => {
    if (!isCampaignDurationCalculatetable) return;
    else calculateCampaignDuration();
  }, [
    calculateCampaignDuration,
    isCampaignDurationCalculatetable,
    dailyBudget,
    totalBudget,
  ]);

  const handleTotalBudgetVisibility = () => {
    setTotalBudgetVisible(!totalBudgetVisible);
    setTotalBudget("");
    form.setFieldsValue({ total_budget: "" });
  };

  useEffect(() => {
    form.setFieldsValue({
      name: data.name,
      start_date: moment(data.start_date),
      end_date:
        date !== "0001-01-01" && date !== undefined ? moment(date) : null,
      bid: data.fixed_bid,
      daily_budget: data?.daily_budget,
      total_budget: data?.total_budget,
      front_loaded_percentage: data?.front_loaded_percentage,
    });
    if (data?.budget_type !== "DAILY") {
      setTotalBudgetVisible(true);
    }
  }, []);

  const queryClient = useQueryClient();

  const updateValue = async (campaign: any) => {
    const config = { ...campaign };
    const response = await api.patch(`campaigns/${data.id}`, config);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const changeValue = async (campaign: any) => {
    await mutateAsync({ ...campaign });
    queryClient.invalidateQueries("single_campaign", data.id);
  };

  const onFinish = () => {
    const { name, start_date, end_date } = form.getFieldsValue();
    changeValue({
      name: name,
      start_date: start_date
        ? start_date.format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      end_date:
        end_date !== null ? end_date.format("YYYY-MM-DD") : "0001-01-01",
      budget_type: budgetType,
      max_acos: data.bid_type === "MAX_ACOS" ? parseFloat(acos) : null,
      fixed_bid: data.bid_type === "FIXED" ? parseFloat(bid) : null,
      daily_budget: dailyBudget !== "" ? parseInt(dailyBudget) : null,
      total_budget: totalBudget !== "" ? parseInt(totalBudget) : null,
      spending_type: spending_type,
      front_loaded_percentage:
        spending_type === "FRONT_LOADED"
          ? parseInt(front_loaded_percentage)
          : null,
    });
    cancel();
  };

  const closeDatePicker = () => {
    setDate(null);
    form.setFieldsValue({ end_date: null });
  };

  const minDailyBudgetFromSettings = () => {
    if (data?.ad_type === "DISPLAY_WITH_PRODUCT")
      return settings?.min_sponsored_display_daily_budget;
    if (data?.ad_type === "VIDEO_WITH_PRODUCT")
      return settings?.min_sponsored_video_daily_budget;
    else
      return settings?.[
        `min_sponsored_${data?.ad_type?.toLowerCase()}_daily_budget`
      ];
  };

  const totalBudgetAmount = () => {
    if (
      form.getFieldValue("start_date") !== "" &&
      form.getFieldValue("end_date") !== null
    )
      return (
        Number(
          moment(form.getFieldValue("end_date")).diff(
            moment(form.getFieldValue("start_date")),
            "days"
          ) + 1
        ) * minDailyBudgetFromSettings()
      );
    else return minDailyBudgetFromSettings();
  };

  const handleOnClose = () => {
    cancel();
    setTotalBudgetVisible(data?.budget_type !== "DAILY");
    setBudgetType(data?.budget_type);
    setTotalBudget(data?.total_budget || "");
    setDailyBudget(data?.daily_budget || "");
    setSpendingType(data?.spending_type);
    setFrontLoadedPercentage(data?.front_loaded_percentage || "");
    form.setFieldsValue({
      daily_budget: data?.daily_budget,
      total_budget: data?.total_budget,
    });
  };

  return (
    <CampaignDetailsModal
      type="UPDATE"
      visible={visible}
      onCancel={handleOnClose}
      onOk={form.submit}
      subtitle={t("pages.acc.campaignDetails.subtext")}
      destroyOnClose
    >
      <Form
        form={form}
        autoComplete="off"
        colon={false}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Row className={cm.row}>
          <RetailFormInput
            isFocused={data.name !== ""}
            label={t("components.campaignForm.firstStep.campaignLabel")}
            name="name"
            className="floating"
          >
            <Input className="floating" autoFocus={activeKey === "name"} />
          </RetailFormInput>
          <RetailDatePicker
            isFocused={data.start_date}
            autoFocus={activeKey === "start_date"}
            type="campaigns"
            disabled={moment(data.start_date).isBefore(today)}
          />
          <RetailEndDatePicker
            isFocused={date}
            modal
            onClose={closeDatePicker}
            handleChange={(value) => setDate(value)}
            startDate={data.start_date}
            autoFocus={activeKey === "end_date"}
            type="campaign_details"
          />
        </Row>
        <Row className={cm.row}>
          <Col className={cm.budgetContainer}>
            <RetailFormInput
              isFocused={dailyBudget !== ""}
              label={t("components.campaignForm.firstStep.dailyBudgetLabel")}
              className={cc([
                "floating inside show-message",
                dailyBudget === "" ? cm.hidePrefix : cm.showPrefix,
              ])}
              name="daily_budget"
              extra={
                <RetailText
                  family="poppins"
                  size="xxxs"
                  className={cm.budgetHelp}
                >
                  {t("components.campaignForm.firstStep.dailyBudgetExtra")}
                </RetailText>
              }
              rules={[
                {
                  required: budgetType !== "TOTAL",
                  message: t("components.campaignForm.firstStep.budgetError", {
                    value: `${minDailyBudgetFromSettings()}${currencySymbol}`,
                  }),
                },
                () => ({
                  validator(_, value) {
                    if (budgetType === "DAILY") {
                      if (!value || value >= minDailyBudgetFromSettings()) {
                        return Promise.resolve();
                      }

                      return budgetErrorMessage(
                        minDailyBudgetFromSettings(),
                        t,
                        currencySymbol
                      );
                    } else return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                className="number-input floating"
                prefix={currencySymbol}
                autoFocus={activeKey === "daily_budget"}
                onKeyDownCapture={(e) => validateNumber(e)}
                value={dailyBudget}
                onChange={({ target }) => setDailyBudget(target.value)}
              />
            </RetailFormInput>

            <div className={totalBudgetVisible ? cm.totalBudgetWrapper : ""}>
              <div
                className={cc([
                  cm.totalBudgetContainer,
                  totalBudgetVisible ? cm.open : "",
                ])}
              >
                <RetailText
                  size="xs"
                  weight="medium"
                  className={cm.totalBudgetTitle}
                >
                  {t("components.campaignForm.firstStep.totalBudget")}
                </RetailText>
                <span
                  onClick={handleTotalBudgetVisibility}
                  className={cc([
                    cm.totalBudgetButton,
                    totalBudgetVisible ? cm.open : "",
                  ])}
                >
                  {t(`common.${totalBudgetVisible ? "remove" : "add"}`)}
                  <span>
                    {totalBudgetVisible ? (
                      <RemoveCircleOutlined />
                    ) : (
                      <AddCircleOutlinedThick />
                    )}
                  </span>
                </span>
              </div>
              <div
                className={cc([
                  cm.totalBudgetSection,
                  totalBudgetVisible ? cm.open : "",
                ])}
              >
                <RetailFormInput
                  isFocused={totalBudget !== ""}
                  label={t(
                    "components.campaignForm.firstStep.totalBudgetLabel"
                  )}
                  className={cc([
                    "floating inside",
                    cm.totalBudgetInput,
                    totalBudget === "" ? cm.hidePrefix : cm.showPrefix,
                  ])}
                  name="total_budget"
                  extra={
                    <RetailText
                      family="poppins"
                      size="xxxs"
                      className={cm.budgetHelp}
                    >
                      {t("components.campaignForm.firstStep.totalBudgetExtra")}
                    </RetailText>
                  }
                  rules={[
                    {
                      required: budgetType !== "DAILY",
                      message: t(
                        "components.campaignForm.firstStep.budgetErrorMessage"
                      ),
                    },
                    () => ({
                      validator(_, value) {
                        if (budgetType === "TOTAL") {
                          if (budgetType === "STOPPED") {
                            if (
                              !value ||
                              value >= minDailyBudgetFromSettings()
                            ) {
                              return Promise.resolve();
                            }
                            return budgetErrorMessage(
                              minDailyBudgetFromSettings(),
                              t,
                              currencySymbol
                            );
                          } else {
                            if (!value || value >= totalBudgetAmount())
                              return Promise.resolve();

                            return budgetErrorMessage(
                              totalBudgetAmount(),
                              t,
                              currencySymbol
                            );
                          }
                        } else if (budgetType === "FLEXIBLE") {
                          if (!value || value >= +dailyBudget)
                            return Promise.resolve();
                          else {
                            return budgetErrorMessage(
                              dailyBudget,
                              t,
                              currencySymbol
                            );
                          }
                        } else return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    className="number-input floating"
                    prefix={currencySymbol}
                    autoFocus={activeKey === "total_budget"}
                    onKeyDownCapture={(e) => validateNumber(e)}
                    value={totalBudget}
                    onChange={({ target }) => setTotalBudget(target.value)}
                  />
                </RetailFormInput>
                {isCampaignDurationCalculatetable && (
                  <div
                    className={cc(["flex", cm.totalBudgetCalculationContainer])}
                  >
                    <Progress
                      type="circle"
                      trailColor="var(--secondary-3)"
                      width={52}
                      strokeColor={campaignDuration.color || "teal"}
                      format={() => (
                        <div className={cc(["flex", cm.progressText])}>
                          <RetailText size="xxxs" className={cm.durationInfo}>
                            <strong>{campaignDuration.days}</strong>
                          </RetailText>
                          <RetailText className={cm.day} weight="bold">
                            {t("common.day")}
                          </RetailText>
                        </div>
                      )}
                      percent={campaignDuration.percentage}
                    />
                    <RetailText
                      size="xxxs"
                      weight="medium"
                      className={cc([
                        cm.durationText,
                        campaignDuration.className,
                      ])}
                    >
                      <Trans
                        i18nKey={t(
                          "components.campaignForm.firstStep.durationText",
                          {
                            value: campaignDuration.days,
                          }
                        )}
                      />
                    </RetailText>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className={cm.row}>
          <Col className={cm.spendingContainer}>
            <RetailSelect
              placeholder={t("components.campaignForm.firstStep.spendingTitle")}
              options={options.map((option) => ({
                value: option,
                label: t(
                  `components.campaignForm.firstStep.${option.toLowerCase()}`
                ),
              }))}
              value={spending_type}
              onChange={(value) => setSpendingType(value)}
              className={cm.spendingSelect}
              autoFocus={activeKey === "spending_type"}
            />
            {spending_type === "FRONT_LOADED" && (
              <RetailFormInput
                isFocused={data?.front_loaded_percentage !== ""}
                label={t("components.campaignForm.firstStep.frontPercentage")}
                name="front_loaded_percentage"
                rules={[
                  {
                    required: true,
                    pattern: /^([1-9]|[1-9][0-9]|100)$/,
                    message: t(
                      "components.campaignForm.firstStep.errorStates.frontPercentage"
                    ),
                  },
                ]}
                className={cc(["floating", cm.spendingInput])}
              >
                <Input
                  className={cc([
                    cm.input,
                    "number-input floating",
                    data?.front_loaded_percentage === ""
                      ? cm.hidePrefix
                      : cm.showPrefix,
                  ])}
                  prefix="%"
                  name="front_loaded_percentage"
                  value={front_loaded_percentage}
                  onChange={({ target }) =>
                    setFrontLoadedPercentage(target.value)
                  }
                  onKeyDownCapture={(e) => validateNumber(e)}
                />
              </RetailFormInput>
            )}
          </Col>
        </Row>
        {data.bid_type === "MAX_ACOS" && (
          <Row className={cm.row}>
            <RetailFormInput
              isFocused={data.max_acos !== ""}
              label={t("pages.acc.campaignDetails.maxAcosLabel")}
              className={cc([
                "floating inside",
                data.max_acos !== "" ? cm.showPrefix : cm.hidePrefix,
              ])}
            >
              <Input
                type="tel"
                className="number-input floating"
                prefix="%"
                autoFocus={activeKey === "bid"}
                onKeyDownCapture={(e) => validateNumber(e)}
                value={acos > 0 ? acos : ""}
                onChange={({ target }) => setAcos(target.value)}
              />
            </RetailFormInput>
          </Row>
        )}
        {data.bid_type === "FIXED" && data.targeting_type === "AUTO" && (
          <Row className={cm.row}>
            <RetailFormInput
              isFocused={data.fixed_bid !== ""}
              label={t("pages.acc.campaignDetails.fixedBidLabel")}
              className={cc([
                "floating inside",
                data.max_acos !== "" ? cm.showPrefix : cm.hidePrefix,
              ])}
              name="bid"
              rules={[
                {
                  required: true,
                  message: (
                    <>
                      {t("components.campaignForm.firstStep.suggestedBidText")}
                      {currencySymbol}
                      {chosenMinBid()}
                    </>
                  ),
                },
                () => ({
                  validator(_, value) {
                    if (!value || value >= chosenMinBid()) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `
                        ${t(
                          "components.campaignForm.firstStep.suggestedBidText"
                        )}${chosenMinBid()}
                        `
                      )
                    );
                  },
                }),
              ]}
            >
              <Input
                className="number-input floating"
                prefix={currencySymbol}
                autoFocus={activeKey === "bid"}
                onKeyDownCapture={(e) => validateNumber(e)}
                onChange={({ target }) => setBid(target.value)}
              />
            </RetailFormInput>
          </Row>
        )}
      </Form>
    </CampaignDetailsModal>
  );
};

export default CampaignUpdateModal;
