import useApi from "api";
import {
	Auth,
	AuthContext,
} from "context/AuthProvider";
import {
	useContext,
	useEffect,
	useState,
} from "react";
import { useQuery } from "react-query";

const useCreativeSize = (enabled = true) => {
  const { api, baseURL } = useApi();

  const { authToken } = useContext(AuthContext) as Auth;

  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    setIsEnabled(baseURL() !== undefined && authToken !== "" && enabled);
  }, [baseURL, authToken, enabled]);

  const { isLoading, error, data } = useQuery(
    "creative_size",
    () => {
      const data = api.post("/ad_placements/table", {
        page: 1,
        page_size: 10,
        filters: [
          { key: "status", op: "eq", value: "ACTIVE" },
          {
            key: "ad_format",
            op: "eq",
            value: "DISPLAY",
          },
        ],
      });
      return data;
    },
    {
      enabled: isEnabled,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return { isLoading, data, error };
};

export default useCreativeSize;
