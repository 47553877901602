import { ReactComponent as CreativeLanguage } from "assets/icons/creativeLanguage.svg";
import { ReactComponent as DisplayFilled } from "assets/icons/displayFilled.svg";
import { ReactComponent as EditOutlined } from "assets/icons/editWhiteOutlined.svg";
import { ReactComponent as SearchOutlined } from "assets/icons/searchOutlined.svg";
import RetailTooltip from "components/Tooltip/RetailTooltip";
import RetailText from "components/Typography/RetailText";
import {
	Trans,
	useTranslation,
} from "react-i18next";
import { renderFlagOrItem } from "utils/helpers";
import { Img } from "utils/types";

import {
	DeleteOutlined,
	InfoCircleFilled,
} from "@ant-design/icons";

import cm from "./style.module.scss";

export interface DisplayItemProps {
  singleImg: Img;
  languages: string[];
  deleteCreative(uuid: string): void;
  openUpdateDrawer(uuid: string): void;
  openPreview(url: string, name: string, html: string, size: string): void;
}

const DisplayItem = ({
  singleImg,
  languages,
  deleteCreative,
  openUpdateDrawer,
  openPreview,
}: DisplayItemProps) => {
  const { t } = useTranslation();

  const {
    uuid,
    url,
    size,
    languages: creativeLanguages,
    creative_name,
    html,
    submitted,
  } = singleImg;

  const editDisplayItem = () => openUpdateDrawer(uuid);

  const deleteDisplayItem = () => deleteCreative(uuid);

  const openPreviewModal = () =>
    openPreview(url, creative_name, html || "", size);

  const prepareHtmlWithStyles = (htmlContent: string) => {
    const styleTag = `
      <style>
        img {
             width: 100%;
             height: 114px;
             object-fit: cover;
        }
      </style>
    `;

    // Insert the style tag into the head of the user's HTML
    if (htmlContent.includes("<head>")) {
      return htmlContent.replace("<head>", `<head>${styleTag}`);
    }

    // If <head> is missing, inject it before <body>
    if (htmlContent.includes("<body>")) {
      return htmlContent.replace("<body>", `<head>${styleTag}</head><body>`);
    }

    // As a fallback, wrap the HTML in a complete document
    return `
      <!DOCTYPE html>
      <html>
      <head>${styleTag}</head>
      <body>${htmlContent}</body>
      </html>
    `;
  };

  const isLanguageSelectable = languages?.length > 0;

  const languageClassName = `flex ${cm.displayCardIcon} ${
    creativeLanguages && creativeLanguages?.length > 0 ? cm.languageActive : ""
  }`;

  return (
    <section className={`${cm.displayCard} ${submitted ? cm.submitted : ""}`}>
      <div className={cm.imgContainer}>
        {html ? (
          <iframe
            srcDoc={prepareHtmlWithStyles(html)}
            title={creative_name}
            sandbox="allow-scripts allow-same-origin"
          />
        ) : (
          <img src={url} alt={creative_name} />
        )}

        <div className={cm.displayButton} onClick={openPreviewModal}>
          <SearchOutlined />
          <RetailText size="xxxs" weight="bold">
            {t("common.view")}
          </RetailText>
        </div>
      </div>
      <div className={`flex ${cm.displayCardMeta}`}>
        <DisplayFilled className={cm.displayIcon} />
        <RetailText weight="bold" className={`flex ${cm.displayCardMetaText}`}>
          {t("pages.acc.campaigns.table.display")}
        </RetailText>
      </div>
      <div className={cm.displayNameContainer}>
        <Trans>
          <RetailText size="xxxs" weight="medium" className={cm.creativeName}>
            {t("components.campaignForm.firstStep.creativeName", {
              value: creative_name,
            })}
          </RetailText>
        </Trans>
        <RetailTooltip title={creative_name}>
          <InfoCircleFilled />
        </RetailTooltip>
      </div>
      <div className={cm.displayCardFooter}>
        <div className={cm.displayCardIcon} onClick={editDisplayItem}>
          <EditOutlined className={cm.editIcon} />
        </div>
        {isLanguageSelectable && (
          <div className={languageClassName} onClick={editDisplayItem}>
            <div className={cm.languageContainer}>
              <RetailText size="xxxs" weight="bold">
                {renderFlagOrItem(creativeLanguages || languages)}
              </RetailText>
            </div>
            <CreativeLanguage className={cm.language} />
          </div>
        )}
        <div className={cm.displayCardIcon} onClick={deleteDisplayItem}>
          <DeleteOutlined className={cm.deleteIcon} />
        </div>
      </div>
    </section>
  );
};

export default DisplayItem;
