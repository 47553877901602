import { useCurrency } from "context/CurrencyProvider";

interface RetailCurrencyProps {
  amount: string | number;
}
const RetailCurrency = ({ amount }: RetailCurrencyProps) => {
  const { currencySymbol } = useCurrency();
  return (
    <>
      {currencySymbol}
      {amount}
    </>
  );
};

export default RetailCurrency;
