import Spin from "antd/lib/spin";
import Tabs from "antd/lib/tabs";
import RetailCampaignWidget from "components/Card/RetailCampaignWidget";
import RetailPageContainer from "components/Layout/RetailPageContainer";
import RetailNotification from "components/Notification";
import useCampaignFetch from "hooks/useCampaignFetch";
import useSettings from "hooks/useSettings";
import {
	useCallback,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";
import { marketplaceName } from "utils/defaults";
import { selectCampaignType } from "utils/helpers";

import AdPlacementTable from "./AdPlacementTable";
import SingleCampaignHeader from "./CampaignDetailsHeader";
import CategoriesTable from "./CategoriesTable";
import CreativesTable from "./CreativesTable";
import CreativeInfo from "./CreativesTable/CreativeInfo";
import KeywordsTable from "./KeywordsTable";
import NegativeKeywordsTable from "./NegativeKeywordsTable";
import ProductTable from "./ProductTable";
import TargetingTable from "./TargetingTable";
import VideoTable from "./VideoTable";

const SingleCampaignPage = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const { isLoading, data, isError } = useCampaignFetch();

  const { data: marketplaceSettings } = useSettings("ADVERTISER");

  const { data: advertiserSettings } = useSettings("ADMIN_VIEW");

  const [activeKey, setActiveKey] = useState("");

  const isDemo = window.location.hostname === "platform.gowit.com";

  const isDemographicTargetingEnabled =
    selectCampaignType(data?.data?.ad_type) === "DISPLAY" &&
    advertiserSettings?.data?.demographic_targeting === "ALLOWED";

  useEffect(() => {
    switch (selectCampaignType(data?.data?.ad_type)) {
      case "DISPLAY":
        setActiveKey("CREATIVES");
        break;
      case "VIDEO":
        setActiveKey("VIDEO");
        break;
      default:
        setActiveKey("PRODUCTS");
        break;
    }
  }, [data?.data?.ad_type]);

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      const timer = setTimeout(
        () => navigate({ pathname: "/", search: location.search }),
        100
      );
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const chooseMinimum = useCallback(() => {
    switch (data?.data?.ad_type) {
      case "DISPLAY":
      case "DISPLAY_WITH_PRODUCT":
        return marketplaceSettings?.data?.sponsored_display_price_setting;
      case "VIDEO":
      case "VIDEO_WITH_PRODUCT":
        return marketplaceSettings?.data?.sponsored_video_price_setting;
      default:
        return marketplaceSettings?.data?.sponsored_product_price_setting;
    }
  }, [data?.data?.ad_type, marketplaceSettings]);

  const chosenMinBid = useCallback(() => {
    if (chooseMinimum() === "CPM")
      return marketplaceSettings?.data?.minimum_cpm;
    else return marketplaceSettings?.data?.minimum_bid;
  }, [marketplaceSettings, chooseMinimum]);

  const switchTab = () => {
    switch (data?.data?.targeting_type) {
      case "SEARCH":
        return (
          <>
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.keywords")}
              key="KEYWORDS"
            >
              <KeywordsTable
                keywordType={data?.data?.targeting_type}
                bidType={data?.data?.bid_type}
                chosenMinBid={chosenMinBid}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.negativeKeywords")}
              key="NEGATIVE_KEYWORDS"
            >
              <NegativeKeywordsTable type="NEGATIVE_KEYWORDS" />
            </Tabs.TabPane>
          </>
        );
      case "CATEGORY":
        return (
          <>
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.categories")}
              key="CATEGORIES"
            >
              <CategoriesTable
                bidType={data?.data?.bid_type}
                chosenMinBid={chosenMinBid}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.negativeCategories")}
              key="NEGATIVE_CATEGORIES"
            >
              <NegativeKeywordsTable type="NEGATIVE_CATEGORIES" />
            </Tabs.TabPane>
          </>
        );
      default:
        break;
    }
  };

  const chooseProductOrDisplay = () => {
    switch (data?.data?.ad_type) {
      case "PRODUCT":
      case "STORE":
        return (
          <Tabs.TabPane
            tab={t("pages.acc.campaignDetails.products")}
            key="PRODUCTS"
          >
            <ProductTable ad_type={data?.data?.ad_type} />
          </Tabs.TabPane>
        );
      case "DISPLAY":
      case "DISPLAY_WITH_PRODUCT":
        return (
          <>
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.creatives")}
              key="CREATIVES"
            >
              <CreativeInfo url={data?.data.redirect?.custom_url} />
              <CreativesTable
                allowHtml={marketplaceSettings?.data?.allow_html_creatives}
                languages={marketplaceSettings?.data?.creative_languages}
                type={data?.data?.ad_type}
              />
            </Tabs.TabPane>
            {data?.data?.connected_with_products && (
              <Tabs.TabPane
                tab={t("pages.acc.campaignDetails.products")}
                key="PRODUCTS"
              >
                <ProductTable ad_type={data?.data?.ad_type} />
              </Tabs.TabPane>
            )}
          </>
        );
      case "VIDEO":
      case "VIDEO_WITH_PRODUCT":
        return (
          <>
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.video")}
              key="VIDEO"
            >
              <VideoTable />
            </Tabs.TabPane>
            {data?.data?.connected_with_products && (
              <Tabs.TabPane
                tab={t("pages.acc.campaignDetails.products")}
                key="PRODUCTS"
              >
                <ProductTable ad_type={data?.data?.ad_type} />
              </Tabs.TabPane>
            )}
          </>
        );
      default:
        break;
    }
  };

  return (
    <RetailPageContainer>
      <Spin spinning={isLoading} size="large">
        <SingleCampaignHeader
          data={data?.data}
          chosenMinBid={chosenMinBid}
          settings={marketplaceSettings?.data}
        />
        <RetailCampaignWidget page="campaign_details" />
        <Tabs
          className={`bordered-container ${
            activeKey === "targeting" ? "secondary-tab-active" : ""
          }`}
          activeKey={activeKey}
          onChange={(value) => setActiveKey(value)}
        >
          {chooseProductOrDisplay()}
          {switchTab()}
          {/**
           * Hide placements tab for beymen
           */}
          {marketplaceName !== "beymen" && (
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.placements")}
              key="PLACEMENTS"
            >
              <AdPlacementTable />
            </Tabs.TabPane>
          )}
          {/**
           * Show targeting tabs just for demo
           */}
          {(isDemo || isDemographicTargetingEnabled) && (
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.targeting")}
              key="targeting"
            >
              {isDemo ? (
                <Tabs className="secondary-tab">
                  <Tabs.TabPane
                    tab={t("pages.acc.campaignDetails.city")}
                    key="CITIES"
                  >
                    <TargetingTable activeKey="CITIES" />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={t("pages.acc.campaignDetails.age")}
                    key="AGE_RANGES"
                  >
                    <TargetingTable activeKey="AGE_RANGES" />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={t("pages.acc.campaignDetails.gender")}
                    key="GENDERS"
                  >
                    <TargetingTable activeKey="GENDERS" />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={t("pages.acc.campaignDetails.platform")}
                    key="PLATFORMS"
                  >
                    <TargetingTable activeKey="PLATFORMS" />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={t("pages.acc.campaignDetails.customers")}
                    key="CUSTOMERS"
                  >
                    <TargetingTable activeKey="CUSTOMERS" />
                  </Tabs.TabPane>
                </Tabs>
              ) : isDemographicTargetingEnabled ? (
                <Tabs className="secondary-tab">
                  <Tabs.TabPane
                    tab={t("pages.acc.campaignDetails.segments")}
                    key="SEGMENTS"
                  >
                    <TargetingTable activeKey="SEGMENTS" />
                  </Tabs.TabPane>
                </Tabs>
              ) : null}
            </Tabs.TabPane>
          )}
        </Tabs>
      </Spin>
    </RetailPageContainer>
  );
};

export default SingleCampaignPage;
