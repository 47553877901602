import useApi from "api";
import { ReactComponent as CodeFilled } from "assets/icons/codeFilled.svg";
import { ReactComponent as CodeSmallFiled } from "assets/icons/codeSmallFilled.svg";
import { ReactComponent as PreVideoFilled } from "assets/icons/preVideoFilled.svg";
import RetailPlayButton from "components/Button/RetailPlayButton";
import CreativePreviewModal from "components/Modal/CreativePreviewModal";
import RetailText from "components/Typography/RetailText";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { VASTClient } from "@dailymotion/vast-client";

import cm from "./style.module.scss";

export interface VastPlayerProps {
  vastURL: string;
  type: "form" | "details" | "inspect";
  id: string;
  name?: string;
}

const VastPlayer = ({ vastURL, type, id, name }: VastPlayerProps) => {
  const { t } = useTranslation();

  const { api } = useApi();

  const [videoUrls, setVideoUrls] = useState<any[]>([]);

  const [error, setError] = useState<any>(null);

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const isVideo = vastURL === "";

  const switchIcon = () => {
    if (isVideo) {
      return <PreVideoFilled />;
    } else if (type === "details") {
      return <CodeSmallFiled />;
    } else {
      return <CodeFilled />;
    }
  };

  const fetchVastData = async () => {
    if (videoUrls.length > 0) return;

    const vastClient = new VASTClient();

    try {
      const response = await vastClient.get(vastURL);
      const ads = response.ads;
      const urls: any = [];

      ads.forEach((ad) => {
        ad.creatives.forEach((creative) => {
          if (creative.type === "linear") {
            creative.mediaFiles.forEach((mediaFile) => {
              urls.push(mediaFile.fileURL);
            });
          }
        });
      });

      setVideoUrls(urls);
    } catch (err) {
      setError(`Error fetching VAST data: ${err.message}`);
      console.error("Error fetching VAST data", error);
    }
  };

  const handleVideoPlay = async () => {
    try {
      const response = await api.get(`/creatives/${id}`);
      setVideoUrls([response.data?.image_url]);
      setIsPopupVisible(true);
    } catch (err) {
      console.error("Error fetching video data", error);
    }
  };

  const handlePlayClick = () => {
    fetchVastData().then(() => setIsPopupVisible(true));
  };

  const handleClick = isVideo ? handleVideoPlay : handlePlayClick;

  const close = () => setIsPopupVisible(false);

  const text = isVideo
    ? "pages.acc.campaignDetails.videoFile"
    : "pages.acc.campaignDetails.vastTag";

  const switchInnerText = () => {
    switch (type) {
      case "details":
        return (
          <RetailText size="xs" weight="bold" className={cm.detailsText}>
            {t(text)}
          </RetailText>
        );
      case "inspect":
        return (
          <RetailText weight="bold" className={cm.inspectText}>
            {t(text)}
          </RetailText>
        );
      default:
        break;
    }
  };

  return (
    <>
      <div
        className={`flex ${cm.thumbnail} ${cm[`${type}Thumbnail`]}`}
        onClick={handleClick}
      >
        <div className={type === "details" ? cm.detailsInner : cm.inner}>
          {switchIcon()}
          {switchInnerText()}
        </div>
        <RetailPlayButton type={type} />
      </div>
      <CreativePreviewModal
        type="video"
        url={videoUrls[0]}
        name={name}
        visible={isPopupVisible}
        onCancel={close}
      />
    </>
  );
};

export default VastPlayer;
