import Checkbox from "antd/lib/checkbox/Checkbox";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input/Input";
import Radio, { RadioChangeEvent } from "antd/lib/radio";
import cc from "classcat";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { InfoCircleFilled } from "@ant-design/icons";

import useApi from "api";
import { ReactComponent as BalanceOutlined } from "assets/icons/balanceOutlined.svg";
import { ReactComponent as AdvertiserOutlined } from "assets/icons/menu/advertisersOutlined.svg";
import { ReactComponent as WarningCircleFilled } from "assets/icons/warningCircleFilled.svg";
import { useCurrency } from "context/CurrencyProvider";
import useSettings from "hooks/useSettings";
import { numFormatter, validateNumber } from "utils/helpers";
import RetailDatePicker from "components/Form/RetailDatePicker";
import RetailFormInput from "components/Form/RetailFormInput";
import RetailNotification from "components/Notification";
import AdvertiserSelect from "components/Select/AdvertiserSelect";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import CampaignDetailsModal, {
  CampaignDetailsModalProps,
} from "../CampaignDetailsModal";
import RetailPaymentModal from "./RetailPaymentModal";
import cm from "./style.module.scss";

export interface RetailInvoiceModalProps extends CampaignDetailsModalProps {
  visible?: boolean;
  setVisible?(visible: boolean): void;
}

const RetailInvoiceModal = ({
  visible,
  setVisible,
  type,
  onOk,
  onCancel,
  ...campaignDetailsModalProps
}: RetailInvoiceModalProps) => {
  const { t } = useTranslation();

  const { api, roleForDashboard } = useApi();

  const { currencySymbol } = useCurrency();

  const { data: settings } = useSettings(roleForDashboard);

  const queryClient = useQueryClient();

  const [isCustom, setIsCustom] = useState(false);

  const [value, setValue] = useState(0);

  const [date, setDate] = useState<any>(null);

  const [invoiceNo, setInvoiceNo] = useState("");

  const [note, setNote] = useState("");

  const [advertiser, setAdvertiser] = useState<any>(null);

  const [secondaryVisibility, setSecondaryVisibility] = useState(false);

  const [paymentVisible, setPaymentVisible] = useState(false);

  const [checked, setChecked] = useState(false);

  const invoiceNumberCharacterLimit = 30;

  const isInvoiceLong = invoiceNo.length > invoiceNumberCharacterLimit;

  const amountOptionsType = type === "COUPONS" ? "coupon" : "balance";

  const options = settings?.data?.[`${amountOptionsType}_suggested_amounts`];

  const close = () => {
    setVisible!(false);
    setIsCustom(false);
    setChecked(false);
  };

  const closeInvoice = () => {
    close();
    setValue(0);
    setDate(null);
    setAdvertiser(null);
    setInvoiceNo("");
    setNote("");
  };

  const closeTransactions = () => {
    closeInvoice();
    setSecondaryVisibility(false);
  };

  const couponConfig = {
    advertiser_id: advertiser?.id,
    expire_date: date?.format("YYYY-MM-DD"),
    note,
    amount: value,
  };

  const transactionConfig = {
    amount: value,
    invoice_id: invoiceNo,
    note,
  };

  type ErrorHandler = (error: {
    response?: { data?: string | object };
  }) => string;

  const handleError: ErrorHandler = (error) => {
    const defaultMessage = "limitError";

    const message = error?.response?.data;
    const isString = typeof message === "string";

    if (isString && message.toLowerCase().includes("duplicate coupons"))
      return "duplicateCouponsError";
    else if (isString && message.toLowerCase().includes("multiple balance"))
      return "multipleTopUpsError";
    else return defaultMessage;
  };

  const addBalance = async () => {
    //DATA FOR API CALL
    const config = type === "TRANSACTIONS" ? transactionConfig : couponConfig;
    //ENDPOINT FOR API CALL
    const url =
      type === "TRANSACTIONS"
        ? `advertisers/${advertiser.id}/payments`
        : "coupons";
    //TRY TO CALL API IF ADVERTISER IS SELECTED AND VALUE IS GREATER THAN 0
    if (advertiser !== null && value > 0) {
      try {
        await api.post(url, config);
        queryClient.refetchQueries("table");
      } catch (e) {
        RetailNotification.showNotification(
          "error",
          "",
          t(`pages.acc.invoice.${handleError(e)}`)
        );
      } finally {
        closeTransactions();
      }
    } else return;
  };

  const openPayment = () => setPaymentVisible(true);

  const cancelPayment = () => {
    setPaymentVisible(false);
    setValue(0);
  };

  const add = async () => {
    try {
      switch (type) {
        case "INVOICE":
          return openPayment();
        case "TRANSACTIONS":
        case "COUPONS":
          if (advertiser !== null && value > 0)
            return setSecondaryVisibility(true);
          else return;
        default:
          break;
      }
    } finally {
      close();
    }
  };

  const handleInvoice = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setInvoiceNo(target.value);

  const handleNote = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setNote(target.value);

  const handleValue = ({ target }: RadioChangeEvent) => {
    if (target.value === "custom") {
      setIsCustom(true);
      setValue(0);
    } else {
      setIsCustom(false);
      setValue(target.value);
    }
  };

  const limitCheck = () => {
    switch (type) {
      case "COUPONS":
        return settings?.data?.coupon_define_limit;
      default:
        return settings?.data?.balance_topup_limit;
    }
  };

  const validateCustomAmountResolve = (value: string) => {
    const topAmountCheck = !value || +value <= limitCheck();
    switch (type) {
      case "COUPONS":
      case "TRANSACTIONS":
        return topAmountCheck;
      case "INVOICE":
        return topAmountCheck && +value >= settings?.data?.min_top_up_amount;
    }
  };

  const validateCustomAmountReject = (value: string) => {
    const advertiserMinAmount = settings?.data?.min_top_up_amount;
    if (+value > limitCheck()) return t("pages.acc.invoice.limitError");
    else if (+value < advertiserMinAmount)
      return t("pages.acc.invoice.minAmountError", {
        value: `${currencySymbol} ${advertiserMinAmount}`,
      });
  };

  const checkDisabled = () => {
    const valueCheck = value === 0 || value > limitCheck();
    switch (type) {
      case "INVOICE":
        return (
          !checked || valueCheck || value < settings?.data?.min_top_up_amount
        );
      case "TRANSACTIONS":
      case "COUPONS":
        return valueCheck || advertiser === null || isInvoiceLong;
      default:
        return false;
    }
  };

  const renderInput = () => {
    switch (type) {
      case "COUPONS":
        return (
          <RetailDatePicker
            type="modal"
            isFocused={date !== null}
            onChange={(value) => setDate(value)}
            showToday={false}
          />
        );
      case "TRANSACTIONS":
        return (
          <RetailFormInput
            isFocused={invoiceNo !== ""}
            label={t("common.table.invoice")}
            className="floating inside"
            name="invoiceNo"
            validateStatus={isInvoiceLong ? "error" : undefined}
          >
            <Input
              className="number-input floating"
              value={invoiceNo}
              onChange={handleInvoice}
              onKeyDownCapture={(e) => validateNumber(e)}
            />
          </RetailFormInput>
        );
    }
  };

  const renderHelpText = () => {
    switch (type) {
      case "COUPONS":
        return t("pages.admin.coupons.warning");
      case "TRANSACTIONS":
        return isInvoiceLong
          ? t("pages.admin.transactions.invoiceWarning")
          : t("pages.admin.transactions.warning");
      default:
        return "";
    }
  };

  const isNoteFieldVisible = type === "COUPONS" || type === "TRANSACTIONS";
  const noteLabel = t(`pages.admin.${type.toLowerCase()}.note`);
  const noteHelp = t(`pages.admin.${type.toLowerCase()}.noteHelp`);

  return (
    <>
      <CampaignDetailsModal
        type={type}
        visible={visible}
        onCancel={closeInvoice}
        onOk={add}
        disabled={checkDisabled()}
        destroyOnClose
        {...campaignDetailsModalProps}
      >
        <RetailTitle family="eudoxus" className={cm.radioTitle}>
          {t("pages.acc.invoice.modal.loadingAmount")}
        </RetailTitle>
        {/**
         * Amounts to select from
         */}
        <Col className={cm.relative}>
          <Form>
            <Form.Item name="radio">
              <Radio.Group className={cm.radioGroup} onChange={handleValue}>
                {options?.map((opt: number) => (
                  <Radio value={opt} className="form-radio">
                    <RetailTitle level={5} className={cm.amount}>
                      {currencySymbol} {opt}
                    </RetailTitle>
                  </Radio>
                ))}
                <Radio value="custom" className="form-radio">
                  <RetailTitle level={5} className={cm.amount}>
                    {t("pages.acc.invoice.modal.differentAmount")}
                  </RetailTitle>
                </Radio>
              </Radio.Group>
              {isCustom ? (
                <RetailFormInput
                  isFocused={value > 0}
                  label={t("pages.acc.invoice.modal.label")}
                  name="custom_budget"
                  className={cc(["floating", cm.custom])}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (validateCustomAmountResolve(value))
                          return Promise.resolve();
                        else
                          return Promise.reject(
                            validateCustomAmountReject(value)
                          );
                      },
                    }),
                  ]}
                >
                  <Input
                    type="tel"
                    className="number-input floating"
                    onChange={({ target }) => setValue(parseInt(target.value))}
                    onKeyDownCapture={(e) => validateNumber(e)}
                    value={value}
                  />
                </RetailFormInput>
              ) : null}
            </Form.Item>
          </Form>
        </Col>
        {/**
         *  Render a checkbox for invoice page or a title and input for other pages
         */}
        {type === "INVOICE" ? (
          <Checkbox
            className={cm.gray}
            onChange={({ target }) => setChecked(target.checked)}
          >
            {t("marketplaceDependent.invoice_modal_help_text")}
          </Checkbox>
        ) : (
          <>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.coupons.title")}
            </RetailTitle>
            {/**
             * Render advertiser select and input according to type
             */}
            <Col className={cm.advertiserContainer}>
              <AdvertiserSelect
                type="COUPONS"
                onClick={(e) => setAdvertiser(e)}
              />
              <div className={cm.relative}>
                {renderInput()}
                {isInvoiceLong && (
                  <WarningCircleFilled className={cm.invoiceIcon} />
                )}
              </div>
            </Col>
            <div className={cm.noteWrapper}>
              {isNoteFieldVisible ? (
                <div className={cm.noteContainer}>
                  <RetailFormInput
                    isFocused={note !== ""}
                    label={noteLabel}
                    className="floating inside"
                    help={noteHelp}
                  >
                    <Input className="floating" onChange={handleNote} />
                  </RetailFormInput>
                </div>
              ) : null}
              <div
                className={cc([
                  cm.subtext,
                  isInvoiceLong ? cm.invoiceError : "",
                ])}
              >
                <InfoCircleFilled />
                <RetailText family="poppins" size="xxs">
                  {renderHelpText()}
                </RetailText>
              </div>
            </div>
          </>
        )}
      </CampaignDetailsModal>
      {/**
       *  Balance approval modal for transactions page
       */}
      {(type === "TRANSACTIONS" || type === "COUPONS") && (
        <CampaignDetailsModal
          type={type === "TRANSACTIONS" ? "APPROVE_BALANCE" : "APPROVE_COUPON"}
          subtitle={t(`pages.admin.${type.toLowerCase()}.approve`)}
          visible={secondaryVisibility}
          onCancel={closeTransactions}
          onOk={addBalance}
        >
          <article className={cm.boxContainer}>
            <div>
              <BalanceOutlined />
              <RetailText weight="medium" size="xs" className={cm.title}>
                {t("pages.admin.transactions.balance")}
              </RetailText>
              <RetailText weight="bold" className={cm.text}>
                {currencySymbol}
                {numFormatter(value)}
              </RetailText>
            </div>
            <div>
              <AdvertiserOutlined />
              <RetailText weight="medium" size="xs" className={cm.title}>
                {t("pages.admin.transactions.advertiser")}
              </RetailText>
              <RetailText weight="bold" className={cm.text}>
                {advertiser?.name}
              </RetailText>
            </div>
          </article>
        </CampaignDetailsModal>
      )}
      {/**
       *  Show payment method modal for invoice page
       */}
      {type === "INVOICE" ? (
        <RetailPaymentModal
          onCancel={cancelPayment}
          type="PAYMENT_OPTIONS"
          paymentVisible={paymentVisible}
          value={value}
        />
      ) : null}
    </>
  );
};

export default RetailInvoiceModal;
