import { ReactComponent as CopyOutlined } from "assets/icons/copyOutlined.svg";
import { ReactComponent as DotFilled } from "assets/icons/dotFilled.svg";
import cc from "classcat";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailLinkColumn from "components/Column/RetailLinkColumn";
import RetailNameColumn from "components/Column/RetailNameColumn";
import VastPlayer from "components/Player/VastPlayer";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import { useTranslation } from "react-i18next";
import { switchFlag } from "utils/helpers";

import cm from "./style.module.scss";

interface RetailCreativeInspectCardProps {
  record: any;
  index: number;
  current: number;
  marketplaceHasLanguage: boolean;
  changeValue: (record: any, value: string) => void;
  handleReject: (record: any) => void;
}

const RetailCreativeInspectCard = ({
  record,
  index,
  current,
  marketplaceHasLanguage,
  changeValue,
  handleReject,
}: RetailCreativeInspectCardProps) => {
  const { t, i18n } = useTranslation();

  const copyTextToClipboard = (item: string) =>
    navigator.clipboard.writeText(item);

  const {
    id,
    creative_name,
    format,
    vast_tag,
    image_url,
    html,
    campaign_id,
    campaign_name,
    advertiser_id,
    advertiser_name,
    redirect_url,
    size,
    policy_status,
    languages,
    categories,
    keywords,
  } = record;

  const language = i18n.language.toUpperCase();

  const creativeHasLanguage = marketplaceHasLanguage && languages?.length > 0;

  const creativeHasTargeting = categories || keywords;

  const creativeHasRegions = record?.regions?.length > 0;

  const [width, height] = (size || "0x0").split("x").map(Number);

  const prepareHtmlWithStyles = (htmlContent: string) => {
    const styleTag = `
      <style>
      html {
        width: 100%;
        height: 100%;
      }
        img {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
          margin: 0 auto;
          border-radius: 12px;
        }
      </style>
    `;

    // Insert the style tag into the head of the user's HTML
    if (htmlContent.includes("<head>")) {
      return htmlContent.replace("<head>", `<head>${styleTag}`);
    }

    // If <head> is missing, inject it before <body>
    if (htmlContent.includes("<body>")) {
      return htmlContent.replace("<body>", `<head>${styleTag}</head><body>`);
    }

    // As a fallback, wrap the HTML in a complete document
    return `
      <!DOCTYPE html>
      <html>
      <head>${styleTag}</head>
      <body>${htmlContent}</body>
      </html>
    `;
  };

  return (
    <div className={cc([cm.card, index === current ? cm.visible : ""])}>
      {/*
       * Creative Name Row
       */}
      <RetailTitle level={5} className={cm.title}>
        {creative_name}
      </RetailTitle>
      {/*
       * The actual image or video
       */}
      <div className={cm.imgContainer}>
        {format === "VIDEO" ? (
          <VastPlayer vastURL={vast_tag || ""} type="inspect" id={id} />
        ) : html ? (
          <iframe
            srcDoc={prepareHtmlWithStyles(html)}
            title={creative_name}
            width={width}
            height={height}
            sandbox="allow-scripts allow-same-origin"
          />
        ) : (
          <img src={image_url} alt={creative_name} />
        )}
      </div>
      {/*
       * Vast Tag Row
       */}
      {vast_tag && (
        <div className={cm.vastTag}>
          <RetailText weight="medium" size="xs" className={cm.vastTagText}>
            {t("pages.admin.inspect.vastTag")}
          </RetailText>
          <a
            href={vast_tag}
            target="_blank"
            rel="noreferrer"
            className={cc([cm.link, cm.vastTagLink])}
          >
            {vast_tag}
          </a>
        </div>
      )}
      {/*
       * Campaign and Advertiser Row
       */}
      <div className={cm.text}>
        <div>
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={cm.grayText}
          >
            {t("pages.admin.inspect.campaign")}
          </RetailText>
          <RetailLinkColumn
            to={`/campaign/${campaign_id}?adv=${advertiser_id}`}
            value={campaign_name}
            state={{ breadcrumb: true, breadcrumbType: "campaign" }}
          />
        </div>
        <div>
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={cm.grayText}
          >
            {t("pages.admin.inspect.advertiser")}
          </RetailText>
          <RetailNameColumn
            to={`/campaigns?adv=${advertiser_id}`}
            value={`${advertiser_name || "-"} (${advertiser_id})`}
          />
        </div>
      </div>
      {/*
       * Redirect URL Row
       */}
      <div className={cc(["flex", cm.urlContainer])}>
        <RetailText
          family="poppins"
          weight="medium"
          size="xs"
          className={cm.grayText}
        >
          {t("pages.admin.inspect.url")}
        </RetailText>
        <a
          href={redirect_url}
          className={cm.link}
          target="_blank"
          rel="noreferrer"
        >
          {redirect_url}
        </a>
        <CopyOutlined onClick={() => copyTextToClipboard(redirect_url)} />
      </div>
      {/*
       * Size, Status, and Languages Row
       */}
      <div className={cc(["flex", cm.sizeContainer])}>
        <div className="flex">
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={cm.grayText}
          >
            {t("pages.admin.inspect.size")}
          </RetailText>
          <RetailText family="poppins" weight="medium" size="xs">
            {size}
          </RetailText>
        </div>

        <DotFilled />

        <div className="flex">
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={cm.grayText}
          >
            {t("pages.admin.inspect.status")}
          </RetailText>
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={policy_status ? cm[policy_status.toLowerCase()] : ""}
          >
            {policy_status
              ? t(`pages.admin.creatives.${policy_status.toLowerCase()}`)
              : "-"}
          </RetailText>
        </div>
        <DotFilled />
        {creativeHasLanguage && (
          <div className={cc(["flex", cm.languageWrapper])}>
            <RetailText
              family="poppins"
              weight="medium"
              size="xs"
              className={cm.grayText}
            >
              {t("pages.admin.inspect.languages")}
            </RetailText>
            {languages?.map((lang: string) => (
              <RetailText
                family="poppins"
                weight="medium"
                size="xxxs"
                className={cm.languageContainer}
                key={lang}
              >
                {t(`common.languages.${lang?.toLowerCase()}`)}
                <span>{switchFlag(lang)}</span>
              </RetailText>
            ))}
          </div>
        )}
      </div>
      {
        /*
         * Regions Row
         */
        creativeHasRegions && (
          <div className={cc(["flex", cm.infoContainer])}>
            <RetailText
              family="poppins"
              weight="medium"
              size="xs"
              className={cc([cm.grayText, cm.regionText])}
            >
              {t("pages.admin.inspect.regions")}
            </RetailText>
            {record?.regions?.map((region: any) => {
              const regionLabel =
                region.translations[language] ||
                Object.values(region.translations)[0];
              return (
                <RetailText
                  family="poppins"
                  weight="medium"
                  size="xxxs"
                  className={cm.languageContainer}
                  key={region}
                >
                  {regionLabel}
                </RetailText>
              );
            })}
          </div>
        )
      }
      {/*
       *  Categories and Keywords Row
       */}
      {creativeHasTargeting && (
        <div className={cc(["flex", cm.infoContainer])}>
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={cm.grayText}
          >
            {categories
              ? t("pages.admin.inspect.categories")
              : t("pages.admin.inspect.keywords")}
          </RetailText>
          {categories?.map((item: string) => (
            <RetailText family="poppins" weight="medium" size="xs" key={item}>
              {item}
            </RetailText>
          )) ||
            keywords?.map((item: string) => (
              <RetailText family="poppins" weight="medium" size="xs" key={item}>
                {item}
              </RetailText>
            ))}
        </div>
      )}
      {/*
       * Actions Row
       */}
      <div className={cc(["flex", cm.btnContainer])}>
        <RetailMainButton
          hasBackground={false}
          className={cc(["flex", cm.btn, cm.rejectBtn])}
          onClick={() => handleReject(record)}
        >
          {t("common.reject")}
        </RetailMainButton>

        <RetailMainButton
          hasBackground
          className={cc(["flex", cm.btn, cm.okBtn])}
          onClick={() => changeValue(record, "ACCEPTED")}
        >
          {t("common.accept")}
        </RetailMainButton>
      </div>
    </div>
  );
};

export default RetailCreativeInspectCard;
