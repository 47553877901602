import Row from "antd/lib/row";
import RetailText from "components/Typography/RetailText";
import { useTranslation } from "react-i18next";

import cm from "../style.module.scss";

export interface CreativeInfoProps {
  url: string;
}

const CreativeInfo = ({ url }: CreativeInfoProps) => {
  const { t } = useTranslation();

  return (
    <Row className={cm.info}>
      <RetailText size="xxxs" weight="bold" className={cm.infoTitle}>
        {t("pages.acc.campaignDetails.creativesLinkTitle")}
      </RetailText>
      <RetailText size="xxxs" className={cm.infoText}>
        {t("pages.acc.campaignDetails.creativesLinkText", {
          value: url,
        })}
      </RetailText>
    </Row>
  );
};

export default CreativeInfo;
