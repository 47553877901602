const tr = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/hedefleme-nasil-yapilir-hedefleme-turleri-nelerdir",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_targeting_text:
      "Kampanyanızın hedefleme türünü seçin. Kampanyanız hedefleme tercihinize göre müşterilere gösterilir.",
    campaign_form_budget_text:
      "Kampanyanızın bütçe tipini ve miktarını belirleyin. Harcamanız bütçenize ulaştığında kampanya gösterimi durur.",
    campaign_form_creative_text:
      "Kampanyanızda kullanmak istediğiniz kreatifleri yükleyebilirsiniz.",
    invoice_modal_help_text:
      "Ödeme yöntemi seç butonuna tıklayarak devam ettiğiniz takdirde tahsil edilecek tutarın iadesinin yapılmayacağını onaylamış kabul edilirsiniz.",
    invoice_general_error_modal_title: "Bakiye Yüklenemedi!",
    invoice_general_error_modal_text: "Bir hata meydana geldi.",
    invoice_unknown_error_modal_text: "Bilinmeyen bir hata oluştu.",
    invoice_product_id_error_modal_text: "Ürün id alanı gereklidir.",
    invoice_signature_error_modal_text: "İmza doğrulama başarısız oldu.",
    invoice_transaction_error_modal_text: "Transaction ID zaten alınmış.",
    invoice_store_error_modal_text: "Mağaza verilen şirkete ait değil.",
    invoice_balance_error_modal_text: "Mağaza yeterli bakiyeye sahip değil.",
    invoice_error_modal_title: "Bakiye Yüklenemedi!",
    invoice_error_modal_text: "Bir hata meydana geldi.",
  },
  marketplaceDependentTable: {
    CPC: "CPC",
    CPM: "CPM",
    RoAS: "ROAS",
  },
  marketplaceDependentSettings: {
    api_link: "https://docs.gowit.com/reference/getting-started-1",
    auto_impression_link: "https://docs.gowit.com/reference/sendevent",
    auction_link:
      "https://docs.gowit.com/docs/what-type-of-auctions-are-offered",
    product_link: "https://docs.gowit.com/docs/filters#what-is-a-product-rate",
    stock_link: "https://docs.gowit.com/docs/filters#what-is-a-product-stock",
    targeting_link:
      "https://docs.gowit.com/docs/filters#what-is-a-demographic-targeting-feature",
    bidding_link: "https://docs.gowit.com/docs/bidding-types",
  },
  sider: {
    user: {
      campaign: "Kampanyalar",
      report: "Raporlar",
      campaigns: "Kampanyalar",
      reports: "Raporlar",
      invoices: "Faturalar",
      academy: "Reklam Rehberi",
      chat: "Mail ile Destek",
    },
    admin: {
      campaign: "Kampanyalar",
      advertisers: "Reklamverenler",
      agencies: "Ajanslar",
      invoices: "Faturalar",
      finance: "Finansal",
      transactions: "İşlemler",
      coupons: "Kuponlar",
      access: "Erişim",
      users: "Kullanıcılar",
      invited: "Davet Edilenler",
      synchronization: "Senkronizasyon Geçmişi",
      activity: "Değişiklik Geçmişi",
      report: "Raporlar",
      settings: "Ayarlar",
      general: "Genel Bilgiler",
      integration: "Entegrasyon Bilgileri",
      advertiser: "Reklamveren Ayarları",
      auction: "Açık Artırma Ayarları",
      single: "Kampanya Ayarları",
      campaigns: "Kampanyalar",
      reports: "Raporlar",
      admin_report: "Raporlar",
      ad: "Reklam",
      placement: "Reklam Yerleşimi",
      creatives: "Kreatifler",
      review: "İnceleme",
      restrictions: "Kısıtlama Ayarları",
      console: "Veri Kaynakları",
      dev: "Dev. Tools",
      auctions: "Açık Artırmalar",
    },
  },
  components: {
    adminBar: {
      rn: "Şu anda",
      visible: "reklamvereninin görünümündesiniz.",
      back: "Admin Görünümüne Dön",
    },
    advertiserSelect: {
      placeholder: "Reklamveren Arayın",
    },
    articles: {
      date: "Oluşturulan Kampanya Ne Zaman Başlar ve Sonlanır?",
      budget: "Kategori Bazlı Tıklama Maliyetleri",
      bid: "Teklif nedir ve nasıl belirlenir?",
      targeting: "Hedefleme nasıl yapılır?",
      frequency: "Gönderim türü nedir ve zamanlanmış rapor nasıl oluşturulur?",
      type: "Özel rapor nasıl oluşturulur?",
      product: "Ürün seçimi yaparken nelere dikkat edilmeli?",
      store: "Mağaza seçerken nelere dikkat edilmelidir?",
    },
    breadcrumb: {
      finance: "İşlemler",
      settings: "Genel Bilgiler",
      access: "Kullanıcılar",
    },
    bulkUpdate: {
      list: "Seçilenleri Arşivden Çıkar",
      archive: "Seçilenleri Arşivle",
      pause: "Seçilenleri Durdur",
      activate: "Seçilenleri Aktifleştir",
      selected: "{{value}} birim seçildi",
      bid: "Seçili Teklifleri Düzenle",
      bidLabel: "Teklif",
      bidHelp: "Seçili satırların teklifleri güncellenecektir.",
      row: "{{value}} Satır",
      count: "Seçili:",
      updateAllBids: "Teklifleri Düzenle",
      updateAllBidsText:
        "Tabloda gösterilen bütün satırların tekliflerini düzenleyebilirsiniz.",
      updatingAllBids: "Bütün satırların tekliflerini düzenliyorsunuz.",
      updateAllBidsHelp:
        "Tabloda görüntülenen tüm satırların teklifleri güncellenecektir.",
    },
    datasource: {
      entity: "Veri kaynağınız nedir?",
      method: "İçe aktarma yöntemi nedir?",
      configurations: "Yapılandırmalarınızı ayarlayın",
      mapping: "Alan eşleştirme",
      emptyEntity: "<strong>Veri kaynağınız</strong> nedir?",
      emptyEntityText:
        "Veri kaynağı türünü seçin. Bu seçim, verinizin yapısını tanımlar ve hangi alanların içe aktarım için gerekli olduğunu belirler. Seçim yaptıktan sonra, veri kaynağına özgü veri alanlarını yapılandırabileceksiniz.",
      productEntityText:
        "Ürün veri kaynağı ürünlerinizle ilgili verileri temsil eder. Ürün kimliği, adı, açıklama, fiyat, filtreler ve stok bilgileri gibi alanları içerir. Bu veri türü, her ürün için ayrıntılı bilgileri yakalamak ve yönetmek için tasarlanmıştır.",
      advertiserEntityText:
        "Reklamveren veri kaynağı, reklamverenlerinizle ilişkin verileri temsil eder. Reklamveren ID, isim ve durum gibi alanları içerir. Bu veri türü, her bir reklamveren için detaylı bilgileri yakalamak ve yönetmek için tasarlanmıştır.",
      selectEntity: "Veri Kaynağı Seçin",
      selectEntityDescription:
        "Veri eklemek istediğiniz türü seçiniz. Bu seçim, verilerinizin yapısını belirleyecek ve hangi alanların içe aktarım için gerekli olduğunu gösterecektir. Seçim yaptıktan sonra, bu kaynağa özel veri alanlarını yapılandırabileceksiniz.",
      product: "Ürün",
      productText:
        "Ürün veri kaynağı ürünlerinizle ilgili verileri temsil eder. Ürün kimliği, adı, açıklaması, fiyatı, filtreler ve stok bilgisi gibi alanları içerir. Bu veri türü, her ürün için ayrıntılı bilgilerin toplanmasını ve yönetilmesini sağlar.",
      seeFields: "{{value}} Veri Şemasını Görüntüle",
      how: "Nasıl Yapılır?",
      advertiser: "Reklamveren",
      advertiserText:
        "Reklamveren türü, reklamverenlere ilişkin verileri temsil eder. Reklamveren kimliği, adı ve diğer ilgili detayları içerir. Doğru entegrasyonu sağlamak için gerekli alanlar 'id' ve 'name' dir. Bu veri türü, reklamverenler hakkında önemli bilgilerin organize edilmesine ve saklanmasına yardımcı olur.",
      soon: "Yakında",
      schema: "{{value}} Veri Şeması",
      required: "Zorunlu",
      showFields: "Ek Alanları Gör",
      emptyMethod: "İçe Aktarma Yönteminiz nedir?",
      emptyMethodText:
        "Seçilen kaynaktan verilerinizi içe aktarmak istediğiniz yöntemi seçin. Seçtiğiniz yönteme bağlı olarak, sistem verileri doğru bir şekilde işlemek için içe aktarma sürecini otomatik olarak uyarlayacaktır. Her yöntemin kendi avantajları vardır, bu yüzden ihtiyaçlarınıza en uygun olanı seçin.",
      filledMethod: "İçe Aktarma Yöntemi: <strong>{{value}}</strong>",
      googleFeedUrl: "Google Feed URL",
      googleFeedFile: "Google Feed Dosyası Yükleme (XML)",
      googleFeedDirectXml: "Doğrudan XML Yükleme (İstek Gövdesi)",
      filledMethodText:
        "Feed URL'sini sağlayarak veya desteklenen formatlarda dosya yükleyerek {{value}}'inizden veri almak için bu seçeneği kullanın.",
      selectMethod: "İçe Aktarma Yöntemini Seçin",
      googleFeedText:
        "Google Feed, feed URL'si sağlayarak veya desteklenen formatlarda dosya yükleyerek verileri doğrudan Google Feed'inizden içe aktarmak için kullanılır. Bu özellik, ürün feed entegrasyonu sırasında reklamveren oluşturmayı otomatik olarak yöneterek süreci basitleştirir.",
      feedUrl: "Feed URL",
      feedUrlText: "Google Feed verilerinin doğrudan alındığı URL.",
      fileUpload: "Dosya Yükleme (XML)",
      fileUploadText:
        "Google Feed dosyaları, XML formatı kullanılarak manuel olarak içe aktarılabilir.",
      requestBody: "Doğrudan XML Yükleme (İstek Gövdesi)",
      requestBodyText:
        "Geleneksel dosya yüklemelerini atlayarak, XML aracılığıyla Google Feed verilerini istek gövdesi içinde içe aktarmayı sağlayan doğrudan bir entegrasyon yöntemi. Bu özellik yakında sunulacak.",
      configTitle: "<strong>Yapılandırmalarınızı</strong> Ayarlayın",
      configText:
        "Veri kaynağınız için gerekli ayarları tanımlayın. Bu ayarların doğru yapılandırılması, veri içe aktarma sürecinin sorunsuz çalışmasını sağlar ve verilerinizin sistemde güncel kalmasını sağlar. Veri kaynağı kaydedildikten sonra bu ayarlar değiştirilemez.",
      name: "Veri Kaynağı Adı",
      nameHelp:
        "Bu veri kaynağı yapılandırması için benzersiz bir ad girin. Bu, kaynağı daha sonra tanımlamanıza yardımcı olacaktır.",
      url: "Feed URL",
      urlHelp:
        "Feed'in alınabileceği tam URL'yi sağlayın. Sistem bu bağlantıdan düzenli olarak veri çekecektir.",
      cycle: "Döngü Seçin",
      cycleHelp:
        "Sistem sağlanan feed URL'sinden ne sıklıkla güncelleme alacağını belirleyin.",
      fetch: "Al & Eşle",
      mappingTitle: "Alan <strong>Eşleştirme</strong>",
      mappingText:
        "Bu bölümde, veri kaynağınızdaki alanları sistemimizdeki ilgili alanlara eşleyeceksiniz. Sistem alanlarının belirli gereksinimleri vardır ve gerekli alanların başarılı bir şekilde içe aktarılması için doğru bir şekilde eşleştirilmesi gerekir. Kaynağınızda eşleşen veri olmayan bazı zorunlu alanlar için, varsayılan bir değer ayarlamayı seçebilirsiniz. Bu, içe aktarma sırasında kritik bilgilerin eksik olmasını engellemeye yardımcı olur. Alanları eşlemeyi tamamladıktan sonra, gerekli tüm alanların doğru şekilde eşleştirildiğinden emin olmak için eşleştirmeyi doğrulamanız gerekir.",
      validate: "Eşleştirmeyi Doğrula",
      system: "Sistem Alanları",
      imported: "İçe Aktarılan Alanlar",
      preview: "Önizleme",
      hideFields: "Ek Alanları Gizle",
      feedUrlLink: "Google Ürün Veri Kılavuzu",
      upload: "XML Dosyası Yükle",
      uploaded: "Dosya yüklendi!",
      uploadText: "veya buraya sürükleyip bırakın",
      metaFeedText:
        "Meta Feed, feed URL'leri ve dosya yüklemeleri dahil olmak üzere çeşitli formatlar kullanarak veri alımını destekler. Desteklenen bütün formatlar ve alanlar yakında eklenecektir.",
      metaFeedUrlText:
        "Meta Feed entegrasyonu için verilerin doğrudan alınması amacıyla kullanılan özel bir URL kaynağı.",
      metaFeedUrl: "Meta Feed URL",
      xml: "Doğrudan XML Kodu",
      xmlHelp: "Verilerinizi kaynağınızdan içe aktarmak için XML kodunu girin.",
      api: "API Entegrasyonu",
      apiText:
        "API entegrasyonu, belirtilen API uç noktalarına bağlantı sağlar ve sisteminize sürekli bilgi akışı için veri senkronizasyonunu mümkün kılar.",
      apiButtonText:
        "Belirtilen API uç noktalarına bağlanarak veri almak için bir yapı sunar. Bu entegrasyon, API'mize gönderilen istekler aracılığıyla veri senkronizasyonunu sağlayarak, değişiklikler meydana geldikçe güncellenen bilgilerin doğrudan sisteminize akmasını sağlar.",
      impression: "Görüntülenme",
      impressionText:
        "Görüntülenme, reklam görüntülemeleriyle ilgili verileri temsil eder. Bu, reklam kimliği, oturum kimliği ve diğer ilgili ayrıntılar gibi alanları içerir. Bu varlık, bir reklamın ekranda göründüğü her örneği kaydederek reklamlarınızın görünürlüğünü ölçmenize yardımcı olur.",
      click: "Tıklama",
      clickText:
        "Tıklama varlığı, kullanıcıların bir reklama tıkladıklarında gerçekleştirdiği etkileşimlere ilişkin verileri yakalar. Alanlar, reklam kimliği, oturum kimliği ve diğer ilgili ayrıntıları içerir ve kullanıcı katılımına dair içgörüler sağlar. Bu varlık, her reklam tıklamasını kaydederek kullanıcı eylemlerinin detaylı analizini mümkün kılar.",
      sale: "Satış",
      saleText:
        "Satış varlığı, reklam etkileşimlerinden kaynaklanan tüm dönüşümler veya satın alımlara ilişkin verileri kapsar. Doğru izleme ve raporlama sağlamak için tüm satış olaylarının bize gönderilmesi önemlidir. Bu veriler, kategori alıcıları, yeni müşteriler ve mevcut müşteriler gibi müşteri türleri ile doğrudan ve dolaylı dönüşümler hakkında ayrıntılı bilgiler sağlamamıza olanak tanır.",
      productSchema: {
        id: "Ürünün ID'si",
        name: "Ürünün adı",
        advertiser_id: "Reklamverenin ID'si",
        image_url: "Ürünün resim URL'si",
        description: "Ürünün açıklaması",
        price: "Ürünün fiyatı (birden fazla konum için -1 döner)",
        filters: "Ürüne uygulanan filtreler",
        buybox: "Ürünün buybox'a sahip olup olmadığını gösterir",
        sub_class_names: "Ürünün alt sınıf adları",
        brand: "Ürünün markası",
        retailer_taxonomy: "Ürün kategorisi",
        google_taxonomy: "Ürünün Google sınıflandırması",
        product_type: "Ürünün türü",
        global_identifier: "Ürünün global tanımlayıcısı",
        global_identifier_type: "Global tanımlayıcı türü (GTIN veya ASIN)",
        rating: "Ürünün derecelendirmesi",
        stock: "Ürünün stoğu (birden fazla konum için -1 döner)",
        sku: "Ürünün SKU'su",
        barcode: "Ürünün barkodu",
        price_range: "Ürünün fiyat aralığı",
        status: "Ürünün durumu",
        retailer_taxonomy_id_structure: "Ürün kategorisinin yapısı",
        process_id: "Sürecin id'si",
      },
      advertiserSchema: {
        id: "Reklamverenin ID'si",
        name: "Reklamverenin adı",
        time_zone: "Reklamverenin saat dilimi",
        email: "Reklamverenin e-posta adresi",
        language: "Reklamverenin dili",
        status: "Reklamverenin durumu",
        process_id: "Reklamverenin süreç id'si",
      },
    },
    download: {
      campaigns: "Kampanyalar",
      advertisers: "Reklamverenler",
      agencies: "Ajanslar",
      transactions: "İşlemler",
      coupons: "Kuponlar",
      users: "Kullanıcılar",
      sync_logs: "Senkronizasyon Geçmişi",
      reports: "Raporlar",
      query: "Performans Raporu",
      PRODUCTS: "Ürünler",
      KEYWORDS: "Anahtar Kelimeler",
      NEGATIVE_KEYWORDS: "Negatif Anahtar Kelimeler",
      AGE_RANGES: "Yaş",
      CITIES: "Şehir",
      PLATFORMS: "Platform",
      GENDERS: "Cinsiyet",
      PLACEMENTS: "Reklam Yerleşimi",
      CUSTOMERS: "Müşteri",
      CATEGORIES: "Kategoriler",
      NEGATIVE_CATEGORIES: "Negatif Kategoriler",
      CREATIVES: "Kreatifler",
    },
    inbox: {
      title: "Gelen Kutusu",
      empty: "Bildiriminiz yok.",
      unread: "Sadece okunmamışlar",
      default: "Tümü",
      archived: "Arşivlenmiş",
      readAll: "Tümünü okundu olarak işaretle",
      archiveAll: "Tümünü arşivle",
      archiveRead: "Okunanları arşivle",
      preferences: "Bildirim Tercihleri",
      global: "Genel Tercihler",
      balance: "Düşük Bakiye",
      categories: "Düşük Teklif (Kategoriler)",
      keywords: "Düşük Teklif (Anahtar Kelimeler)",
      products: "Ürünler Durduruldu",
    },
    info: {
      firstTipCreate:
        "Kampanya kurulumda önerilen ayarları tercih ederek kolaylıkla başarılı kampanyalar oluşturabilirsiniz. Önerilen ayarlar reklam performansınızı sizin için optimize eder.",
      secondTipCreate:
        "Yeterli stoğa sahip, yüksek puanlı ve buybox kazananı olan ürünlerinizi kampanyalarınıza ekleyerek kampanyanızın performansını artırabilirsiniz.",
      thirdTipCreate:
        "Reklam bütçenizin yüksek olması ürünlerinizi daha görünür yaparak tıklanma ve satış sayısınızı artırır.",
      forthTipCreate:
        "Yeni ürünlerinizin reklamını yaparken yüksek teklif vererek ürünlerinizin üst sıralarda görüntülenmesini sağlayabilirsiniz.",
      survey:
        "Fikirlerinizi duymaktan mutluluk duyarız! Anketimize katılarak platformumuzu daha iyi hale getirmemize yardımcı olabilirsiniz.",
    },
    invite: {
      selected: "{{value}} birim seçildi",
      invite: "Seçilenleri Davet Et",
      reinvite: "Seçilenleri Yeniden Davet Et",
    },
    modal: {
      campaign: {
        create: "Yeni Kampanya Oluştur",
        success: "Kampanya Başarıyla Oluşturuldu!",
        successText:
          "Kampanya detay sayfasına “Tamam” butonuna tıklayarak devam edebilirsiniz.",
      },
      campaignDetails: {
        products: "Ürün Seçimi",
        keywords: "Anahtar Kelime Ekle",
        update: "Kampanya Düzenle",
        negative_keywords: "Negatif Anahtar Kelime Ekle",
        products_btn: "Ürünleri Ekle",
        keywords_btn: "Kelimeleri Ekle",
        negative_keywords_btn: "Kelimeleri Ekle",
        update_btn: "Güncelle",
        create_agency: "Yeni Ajans Ekle",
        update_agency: "Ajansı Düzenle",
        create_agency_btn: "Ekle",
        update_agency_btn: "Düzenle",
        create_user: "Yeni Kullanıcı Ekle",
        create_user_marketplace: "Admin Kullanıcısı Ekle",
        create_user_marketplace_btn: "Ekle",
        create_user_advertiser: "Reklamveren Kullanıcısı Ekle",
        create_user_advertiser_btn: "Ekle",
        select_advertiser: "Reklamveren Seçimi",
        select_advertiser_btn: "Devam Et",
        update_advertiser: "Reklamveren Düzenle",
        update_advertiser_btn: "Güncelle",
        invoice: "Bakiye Yükleme",
        invoice_btn: "Ödeme Yöntemi Seç",
        coupons: "Kupon Tanımla",
        coupons_btn: "Kupon Tanımla",
        transactions: "Bakiye Ekle",
        transactions_btn: "Bakiye Ekle",
        categories: "Yeni Kategori Ekle",
        categories_btn: "Kategorileri Ekle",
        settings: "Değişiklikleri Kaydet",
        settings_btn: "Anladım",
        negative_categories: "Negatif Kategori Ekle",
        negative_categories_btn: "Kategorileri Ekle",
        transaction_details: "Harcama Detayları",
        creatives: "Kreatif Ekle",
        creatives_btn: "Kreatif Ekle",
        approve_balance: "Bakiye Yükleme",
        approve_balance_btn: "İşlemi Onayla",
        approve_coupon: "Kupon Tanımla",
        approve_coupon_btn: "Kuponu Onayla",
        payment_options: "Bakiye Yükleme",
        payment_options_btn: "Yükle",
        add_user: "Yeni Kullanıcı Davet Et",
        add_user_btn: "Davetiye Gönder",
        targeting_settings: "Reklamveren Seçimi",
        targeting_settings_btn: "Seç",
        update_transaction: "Fatura No Düzenle",
        update_transaction_btn: "Kaydet",
        select_user: "Kullanıcı Tipi Seç",
        select_user_btn: "Devam",
        select_language: "Dil Değiştir",
        select_language_btn: "Değiştir",
        campaign_form: "Kampanya Özeti",
        campaign_form_btn: "Kampanyayı Oluştur",
        update_bid: "Teklifleri Düzenle",
        update_bid_btn: "Düzenle",
        show_vast_tag: "VAST Reklam Etiketi",
        show_vast_tag_btn: "Tamam",
        update_dayparting: "Gün Ayırma",
        update_dayparting_btn: "Düzenle",
        reject_creative: "Kreatif Reddetme",
        reject_creative_btn: "Onayla",
      },
      selectCampaign: {
        title: "Kampanya Tipi Seç",
        text: "Lütfen oluşturmak istediğiniz kampanya tipini seçiniz.",
        soon: "Yakında",
        sponsored_product_availability_title: "Sponsorlu Ürün",
        sponsored_product_availability_text:
          "Ürünlerinizi ilgili anahtar kelimelerle arama yapan veya benzer ürünleri görüntüleyen müşterilere tanıtın.",
        sponsored_brand_availability_title: "Sponsorlu Marka",
        sponsored_brand_availability_text:
          "Markanızı ilgili aramalarda ve benzer ürün görüntülemelerinde öne çıkararak marka bilinirliğinizi artırın.",
        sponsored_display_availability_title: "Görsel Reklam",
        sponsored_display_availability_text:
          "Site içi görsel reklamlarla ilgili kitlelere ulaşarak satışlarınızı artırın.",
        sponsored_store_availability_title: "Sponsorlu Mağaza",
        sponsored_store_availability_text:
          "Mağazanızın erişimini genişletin ve alışveriş yapanların mağazaları keşfetmesine yardımcı olun.",
        sponsored_video_availability_title: "Video Reklam",
        sponsored_video_availability_text:
          "Video reklamlarla ilgi çekici öğeler kullanarak hedef kitlenize ulaşın ve satışlarınızı artırın.",
        koctasLink: "Görsel Reklam Nedir?",
        sponsored_display_with_product_availability_title:
          "Ürünle Görsel Reklam",
        sponsored_display_with_product_availability_text:
          "Ürünlerinizi görsel reklamlarla ilgili kitlelere ulaşarak satışlarınızı artırın.",
        sponsored_video_with_product_availability_title: "Ürünle Video Reklam",
        sponsored_video_with_product_availability_text:
          "Ürün görünürlüğünüzü artırın; dinamik ve ilgi çekici video içerikleriyle kitlenizi etkileyin.",
      },
      success: {
        advertisers_success_title: "Davetiye Gönderildi",
        advertisers_success_text:
          "Girdiğiniz mail adresine bir davetiye gönderildi. Kullanıcıların hesaplarına erişmesi için davetiye linkine tıklayarak hesap oluşturması gerekmektedir.",
        reports_success_title: "Rapor Başarıyla Oluşturuldu",
        reports_success_text:
          "Rapor detay sayfasına “Tamam” butonuna tıklayarak devam edebilirsiniz.",
        invited_success_title: "Kullanıcı Davet Edildi",
        invited_success_text:
          "Kullanıcının listede gözükebilmesi için davetiye mailine tıklayarak şifre oluşturması gerekmektedir.",
        agencies_success_title: "Ajans Başarıyla Oluşturuldu!",
        agencies_success_text: "",
        multiple_advertisers_success_title: "Davetiye Gönderildi",
        multiple_advertisers_success_text:
          "Seçilen reklamverenler davet edildi.",
        invoice_success_title: "Bakiye Yükleme Başarılı!",
        invoice_success_text:
          "Bakiyeniz yüklenmiştir. Yeni kampanyalar oluşturabilirsiniz.",
        payment_success_title: "Bakiye Yükleme Talebiniz Alınmıştır!",
        payment_success_text:
          "Havale veya EFT ile ödemeleriniz onaylandıktan sonra hesabınıza aktarılacaktır. Onay süreci 2-3 iş günü sürebilmektedir.",
        craftgate_success_title: "Bakiye Yükleme Talebiniz Alındı!",
        craftgate_success_text:
          "Bakiyeniz en kısa sürede hesabınıza geçecektir.",
        create_advertiser_success_title: "Reklamveren Ekleme Başarılı!",
        create_advertiser_success_text:
          "Reklamvereni aktif hale getirmek için davet etmeniz gerekmektedir.",
        onboarding_success_title: "Tebrikler!",
        onboarding_success_text:
          "Onboarding sürecini başarıyla tamamladınız ve artık panelin tüm özelliklerine tam erişiminiz var! Yeni arayüzü keşfedin, etkileyici kampanyalar oluşturun ve reklamlarınızı zahmetsizce yönetin. Başlamak için “Tamam”a tıklayın!",
        file_success_title: "Dosya Başarıyla Yüklendi!",
        file_success_text:
          "Dosyanız başarıyla yüklendi ve işlendi. Şimdi sonraki adımlara geçebilirsiniz.",
        xml_success_title: "XML Başarıyla İşlendi!",
        xml_success_text:
          "XML verileri başarıyla işlendi. Şimdi sonraki adımlara geçebilirsiniz.",
      },
      error: {
        craftgate_title: "Bakiye Yüklenemedi!",
        craftgate_text: "Kredi kartı yüklemesi başarısız sonuçlandı.",
        file_title: "XML Dosya Yükleme Hatası",
        file_text:
          "XML dosyanız işlenirken bir sorun oluştu. Lütfen dosya formatının doğru olduğundan ve gerekli yapıya uyduğundan emin olun. Sorun devam ederse, daha fazla yardım için destekle iletişime geçin.",
        xml_title: "XML İşleme Hatası",
        xml_text:
          "XML verilerini işlerken bir sorun oluştu. Lütfen XML yapısının ve içeriğinin doğru olduğundan emin olun. Sorun devam ederse, daha fazla yardım için destekle iletişime geçin.",
      },
      warning: {
        campaign_warning_title: "Bu sayfadan çıkmak istediğinize emin misiniz?",
        campaign_warning_text: "Yaptığınız değişiklikler kaydedilmeyecektir!",
      },
    },
    navbar: {
      search: "Bir şeyler arayın...",
      help: "Yardım & Destek",
      site: "Satıcı Paneline Dön",
    },
    navbarPopover: {
      account: "Hesap Değiştir",
      language: "Dil Değiştir",
      logout: "Çıkış Yap",
    },
    notification: {
      formTitle: "Form Tamamlanmadı!",
      formText: "Lütfen boşluk ve gerekli alanları tamamlayınız!",
      paused: "{{value}} durdurma işlemi başarıyla gerçekleşti.",
      active: "{{value}} aktifleştirme işlemi başarıyla gerçekleşti.",
      campaigns: "Kampanyayı",
      reports: "Raporu",
      products: "Ürünü",
      keywords: "Anahtar kelimeyi",
      negative_keywords: "Negatif anahtar kelimeyi",
      categories: "Kategoriyi",
      negative_categories: "Negatif kategoriyi",
      placements: "Reklam yerleşimini",
      users: "Kullanıcıyı",
      advertisers: "Reklamvereni",
      agencies: "Ajansı",
      ad_placements: "Reklam yerleşimini",
      creatives: "Kreatifi",
      account_users: "Kullanıcıyı",
      statusError: "Bir hata meydana geldi.",
      activeReport: "Raporu arşivleme işlemi başarıyla gerçekleşti.",
      archivedReport: "Raporu arşivden çıkarma işlemi başarıyla gerçekleşti.",
      activeCampaign: "Kampanyayı arşivleme işlemi başarıyla gerçekleşti.",
      pausedCampaign: "Kampanyayı arşivleme işlemi başarıyla gerçekleşti.",
      archivedCampaign:
        "Kampanyayı arşivden çıkarma işlemi başarıyla gerçekleşti.",
      activeAgency: "Ajansı arşivleme işlemi başarıyla gerçekleşti.",
      archivedAgency: "Ajansı arşivden çıkarma işlemi başarıyla gerçekleşti.",
      invitedAdvertiser: "Reklamvereni arşivleme işlemi başarıyla gerçekleşti.",
      activeAdvertiser:
        "Reklamvereni arşivden çıkarma işlemi başarıyla gerçekleşti.",
      archivedAdvertiser:
        "Reklamvereni arşivleme işlemi başarıyla gerçekleşti.",
      archived_reports_bulk: "Raporu arşivleme işlemi başarıyla gerçekleşti.",
      archived_campaigns_bulk:
        "Kampanyayı arşivleme işlemi başarıyla gerçekleşti.",
      archived_users_bulk:
        "Kullanıcıyı arşivleme işlemi başarıyla gerçekleşti.",
      archived_advertisers_bulk:
        "Reklamvereni arşivleme işlemi başarıyla gerçekleşti.",
      archived_agencies_bulk: "Ajansı arşivleme işlemi başarıyla gerçekleşti.",
      archived_account_users_bulk:
        "Kullanıcıyı arşivleme işlemi başarıyla gerçekleşti.",
      keyword: "Lütfen en az 1 hedef anahtar kelime ekleyiniz.",
      category: "Lütfen en az 1 kategori ekleyiniz.",
      invite: "{{value}} davet işlemi başarıyla gerçekleşti.",
      full_invite: "Bütün davet işlemleri başarıyla gerçekleşti.",
      rejected: "Kreatif reddetme işlemi başarıyla gerçekleşti.",
      accepted: "Kreatif onaylama işlemi başarıyla gerçekleşti.",
      uninvitedAdvertiser:
        "Reklamvereni aktifleştirme işlemi başarıyla gerçekleşti.",
      settings_success: "Ayarlar başarıyla kaydedildi.",
      previous_invite_sent_too_recently:
        "{{value}} davet çok sık gönderildi. Yeniden denemek için bekleyin.",
      user_email_is_already_registered: "{{value}} mail adresi zaten kayıtlı.",
      copiedCampaign: "Kampanya başarıyla kopyalandı.",
      copyError: "Kampanya kopyalanırken bir hata oluştu.",
      editableFieldError:
        "Lütfen minimum değerden daha büyük bir değer girin, aksi takdirde değer buna göre ayarlanacaktır.",
      active_account_user:
        "Kullanıcıyı arşivleme işlemi başarıyla gerçekleşti.",
      archived_account_user:
        "Kullanıcıyı arşivden çıkarma işlemi başarıyla gerçekleşti.",
      bid: "{{value}} teklif güncellendi.",
      newBid: "Yeni teklif: {{value}}.",
      creativeUpload: "Kreatif başarıyla eklendi!",
      advertiserBalanceLowSubject: "Bakiyeniz Azalıyor!",
      advertiserBalanceLowBody:
        "Reklamveren bakiyeniz kritik bir seviyeye düştü. Reklamlarınızın kesintisiz devam etmesi için şimdi bakiye yükleyin.",
      campaignKeywordsBelowMinBidSubject:
        "Kampanya #{{value}} İçindeki Anahtar Kelime Teklifleri Çok Düşük",
      campaignKeywordsBelowMinBidBody:
        "Kampanyanızdaki (ID: {{value}}) tüm anahtar kelimelerin teklifleri, marketplace'in minimum teklif gereksiniminin altında. Görünürlüğü artırmak için tekliflerinizi güncelleyin.",
      campaignCategoriesBelowMinBidSubject:
        "Kampanya #{{value}} İçindeki Kategori Teklifleri Çok Düşük",
      campaignCategoriesBelowMinBidBody:
        "Kampanyanızdaki (ID: {{value}}) tüm kategorilerin teklifleri minimum teklif gereksiniminin altında. Görünürlüğü artırmak için tekliflerinizi güncelleyin.",
      campaignProductsStoppedSubject:
        "Kampanya #{{value}} İçindeki Ürünler Pasif",
      campaignProductsStoppedBody:
        "Kampanyanızdaki (ID: {{value}}) ürünler duraklatılmış durumda. Kampanyanızın etkili bir şekilde devam etmesi için yeni ürünler eklemeyi düşünün.",
      balanceAction: "Bakiye Yükle",
      productAction: "Yeni Ürünler Ekle",
      bidAction: "Teklifleri Güncelle",
      segments: "Segment",
    },
    suggestedTag: {
      text: "ÖNERİLEN",
    },
    campaignCard: {
      graph: "Grafikte Göster",
      hide: "Gizle",
      message: "Son 30 Gün",
    },
    campaignForm: {
      firstStep: {
        keywordStepTitle: "Kelime Bilgileri",
        categoryStepTitle: "Kategori Bilgileri",
        targetingStepTitle: "Kitle Bilgileri",
        campaignInfoTitle: "Kampanya Bilgileri",
        campaignInfoText:
          "Kampanyalar tablosunda gösterilmek üzere bir isim seçin. Bu isim müşterilere gösterilmez, size özeldir. Reklam yapmak istediğiniz ürünlerle ilgili bir isim seçmenizi önerilir.",
        campaignLabel: "Kampanya İsmi",
        campaignSub: "Kampanya ismini girin.",
        dateInfoTitle: "Kampanya Tarihi",
        dateInfoText: "Kampanyanızın yayınlanma süresini belirleyin.",
        startDateLabel: "Başlangıç Tarihi",
        startDateSub: "Başlangıç tarihini seçiniz.",
        stopped: "Şimdi Başlasın ve Durdurana Kadar Devam Etsin",
        stoppedText:
          "Kampanyanız bugün başlayarak siz durdurana kadar devam eder.",
        stoppedSub: "Başlangıç Tarihi: {{value}}",
        defined: "Başlangıç - Bitiş Tarihi",
        definedText: "Kampanyanızın yayınlanacağı tarih aralığını belirleyin.",
        budget: "Bütçe",
        totalBudget: "Toplam Bütçe Sınırı",
        totalBudgetLabel: "Toplam Bütçe",
        dailyBudgetLabel: "Günlük Bütçe",
        dailyBudgetExtra:
          "Kampanyanızın günlük harcaması maksimum belirlediğiniz tutarda olacaktır.",
        dailyBudgetFilter: "Günlük Bütçe",
        dailyBudgetText: "Günlük bütçenizi belirleyin.",
        totalBudgetExtra:
          "Kampanyanızın her gün maksimum belirlediğiniz tutarda harcama yapacaktır.",
        totalBudgetFilter: "Toplam Bütçe",
        totalBudgetText: "Toplam bütçenizi belirleyin.",
        flexibleBudgetLabel: "Günlük Bütçe",
        bid: "Teklif",
        bidText:
          "Kampanyanızın teklif türünü seçin. Reklam harcamalarınız seçtiğiniz teklif türüne göre tıklama başına yapılır.",
        dynamicBid: "Otomatik Teklif",
        dynamicBidText: "Tıklama başına harcamanız otomatik olarak belirlenir.",
        targetAcosTitle: "Hedef ACoS",
        targetAcosText:
          "Reklamlardan gelen satışlarınızın ne kadarını reklama harcamak istediğinizi seçin.",
        default: "Varsayılan",
        advanced: "Gelişmiş",
        fixedBid: "Sabit Teklif",
        fixedBidText:
          "Anahtar kelime ve kategoriler için tıklama başına sabit bir maliyet belirleyin.",
        productTitle: "Ürün Seçimi",
        productText: "Reklam yapmak istediğiniz ürünleri seçin.",
        acosTooltip:
          "ACoS bir satışın reklam maliyeti anlamına gelir. Satışlardan gelen kazancınızın ne kadarını reklama harcamak istediğinizi gösteren orandır. Örneğin %10 ACoS değerine sahip bir kampanyada her 10 TLlik satış için 1 TLlik reklam harcaması yaparsınız.",
        autoTargeting: "Otomatik Hedefleme",
        autoTargetingText:
          "Kampanyanız için en uygun anahtar kelime veya kategoriler hedeflenecektir.",
        manualTargeting: "Manuel Hedefleme",
        manualTargetingText:
          "Kampanyanızın görüntülenmesini istediğiniz anahtar kelime veya kategorileri hedefleyin.",
        acosError: "ACoS 0.0001 ile 100 arası olmalıdır.",
        suggestedBidText: "En düşük teklif: ",
        creativeTitle: "Kreatif Bilgileri",
        videoText:
          "Kampanyanızda kullanmak istediğiniz kreatifleri yükleyebilirsiniz.",
        urlTitle: "Yönlendirme Bağlantısı",
        urlText:
          "Reklamınıza tıklayan kullanıcıların yönlendirileceği sayfadır.  Kullanıcıları pazaryeri içerisinde bir sayfaya yönlendirmeniz gerekir.",
        displayDraggerTitle:
          "Kreatif yüklemek için <strong>.PNG, .JPG, .GIF</strong> dosyası yükleyin",
        displayDraggerText: "veya buraya sürükleyip bırakın",
        creativeLabel: "Kreatif İsmi",
        size: "Boyut:",
        listWarningSize:
          "Herhangi bir kreatif için maksimum dosya boyutu 5120 KB'tır.",
        listWarningType: ".jpg, .png .gif dosya türleri kabul edilir.",
        listWarningRecommended:
          "Reklam yerleşimleri ve kabul edilen kreatif boyutları için kreatif boyutlarını inceleyebilirsiniz.",
        modalTitle: "Kreatif Boyutları",
        modalText:
          "Listelenen tüm boyutlarda kreatif yüklemesi yapabilirsiniz. Önerilen boyutlarda kreatif yüklemek reklam performansınızı artırır.",
        recommended: "Önerilen Boyut",
        accepted: "Kabul Edilen Boyutlar",
        creativeError: "Lütfen kreatif ismi girin.",
        urlError: "Lütfen bir URL girin.",
        budgetError: "Bütçeniz {{value}} üstünde olmalıdır.",
        budgetErrorMessage: "Lütfen bütçe giriniz.",
        invalid: "GEÇERSİZ BOYUT",
        advancedOptions: "Gelişmiş Seçenekler",
        placements: "Reklam Yerleşimi",
        placementsText:
          "Reklamınızın hangi alanlarda gösterilmesini istediğinizi belirleyiniz.",
        in_search: "Arama Sonuçları",
        in_search_text: "Reklamlarınız ilgili arama sonuçlarında görüntülenir.",
        in_product_detail_page: "Ürün Detay Sayfaları",
        in_product_detail_page_text:
          "Reklamlarınız ilgili ürün detay sayfalarında görüntülenir.",
        in_category: "Kategoriler",
        in_category_text:
          "Reklamlarınız ilgili kategori sayfalarında görüntülenir.",
        in_home_page: "Anasayfa",
        in_home_page_text: "Reklamlarınız anasayfada  görüntülenir.",
        in_collection: "Koleksiyon",
        in_collection_text:
          "Reklamlarınız ilgili koleksiyon sayfalarında görüntülenir.",
        storeTitle: "Mağaza Seçimi",
        storeText: "Reklamını yapmak istediğiniz mağazaları seçin.",
        info: "GENEL BİLGİLER",
        advertiser: "Reklamveren İsmi:",
        budgetType: "Bütçe Tipi:",
        bidType: "Teklif Tipi:",
        standart: "Otomatik Teklif",
        fixed: "Sabit Teklif",
        max_acos: "Hedef ACoS",
        fixed_info: "Teklif:",
        max_acos_info: "ACoS:",
        targeting: "HEDEFLEME",
        custom: "Özel",
        durationText:
          "Kampanyanız her gün günlük harcama hedefine ulaşması halinde <strong>{{value}} gün</strong> yayında kalacak.",
        dayParting: "Gün Ayırma",
        dayPartingText:
          "Kampanyanızın aktif kalacağı gün ve saat aralıklarını belirleyiniz.",
        allTimes: "Tüm Zamanlar",
        allTimesText:
          "Kampanyanız tüm gün ve tüm saat aralıklarında yayınlanır.",
        scheduled: "Tarih Aralığı Seç",
        scheduledText:
          "Kampanyanız belirlediğiniz gün ve saat aralıklarında yayınlanır.",
        dayPartingTableText:
          "Lütfen istediğiniz günü ve saat aralığını seçmek için aşağıdaki <strong>kutucuklardan</strong> uygun olanları işaretleyin. Eğer bir gün boyunca tüm saat aralıklarını seçmek istiyorsanız <strong>'Tüm Gün'</strong> seçeneğini kullanabilirsiniz.",
        allDay: "Tüm Gün",
        clear: "Seçimleri Temizle",
        videoSideTitle: "Kreatif Ekle",
        videoSideText:
          "Kreatif tiplerinizi seçerek, formatlarınızı belirleyin.",
        firstDrawerTitle: "Adım 1: Kreatif Tipini Seçin ",
        creativeTypeTitle: "Kreatif Tipi",
        creativeTypeText: "Reklamınıza uygun kreatif tipini seçiniz.",
        mp4Title: "Video Dosyası ile Ekle",
        mp4Text: "Kreatifinizi .MP4 video dosyasıyla oluşturabilirsiniz.",
        vastTitle: "VAST Reklam Etiketi ile Ekle",
        vastText:
          "Kreatifinizi verilerle desteklenmiş VAST Reklam Etiketi ile oluşturabilirsiniz.",
        video_upload_second_drawer_title: "Adım 2: Video Dosyası ile Ekle",
        creativeNameTitle: "Kreatif İsmi",
        creativeNameText: "Kreatifiniz için isim belirleyiniz.",
        creativeVideoLabel: "Kreatif ismi girin.",
        video_upload: "Video Yükle",
        video_upload_drawer:
          "Reklamlarınızda gösterilecek video dosyasını yükleyin.",
        videoDraggerTitle:
          "Yüklemek için bir <strong>.MP4</strong> dosyası seçin",
        videoDraggerText: "veya buraya sürükleyip bırakın.",
        maxVideoSize:
          "Herhangi bir kreatif için maksimum dosya boyutu 10 MB'tır.",
        videoFormat: "Sadece .mp4 dosya türü kabul edilir.",
        changeVideo: "Videoyu Değiştir",
        creativeName: "<strong>İsim:</strong> {{value}}",
        updateDrawerTitle: "Kreatif Düzenle",
        videoAd: "Video Reklam",
        vast: "VAST Reklam Etiketi",
        vast_drawer:
          "Reklamlarınızda gösterilecek video için VAST reklam etiketini  ekleyin.",
        videoInnerTitle: "Videonuz işlenme sürecinde!",
        videoInnerText:
          "Videonuz sistem tarafından işlendikten sonra, görüntüleyebilirsiniz!",
        creativeLanguageTitle: "Kreatif Dili",
        creativeLanguageText: "Kreatif içeriğiniz için dilleri seçin.",
        creativeLanguageSearch: "Dil ara",
        autoTargetingProductWarning:
          "Otomatik hedefleme kullanabilmek için <strong>bağlantılı ürünler</strong> adımından en az <strong>“1”</strong> ürün seçmelisiniz.",
        connectedProductsTitle: "Bağlantılı Ürünler",
        connectedProductsText:
          "Yukarıdaki kreatiflerle bağlantılı, sizin için derlenen ürünler.",
        showProducts: "Bağlantılı Ürünleri  Aç",
        showProductsText:
          "Kreatiflerinizle ilişkili ürünleri eklemek için bu seçeneği etkinleştirin. Kreatife ilgili ürünleri eklemek, daha alakalı reklamlar göstermenize yardımcı olur.",
        promoteProduct: "Ürünleri Göster",
        promoteProductText:
          "Bu özellik kampanyanıza eklediğiniz ürünlerin önde gösterilmesini sağlar.",
        promoteProductWarning: "Seçiminiz daha sonradan değiştirilemez.",
        closeProducts: "Bağlantılı Ürünleri Kapat",
        closeProductsText:
          "Kreatiflerle ilişkili ürünleri kullanmamak tavsiye edilmez.",
        spendingTitle: "Harcama Dağılımı",
        spendingText:
          "Reklam bütçenizin harcanacağı zaman dağılımı ve yoğunluğunu belirleyin.",
        spendingSub: "Harcama dağılımınızı belirleyin.",
        asap: "En Kısa Sürede",
        front_loaded: "Başlangıç Ağırlıklı",
        evenly: "Eşit Olarak",
        frontPercentage: "Ağırlık Yüzdesi",
        multiRegion: "Bölge Seçimi",
        multiRegionText:
          "Kampanyanızı birden fazla veya belirli bölgelerde yürütebilirsiniz.",
        allRegions: "Tüm Bölgeler",
        allRegionsText: "Kampanyanız tüm bölgelerde yayınlanacaktır.",
        selectedRegions: "Seçilen Bölgeler",
        selectedRegionsText:
          "Kampanyanız yalnızca seçilen bölgelerde yayınlanacaktır.",
        regionPlaceholder: "Bölge seçiniz",
        evenlyText:
          "Kampanya istikrarlı bir şekilde ilerliyor ve tüm zaman dilimlerine eşit şekilde odaklanılıyor.",
        asapText:
          "Bütçe hızla harcanarak kampanyanın başında hızlı sonuçlar alınır.",
        front_loadedText:
          "Harcamanın büyük bir kısmı başlangıçta yapılır ve zamanla kademeli olarak azalır.",
        displayUploadTitle: "Görsel Dosyası ile Ekle",
        displayUploadText:
          "Kreatifinizi bir .JPEG, .PNG veya .GIF dosyası yükleyerek oluşturabilirsiniz.",
        display_upload_second_drawer_title: "Adım 2: Görsel Dosya ile Ekle",
        display_upload: "Görsel Yükle",
        display_upload_drawer:
          "Reklamlarınızda göstermek için görsel dosyasını yükleyin.",
        changeDisplay: "Görseli Değiştir",
        displayErrorTitle: "Görselde bir hata var!",
        displayErrorText: "Yüklemeye çalıştığınız görselin boyutları yanlış.",
        newDisplay: "Yeni Görsel Yükle",
        bulk: "Toplu Yükleme",
        scriptUploadTitle: "Script Kodu Kullanarak Kreatif Oluştur",
        scriptUploadText: "Script Kodu ile kreatifinizi oluşturabilirsiniz.",
        script: "Script Kodu",
        script_drawer: "Reklamlarınızda göstermek için kodu girin.",
        creativeSizeTitle: "Reklam Boyutu",
        creativeSizeText: "Kreatif için reklam boyutunu seçin.",
        creativeSizeSearch: "Boyut Seçin",
        vast_second_drawer_title: "Adım 2: VAST Etiketi Kullanarak Ekle",
        script_second_drawer_title: "Adım 2: Script Kodu Kullanarak Ekle",
        code: "Kodu Düzenle",
        preview: "Önizleme",
        errorStates: {
          name: "Lütfen kampanya ismi girin.",
          keywordErr: "Anahtar kelimeniz eşsiz olmalıdır.",
          advertiserErr: "Reklamveren aktif değil.",
          placementsError: "Lütfen en az bir reklam yerleşimi seçiniz.",
          productLimitErr: "En fazla 250 ürün ekleyebilirsiniz.",
          sameKeyword:
            "Aynı kelime hem hedef anahtar kelime hem de negatif anahtar kelime olarak eklenemez.",
          duplicateKeyword: "Anahtar kelime zaten kampanyaya eklenmiş.",
          duplicateNegativeKeyword:
            "Negatif anahtar kelime zaten kampanyaya eklenmiş.",
          generalErr: "Bir hata meydana geldi.",
          campaignLimitErr: "Maksimum kampanya sayısına ulaşıldı.",
          creativeLimitErr:
            "SMO pazaryerleri için oluşturulabilecek maksimum kreatif sayısı 100'dür.",
          creativeSizeErr:
            "Yüklediğiniz dosya boyutu çok büyük. Lütfen 150 KB'tan küçük bir dosya yükleyin.",
          vast: "Lütfen geçerli bir VAST etiketi girin.",
          keywordLimitErr:
            "Bir kampanyaya en fazla 100 anahtar kelime eklenebilir.",
          categoryLimitErr: "Bir kampanyaya en fazla 100 kategori eklenebilir.",
          frontPercentage: "Ağırlık yüzdesi 0 ile 100 arasında olmalıdır.",
          creativeDimensionErr:
            "Lütfen doğru boyutlara sahip bir kreatif yükleyin.",
          languageErr: "Lütfen bir dil ekleyin.",
          sizeErr: "Lütfen bir boyut seçin.",
          script: "Lütfen geçerli bir HTML kreatif girin.",
        },
      },
      secondStep: {
        wordTargeting: "Kelime Hedefleme",
        wordTargetingText:
          "Reklamınızın hangi kelimelerde gösterileceğini belirleyin.",
        categorySub:
          "Reklamınızın hangi kategorilerde gösterileceğini belirleyin.",
        autoTargeting: "Otomatik Hedefleme",
        autoTargetingText:
          "Kampanyanız için en uygun anahtar kelime veya kategoriler otomatik hedeflenir.",
        manualTargeting: "Manuel Hedefleme",
        manualTargetingText: "Reklamınız için anahtar kelimeleri belirleyin.",
        defaultBid: "Varsayılan Teklif",
        keywordTitle: "Hedef Anahtar Kelimeler",
        keywordText:
          "Reklamınızın gösterilmesini istediğiniz anahtar kelimeleri ekleyin.",
        negativeTitle: "Negatif Anahtar Kelimeler",
        negativeText:
          "Reklamınızın gösterilmesini istemediğiniz kelimeleri ekleyin.",
        placementTitle: "Hedefleme Türü",
        placementText:
          "Kampanyanızın gösterileceği alanları hedefleyebilirsiniz.",
        search: "Anahtar Kelime Hedefleme",
        searchText:
          "Kampanyanızın görüntülenmesini istediğiniz anahtar kelimeleri hedefleyin.",
        categories: "Kategori Hedefleme",
        categoriesText:
          "Kampanyanızın görüntülenmesini istediğiniz kategorileri hedefleyin.",
        categoryTargeting: "Kategori Hedefleme",
        categoryTargetingTitle:
          "Reklamınızın gösterilmesini istediğiniz kategorileri belirleyin.",
        categoryTargetingText:
          "Reklamınızın gösterilmesini istediğiniz kategorileri ekleyin.",
        category: "Hedef Kategoriler",
        negativeCategoryTitle: "Negatif Kategoriler",
        negativeCategoryText:
          "Reklamınızın gösterilmesini istemediğiniz kategorileri ekleyin.",
        autoCategoryText: "Reklamınız en uygun kategorilerde gösterilecektir.",
        manualCategoryText:
          "Reklamınız için hedefleyeceğiniz kategorileri belirleyebilirsiniz.",
        placement: "Hedefleme",
        targetingText:
          "Kampanyanızın hedefleme türünü seçin. Kampanyanız hedefleme tercihinize göre müşterilere gösterilir.",
      },
      thirdStep: {
        targeting: "Kitle Seçimi",
        targetingText:
          "Reklamınızın hangi kitlelere gösterilmesini istediğinizi belirleyiniz.",
        autoTargeting: "Otomatik Hedefleme",
        autoTargetingText:
          "Reklamınızı en uygun anahtar kelime ve kategorilerde gösterilecektir.",
        manualTargeting: "Manuel Hedefleme",
        manualTargetingText:
          "Ürünlerinizin hangi kitlelere gösterileceğini belirleyebilirsiniz.",
        platform: "Platform Hedefle",
        platformText:
          "Reklamınızın hangi platformu kullanan insanlara gösterilmesini istediğinizi belirleyiniz.",
        tablet: "Tablet",
        mobile: "Mobil",
        app: "Mobil Uygulama",
        desktop: "Masaüstü",
        age: "Yaş Hedefle",
        ageText:
          "Reklamınızın hangi yaş grubundaki insanlara gösterilmesini istediğinizi belirleyiniz.",
        city: "Şehir Hedefle",
        cityText:
          "Reklamınızın hangi şehirdeki insanlara gösterilmesini istediğinizi belirleyiniz.",
        allCities: "Bütün Şehirler",
        selectedCities: "Seçili Şehirler",
        cityPlaceholder: "Şehir Seçiniz",
        customer: "Müşteri Hedefleme",
        customerText:
          "Reklamınızın alışveriş geçmişlerine göre hangi müşterilere gösterilmesini istediğinizi belirleyiniz.",
        presentCustomers: "Mevcut Müşteriler",
        presentCustomersText:
          "Reklamını yaptığınız ürünleri satın almış müşterileri hedefleyin.",
        newCustomers: "Yeni Müşteriler",
        newCustomersText:
          "Reklamını yaptığınız ürünleri hiç satın almamış müşterileri hedefleyin",
        gender: "Cinsiyet Hedefleme",
        genderText:
          "Reklamınızın hangi cinsiyetteki insanlara gösterilmesini istediğinizi belirleyiniz.",
        female: "Kadın",
        male: "Erkek",
        audience: "Hedef Kitle Seçimi",
        audienceText: "Reklamınızın gösterileceği hedef kitleyi belirleyin.",
        segment: "Segment Hedefleme",
        segmentText: "Reklamınızın gösterileceği segmentleri belirleyin.",
        allSegments: "Tüm Segmentler",
        selectedSegments: "Seçili Segmentler",
        segmentPlaceholder: "Segment Seçin",
      },
    },
    columnPopover: {
      title: "Kolonları Düzenle",
    },

    empty: {
      product_title: "Reklamını yayınlamak istediğiniz ürünleri seçin.",
      store_title: "Reklamını yayınlamak istediğiniz mağazaları seçin.",
      dimensions_title: "Henüz hiç kırılım eklemediniz.",
      product_text:
        "Soldaki ürün listesinde yer alan ekle butonuna tıklayarak reklam yapmak istediğiniz ürünlerinizi ekleyin.",
      store_text:
        "Soldaki mağaza listesinde yer alan ekle butonuna tıklayarak reklam yapmak istediğiniz mağazaları ekleyin.",
      dimensions_text:
        "Reklam sonuçlarınızın raporlardaki görünümünü özelleştirmek için kırılımlar ekleyebilirsiniz.",
      null_title: "Veri Yok",
      null_text: "Tabloda gösterilecek veri bulunmamaktadır.",
      categories_title: "Lütfen hedefleyeceğiniz kategorileri ekleyin.",
      categories_text:
        "Bir kategori girerek veya önerilen kategoriler tablosundaki ekle butonuna tıklayarak reklamınızın gösterilmesini istediğiniz kategorileri belirleyin.",
      keywords_title: "Lütfen hedefleyeceğiniz anahtar kelimeleri ekleyin.",
      keywords_text:
        "Bir kelime girerek veya önerilen anahtar kelimeler tablosundaki ekle butonuna tıklayarak reklamınızın gösterilmesini istediğiniz anahtar kelimeleri belirleyin.",
      negative_keywords_title: "Henüz negatif anahtar kelime eklemediniz.",
      negative_keywords_text:
        "Bir kelime girerek reklamınızın gösterilmesini istemediğiniz negatif anahtar kelimeleri belirleyebilirsiniz.",
      negative_categories_title: "Henüz negatif kategori eklemediniz.",
      negative_categories_text:
        "Bir kategori girerek reklamınızın gösterilmesini istemediğiniz negatif kategorileri belirleyebilirsiniz.",
      campaigns_table_title: "Henüz Bir Kampanya Oluşturmadınız.",
      campaigns_table_text:
        "Yeni Kampanya Ekle butonuna tıklayarak ilk kampanyanızı oluşturabilirsiniz.",
      reports_table_title: "Henüz Bir Rapor Oluşturmadınız.",
      reports_table_text:
        "Yeni Rapor Ekle butonuna tıklayarak ilk raporunuzu oluşturabilirsiniz.",
      invoices_table_title: "Henüz Bakiye Yüklemediniz",
      invoices_table_text:
        "Hesap yöneticinizle iletişime geçerek reklam bakiyesi ekleyebilirsiniz.",
      advertisers_table_title:
        "Hesabınıza bağlı bir reklamveren bulunmamaktadır",
      advertisers_table_text: "Lütfen hesap yöneticinizle iletişime geçiniz.",
      agencies_table_title: "Henüz Bir Ajans Oluşturmadınız",
      agencies_table_text:
        "Yeni Ajans Ekle butonuna tıklayarak ajans oluşturabilirsiniz.",
      transactions_table_title: "Henüz Bakiye Yüklemediniz",
      transactions_table_text:
        "Bakiye Yükle butonuna tıklayarak reklamverenlerinize reklam bakiyesi ekleyebilirsiniz.",
      coupons_table_title: "Henüz Kupon Oluşturmadınız",
      coupons_table_text:
        "Kupon tanımla butonuna tıklayarak reklamverenlerinize kupon tanımlayabilirsiniz.",
      account_users_table_title: "Henüz Kullanıcı Oluşturmadınız",
      account_users_table_text:
        "Yeni kullanıcı ekle butonuna tıklayarak kullanıcı oluşturabilirsiniz.",
      archived_table_reports_title: "Arşivlenmiş Rapor Yok",
      archived_table_campaigns_title: "Arşivlenmiş Kampanya Yok",
      archived_table_agencies_title: "Arşivlenmiş Ajans Yok",
      archived_table_account_users_title: "Arşivlenmiş Kullanıcı Yok",
      archived_table_advertisers_title: "Arşivlenmiş Reklamveren Yok",
      invited_table_users_title: "Davet Edilmiş Kullanıcı Yok",
      searched_table_title: "Aramanızla eşleşen bir kayıt bulunamadı.",
      searched_table_text: "",
      searched_title: "Aramanızla eşleşen bir kayıt bulunamadı.",
      searched_text: "",
      products_table_title: "Henüz ürün eklemediniz.",
      products_table_text:
        "Reklam için ürün ekle butonuna tıklayarak reklam yapmak istediğiniz ürünlerinizi ekleyebilirsiniz.",
      keywords_table_title: "Henüz anahtar kelime eklemediniz.",
      keywords_table_text:
        "Bir kelime girerek reklamınızın gösterilmesini istediğiniz anahtar kelimeleri belirleyebilirsiniz.",
      categories_table_title: "Henüz kategori eklemediniz.",
      categories_table_text:
        "Bir kelime girerek reklamınızın gösterilmesini istediğiniz kategorileri belirleyebilirsiniz.",
      negative_keywords_table_title:
        "Henüz negatif anahtar kelime eklemediniz.",
      negative_keywords_table_text:
        "Bir kelime girerek reklamınızın gösterilmesini istemediğiniz negatif anahtar kelimeleri belirleyebilirsiniz.",
      negative_categories_table_title: "Henüz negatif kategori eklemediniz.",
      negative_categories_table_text:
        "Bir kategori girerek reklamınızın gösterilmesini istemediğiniz negatif kategorileri belirleyebilirsiniz.",
      sync_logs_table_title: "Senkronizasyon Geçmişi Verisi Yok",
      sync_logs_table_text: "Senkronizasyon geçmişi verisi bulunamadı.",
      ad_placements_table_title: "Henüz reklam yerleşimi eklemediniz.",
      ad_placements_table_text:
        "Yeni reklam yerleşimi ekle butonuna tıklayarak reklam yerleşimi ekleyebilirsiniz.",
      creatives_table_title: "Henüz bir kreatif eklenmedi!",
      creatives_table_text:
        "Eklenen kreatifleri daha sonra bu alanda görüntüleyebilirsiniz.",
      creatives_relations_table_title: "Henüz bir kreatif oluşturmadınız.",
      creatives_relations_table_text:
        "Kampanya formundan yeni kreatif oluşturabilirsiniz.",
      creativeForm_title: "Henüz bir kreatif eklenmedi!",
      creativeForm_text: "Önerilen kreatif boyutları:",
      invites_table_title: "Henüz kullanıcı davet etmediniz.",
      invites_table_text:
        "Yeni kullanıcı ekle butonuna tıklayarak kullanıcı davet edebilirsiniz.",
      review_title: "Şu anda incelenecek bir kreatif bulunmamaktadır.",
      review_text:
        "Tüm kreatifler onaylandı ya da henüz yeni bir kreatif yok. Onay bekleyen kreatifler bu ekranda gözükecektir.",
      accepted_table_creatives_title: "Henüz onaylanan kreatif yok!",
      accepted_table_creatives_text:
        "Tüm kreatifler reddedildi ya da henüz yeni bir kreatif yok.",
      rejected_table_creatives_title: "Henüz reddedilen bir kreatif yok!",
      rejected_table_creatives_text:
        "Tüm kreatifler onaylandı ya da henüz yeni bir kreatif yok.",
      pending_table_creatives_title: "Henüz bir kreatif eklenmedi!",
      pending_table_creatives_text:
        "Tüm kreatifler incelendi ya da henüz yeni bir kreatif yok.",
      blacklist_table_title: "Kelime kısıtlaması bulunamadı.",
      blacklist_table_text:
        "Yeni Kelime Ekle butonuna tıklayarak kısıtlama ekleyebilirsiniz.",
      cities_table_title: "Henüz şehir eklemediniz.",
      cities_table_text: "",
      age_ranges_table_title: "Henüz yaş aralığı eklemediniz.",
      age_ranges_table_text: "",
      genders_table_title: "Henüz cinsiyet eklemediniz.",
      genders_table_text: "",
      platforms_table_title: "Henüz platform eklemediniz.",
      platforms_table_text: "",
      customers_table_title: "Henüz müşteri eklemediniz.",
      customers_table_text: "",
      report_loading_title: "Rapor Oluşturuluyor",
      report_loading_text:
        "Raporunuz şu anda oluşturulmakta. Bu işlem, raporun karmaşıklığı ve boyutuna bağlı olarak biraz zaman alabilir.",
      data_sources_table_title: "Veri Kaynağı Bulunamadı",
      data_sources_table_text:
        "Şu anda herhangi bir veri kaynağı bulunmamaktadır. İçe aktarma detaylarını ve geçmişini görmek için yeni bir veri kaynağı ekleyin.",
      logs_title: "Log Bulunamadı",
      logs_text:
        "Bu kategori altında şu anda kayıtlı log bulunmamaktadır. İlgili durum meydana geldiğinde, loglar burada görüntülenecektir.",
      segments_table_title: "Henüz herhangi bir segment eklemediniz.",
      segments_table_text: "",
    },
    endDatePicker: {
      label: "Bitiş Tarihi",
      campaigns: "Kampanya bitiş tarihini seçin.",
      reports: "Rapor bitiş tarihini seçin.",
      button: "Bitiş Tarihi Yok",
    },
    reportForm: {
      title: "Rapor Bilgileri",
      nameTitle: "Rapor İsmi",
      nameText: "Raporlar tablosunda gösterilmek üzere bir isim girin.",
      nameSub: "Rapor isminizi girin.",
      reportTypeTitle: "Gönderim Türü",
      reportTypeText:
        "Raporunuzun gönderim türü seçin. Raporunuz gönderim türü tercihinize göre teslim edilir.",
      static: "Sabit",
      staticText: "Raporunuz anlık olarak oluşturulur.",
      scheduled: "Zamanlanmış",
      scheduledText: "Raporunuz tercihinize göre mail olarak iletilir.",
      frequency: "Raporunuzun gönderim türünü seçin.",
      daily: "Günlük",
      weekly: "Haftalık",
      monthly: "Aylık",
      sendDate: "Raporunuzun ilk gönderim tarihini seçin.",
      firstDay: "Ayın İlk Günü",
      lastDay: "Ayın Son Günü",
      exportType: "Raporunuzu hangi formatta almak istediğinizi seçin.",
      mail: "Raporunuzun gönderileceği e-posta adreslerini belirleyin.",
      dateTitle: "Rapor Tarihi",
      dateText: "Raporunuzun başlangıç ve bitiş tarihini seçin.",
      startDateSub: "Rapor başlangıç tarihini seçin.",
      reportKindTitle: "Rapor Türü",
      reportKindText:
        "Hazır raporlar kullanarak önceden oluşturulmuş raporlar ile kolaylıkla performansınızı ölçebilirsiniz. Özelleştirilmiş rapor ile seçtiğiniz kırılım, metrik ve filtreleri içeren özel rapor oluşturabilirsiniz.",
      predefined: "Hazır Rapor",
      predefinedPlaceholder: "Rapor Türü",
      special: "Özelleştirilmiş Rapor",
      breaks: "Kırılımlar",
      breaksText: "Raporunuza dahil etmek istediğiniz kırılımları seçin.",
      breaksPlaceholder: "Kırılım arayınız",
      maxWarning: "*Raporunuza en fazla 4 adet kırılım ekleyebilirsiniz.",
      clear: "TEMİZLE",
      metrics: "Metrikler",
      metricsText: "Raporunuza dahil etmek istediğiniz metrikleri seçin.",
      filters: "Filtreler:",
      filtersText:
        "Kırılım ve metriklere uygulamak istediğiniz filtreleri belirleyin.",
      product: "Ürüne göre performans",
      product_category: "Ürün kategorisine göre performans",
      product_brand: "Markaya göre performans",
      keyword: "Anahtar Kelimeye göre performans",
      campaign: "Kampanyaya göre performans",
      advertiser: "Reklamveren Performansı",
      agency: "Ajans Performansı",
      errorStates: {
        date: "Lütfen bir tarih seçiniz.",
        mail: "Lütfen bir e-posta giriniz.",
        type: "Lütfen rapor türü seçiniz.",
        metric: "Lütfen en az bir metrik seçiniz.",
      },
      dimensions: {
        campaignName: "Kampanya ismi",
        campaignBidType: "Teklif Türü",
        campaignBudgetType: "Bütçe Türü",
        product: "Ürün",
        categoryName: "Kategori Sayfaları",
        keywordText: "Anahtar Kelime",
        productName: "Ürün",
        productBrand: "Marka",
        day: "Gün",
        week: "Hafta",
        month: "Ay",
        year: "Yıl",
        hour: "Saat",
        advertiser: "Reklamveren ID",
        advertiserName: "Reklamveren",
        agency: "Ajans ID",
        agencyName: "Ajans",
        customerDeviceType: "Müşteri Cihaz Türü",
        saleProductName: "Dolaylı Satış Ürünü",
        placementId: "Yerleşim ID",
        customerType: "Müşteri Türü",
        productRetailerTaxonomy: "Ürün Kategorisi",
        adPlacementCustomKey: "Reklam Yerleşim ID",
        adPlacementName: "Reklam Yerleşim İsmi",
        placementText: "Hedeflenen Yerleşim İsmi",
        campaignAdType: "Kampanya Türü",
        campaignBudget: "Bütçe",
        campaignFixedBid: "Kampanya Sabit Teklif",
        keywordBid: "Anahtar Kelime Teklifi",
        categoryBid: "Kategori Teklifi",
        advertiserResourceID: "Satıcı ID",
        productSKU: "Ürün ID",
        customerDeviceEnvironmentType: "Müşteri Cihazı Ortam Türü",
        campaignDailyBudget: "Günlük Bütçe",
        campaignTotalBudget: "Toplam Bütçe",
      },
      metric: {
        sumImpression: "Görüntülenme",
        sumViewableImpression: "Aktif Görüntülenme",
        sumClick: "Tıklama",
        CTR: "Tıklama Oranı",
        CVR: "Dönüşüm Oranı",
        sumSale: "Satış Adedi",
        ACoS: "ACoS",
        sumDirectSale: "Doğrudan Satış Adedi",
        sumIndirectSale: "Dolaylı Satış Adedi",
        sumDirectSaleAmount: "Doğrudan Satış",
        sumIndirectSaleAmount: "Dolaylı Satış",
        sumSaleAmount: "Satış",
        sumSpend: "Reklam Harcaması",
        sale: "Satış Adedi",
        sumVideoStart: "Video Başlangıçları",
        sumVideoComplete: "Tamamlanan Videolar",
        VCR: "VCR",
        viewabilityRate: "Görünürlük Oranı",
        deliveryRate: "Yayınlanma oranı",
        adResponseYieldRate: "Reklam Yanıtı Gelir Oranı",
        fillRate: "Doluluk Oranı",
        sumPostClickSale: "Tıklama Sonrası Satış Adedi",
        sumPostViewSale: "Görüntüleme Sonrası Satış Adedi",
        sumPostClickSaleAmount: "Tıklama Sonrası Satış",
        sumPostViewSaleAmount: "Görüntüleme Sonrası Satış",
        sumPostClickDirectSale: "Tıklama Sonrası Doğrudan Satış Adedi",
        sumPostClickIndirectSale: "Tıklama Sonrası Dolaylı Satış Adedi",
        sumPostViewDirectSale: "Görüntüleme Sonrası Doğrudan Satış Adedi",
        sumPostViewIndirectSale: "Görüntüleme Sonrası Dolaylı Satış Adedi",
        sumPostClickDirectSaleAmount: "Tıklama Sonrası Doğrudan Satış",
        sumPostClickIndirectSaleAmount: "Tıklama Sonrası Dolaylı Satış",
        sumPostViewDirectSaleAmount: "Görüntüleme Sonrası Doğrudan Satış",
        sumPostViewIndirectSaleAmount: "Görüntüleme Sonrası Dolaylı Satış",
        ACoSByClick: "Tıklama Sonrası ACoS",
        ACoSByView: "Görüntüleme Sonrası ACoS",
        RoASByClick: "Tıklama Sonrası RoAS",
        RoASByView: "Görüntüleme Sonrası RoAS",
        CVRByClick: "Tıklama Sonrası Dönüşüm Oranı",
        CVRByView: "Görüntüleme Sonrası Dönüşüm Oranı",
      },
    },
    selectFilter: {
      title: "Filtre Seçenekleri",
      placeholder: "Filtre Seçin",
      budgetOpt: "Kalan Bütçe",
      budgetAdvertiser: "Reklamveren Adı",
      placeholderCondition: "Koşul Seçin",
      bigger: "Büyüktür",
      smaller: "Küçüktür",
      equal: "Eşittir",
      notEqual: "Eşit Değildir",
      any: "Bunlardan Herhangi Biri",
      add: "Filtre Ekle",
      placeholderValue: "Değer Seçin",
      enter: "Değer Girin",
      date: "Tarih Seçiniz",
      reportsText: "Raporunuza uygulamak istediğiniz filtreyi belirleyin.",
    },
    searchBar: {
      keywords: "Bir kelime giriniz...",
      negative_keywords: "Bir kelime giriniz...",
      categories: "Bir kategori giriniz...",
      negative_categories: "Bir kategori giriniz...",
    },
    settingsHeader: {
      general: "Genel Bilgiler",
      generalText: "Pazaryerine ait genel bilgiler",
      integration: "Entegrasyon Bilgileri",
      integrationText:
        "Veri aktarımı gerçekleştirebilmeniz için gerekli entegrasyon bilgileri",
      advertiser: "Reklamveren Ayarları",
      advertiserText: "Reklamverenleriniz ile ilgili ayarlar",
      auction: "Açık Artırma Ayarları",
      auctionText: "Açık artırma ile ilgili ayarlar",
      campaign: "Kampanya Ayarları",
      campaignText: "Kampanya kurulumu ile ilgili ayarlar",
      bid: "Teklif Kısıtlamaları",
      bidText:
        "Platform genelinde uygulanacak teklif kurallarına ilişkin ayarlar",
      conversion: "Dönüşüm",
      conversionText: "Platformdaki dönüşüm hedefleriniz ile ilgili ayarlar",
      category: "Kategori Kısıtlamaları",
      categoryText:
        "Platformunuzda görüntülenen kategoriler ile ilgili kısıtlamalar",
      keyword: "Kelime Kısıtlamaları",
      keywordText:
        "Platformunuzda aranan anahtar kelimeler ile ilgili kısıtlamalar",
    },
    stepsNavbar: {
      report_create: "Raporu Oluştur",
      campaign_create: "Kampanyayı Oluştur",
      report_edit: "Raporu Güncelle",
      step: "Bir sonraki adım {{value}}",
      advertiser: "Reklamveren:",
    },
    suggestionCard: {
      addAll: "Hepsini Ekle",
    },
    table: {
      keywords: {
        keyword: "Anahtar Kelime",
        matchType: "Eşleşme Türü",
        suggested: "Önerilen Teklif",
        dynamic: "Otomatik",
        volume: "Arama Hacmi",
        competition: "Rekabet",
        low: "1000’den az",
        medium: "1B - 5B",
        high: "5B-10B",
        highest: "10B+",
        search: "Bir kelime giriniz...",
        suggestTitle: "Önerilen Anahtar Kelimeler",
        suggestText: "Ürünleriniz için tavsiye edilen anahtar kelimeler.",
        negativePlaceholder: "Negatif anahtar kelime ekle...",
        exact: "Anahtar kelimenizle tam olarak eşleşen arama terimleri.",
        phrase:
          "Anahtar kelimenizin bileşenlerini aynı sırada içeren arama terimleri.",
        broad:
          "Anahtar kelimenizin bileşenlerini herhangi bir sırada içeren arama terimleri.",
        warning: "En az {{value}} olabilir.",
      },
      products: {
        productSearch: "Ürün, sku veya barkod arayın.",
        storeSearch: "Mağaza arayın",
        loading: "Yükleniyor",
        title: "Reklamını yayınlamak istediğiniz ürünleri seçin.",
        text: "Soldaki ürün listesinde yer alan ekle butonuna tıklayarak reklam yapmak istediğiniz ürünlerinizi ekleyin.",
        productSelected: "Seçili Ürünler",
        storeSelected: "Seçili Mağazalar",
        remove: "Temizle",
        price: "Fiyat",
        stock: "Stok",
        rating: "Puan",
        drawerTitle: "Toplu Ürün Seçme",
        sku: "SKU veya Barkod Listesi",
        skuText:
          "Eklemek istediğiniz ürün SKU veya barkodlarını, her satırda bir tane olacak şekilde aşağıdaki alana ekleyin.",
        categories: "Bütün Kategoriler",
        bulk: "Toplu Seç",
        all: "Hepsini Göster",
        selected: "Seçili Ürünleri Göster",
        total: "Toplam <strong>{{value}} adet</strong> ürün var.",
        variable: "Değişken",
      },
      categories: {
        suggestTitle: "Önerilen Kategoriler",
        suggestText: "Ürünleriniz için tavsiye edilen kategoriler",
      },
      result: {
        campaignName: "Kampanya İsmi",
        campaign: "Kampanya ID",
        campaignBidType: "Teklif Türü",
        campaignBudgetType: "Bütçe Türü",
        product: "Ürün",
        advertiserName: "Reklamveren İsmi",
        agencyName: "Ajans İsmi",
        productName: "Ürün",
        categoryText: "Kategori",
        keyword: "Anahtar Kelime ID",
        keywordText: "Anahtar Kelime",
        keywordMatchType: "Eşleşme Türü",
        productBrand: "Marka",
        day: "Gün",
        week: "Hafta",
        month: "Ay",
        year: "Yıl",
        hour: "Saat",
        sumImpression: "Görüntülenme",
        sumViewableImpression: "Aktif Görüntülenme",
        sumClick: "Tıklama",
        CTR: "Tıklama Oranı",
        CVR: "Dönüşüm Oranı",
        sumSale: "Satış Adedi",
        ACoS: "ACoS",
        sumDirectSale: "Doğrudan Satış Adedi",
        sumIndirectSale: "Dolaylı Satış Adedi",
        sumDirectSaleAmount: "Doğrudan Satış",
        sumIndirectSaleAmount: "Dolaylı Satış",
        sumSaleAmount: "Satış",
        sumSpend: "Reklam Harcaması",
        campaignStartDate: "Başlangıç Tarihi",
        campaignEndDate: "Bitiş Tarihi",
        campaignStatus: "Durum",
        campaignBudget: "Bütçe",
        advertiser: "Reklamveren",
        agency: "Ajans",
        weekOf: "{{value}} Haftası",
        customerDeviceType: "Müşteri Cihaz Türü",
        saleProductName: "Dolaylı Satış Ürünü",
        placementId: "Yerleşim ID",
        customerType: "Müşteri Türü",
        categoryName: "Kategori Sayfaları",
        productRetailerTaxonomy: "Ürün Kategorisi",
        adPlacementCustomKey: "Reklam Yerleşim ID",
        adPlacementName: "Reklam Yerleşim İsmi",
        placementText: "Hedeflenen Yerleşim İsmi",
        in_search: "Arama Sonuçları",
        in_category: "Kategori Sayfaları",
        in_product_detail_page: "Ürün Detay Sayfaları",
        in_home_page: "Anasayfa",
        in_collection: "Koleksiyon",
        campaignAdType: "Kampanya Türü",
        campaignFixedBid: "Kampanya Sabit Teklif",
        keywordBid: "Anahtar Kelime Teklifi",
        categoryBid: "Kategori Teklifi",
        advertiserResourceID: "Satıcı ID",
        productSKU: "Ürün ID",
        customerDeviceEnvironmentType: "Müşteri Cihazı Ortam Türü",
        campaignDailyBudget: "Günlük Bütçe",
        campaignTotalBudget: "Toplam Bütçe",
        sumVideoStart: "Video Başlangıçları",
        sumVideoComplete: "Tamamlanan Videolar",
        VCR: "VCR",
        viewabilityRate: "Görünürlük Oranı",
        deliveryRate: "Yayınlanma oranı",
        adResponseYieldRate: "Reklam Yanıtı Gelir Oranı",
        fillRate: "Doluluk Oranı",
        sumPostClickSale: "Tıklama Sonrası Satış Adedi",
        sumPostViewSale: "Görüntüleme Sonrası Satış Adedi",
        sumPostClickSaleAmount: "Tıklama Sonrası Satış",
        sumPostViewSaleAmount: "Görüntüleme Sonrası Satış",
        sumPostClickDirectSale: "Tıklama Sonrası Doğrudan Satış Adedi",
        sumPostClickIndirectSale: "Tıklama Sonrası Dolaylı Satış Adedi",
        sumPostViewDirectSale: "Görüntüleme Sonrası Doğrudan Satış Adedi",
        sumPostViewIndirectSale: "Görüntüleme Sonrası Dolaylı Satış Adedi",
        sumPostClickDirectSaleAmount: "Tıklama Sonrası Doğrudan Satış",
        sumPostClickIndirectSaleAmount: "Tıklama Sonrası Dolaylı Satış",
        sumPostViewDirectSaleAmount: "Görüntüleme Sonrası Doğrudan Satış",
        sumPostViewIndirectSaleAmount: "Görüntüleme Sonrası Dolaylı Satış",
        ACoSByClick: "Tıklama Sonrası ACoS",
        ACoSByView: "Görüntüleme Sonrası ACoS",
        RoASByClick: "Tıklama Sonrası RoAS",
        RoASByView: "Görüntüleme Sonrası RoAS",
        CVRByClick: "Tıklama Sonrası Dönüşüm Oranı",
        CVRByView: "Görüntüleme Sonrası Dönüşüm Oranı",
      },
    },
    states: {
      campaigns_not_started: "Kampanya henüz başlamadı.",
      campaigns_date_passed: "Kampanya süresi bitti.",
      campaigns_status_paused: "Kampanya durduruldu.",
      campaigns_out_of_daily_budget: "Kampanyanın günlük bütçesi tükendi.",
      campaigns_out_of_total_budget:
        "Kampanyanız toplam bütçe sınırına ulaştı.",
      campaigns_products_stopped: "Hiçbir ürün yayınlanmıyor.",
      campaigns_keywords_stopped: "Hiçbir kelime yayınlanmıyor.",
      campaigns_categories_stopped: "Hiçbir kategori yayınlanmıyor.",
      campaigns_status_archived: "Kampanyayı arşivlediniz.",
      campaigns_creatives_stopped: "Aktif kreatif bulunmamaktadır.",
      campaigns_insufficient_balance:
        "Bakiyeniz yetersiz. Lütfen bakiye yükleyin.",
      campaigns_low_bid: "Teklifiniz minimum teklifin altında.",
      campaigns_advertiser_paused: "Reklamveren aktif değil.",
      campaigns_regions_stopped: "Hiçbir bölge yayınlanmıyor.",
      products_status_paused: "Ürün gösterimi durduruldu.",
      products_low_stock: "Ürün stoğu yetersiz.",
      products_low_rating: "Ürün puanı düşük.",
      products_buybox_loser: "Ürün buybox kazananı değil.",
      products_product_paused: "Ürün aktif değil.",
      products_product_deleted: "Ürün aktif değil.",
      keywords_status_paused: "Kelime hedefleme durduruldu.",
      keywords_low_bid: "Teklifiniz minimum teklifin altında.",
      categories_status_paused: "Kategori hedefleme durduruldu.",
      categories_low_bid: "Teklifiniz minimum teklifin altında.",
      creatives_paused: "Kreatifi durdurdunuz.",
      creatives_pending: "Kreatif onay bekliyor.",
      creatives_rejected: "Kreatif reddedildi.",
      creatives_note: "Reddedilme Sebebi:",
    },
    payment: {
      amount: "Yüklenecek Tutar:",
      title: "Ödeme Yöntemi",
      subtitle:
        "Reklam bakiyesi tarafınıza KDV dahil fiyattan faturalandırılacaktır.",
      wire_transfer: "Havale / EFT",
      beymen_milestone_payment: "Bakiyeden Aktar",
      koctas_milestone_payment: "Bakiyeden Aktar",
      modanisa_milestone_payment: "Bakiyeden Aktar",
      bank: "Banka Bilgileri",
      helpUpper:
        "Havale veya EFT ile ödemeleriniz onaylandıktan sonra hesabınıza aktarılacaktır. Onay süreci 2-3 iş günü",
      helpSecondary: "sürebilmektedir.",
      ref: "Referans No:",
      info: "İşlemin açıklama kısmına referans numaranızı doğru girmeniz işlemin takibi açısından çok önemlidir.",
      tax: "KDV:",
      verifyAmount: "Hesabınızdan Çekilecek Tutar:",
      toters_milestone_payment: "Cüzdandan Aktar",
      product_placeholder: "Mağaza Ara",
      craftgate: "Kredi Kartı",
    },
    tour: {
      prev: "Geri",
      next: "Devam Et",
      done: "Eğitimi Sonlandır",
      title: "Reklam Platformuna Hoşgeldiniz!",
      intro:
        "Platformu nasıl kullanacağınızı öğrenmek için tura devam edebilirsiniz. Dilerseniz kapat butonuna tıklayarak eğitimden çıkabilirsiniz.",
      menu: "Menü",
      menuText: "Bu alanı kullanarak sayfalar arasında geçiş yapabilirsiniz.",
      campaigns: "Kampanyalar",
      campaignsText:
        "Kampanyalar sayfasından reklam kampanyaları oluşturabilir ve oluşturduğunuz kampanyaları yönetebilirsiniz. Reklamlardan elde ettiğiniz ürün tıklamaları ve satış performanslarını buradan görüntüleyebilirsiniz.",
      reports: "Raporlar",
      reportsText:
        "Reklamlarınızın performansları ile ilgili farklı kırılımlarda raporlar oluşturabilirsiniz. Bu raporlar ile reklamlarınızın hangi arama sonuçlarında veya kategorilerde ne kadar gösterildiğini ya da ne kadar satış yapıldığını görüntüleyebilirsiniz.",
      reportsTextSub:
        "Rapor oluştururken hazır raporları tercih edebilir veya kendi oluşturacağınız özelleştirilmiş raporları seçebilirsiniz.",
      invoices: "Faturalar",
      invoicesText:
        "Bu ekranda reklamlarda harcamak üzere yüklediğiniz bakiyeleri, kazandığınız hediye kuponlarını ve yaptığınız reklam harcamalarını görüntüleyebilirsiniz.",
      academy: "Reklam Rehberi",
      academyText:
        "Bu sayfadan platformun kullanımı ile ilgili gerekli bilgilere ulaşabilirsiniz.",
      widgets: "İstatistikler",
      widgetsText:
        "Oluşturduğunuz kampanyaların zaman içerisindeki performanslarını görüntüleyebilirsiniz. Grafikte göster butonuna tıklayarak günlük olarak performansınızın detaylarını inceleyebilirsiniz.",
      table: "Kampanyalar Tablosu",
      tableText:
        "Bu alanda oluşturduğunuz kampanyaları ve elde ettiğiniz performansları görüntüleyebilirsiniz. Dilerseniz kampanyalarınızı durdurabilirsiniz. Kampanyanızla ilgili detayları görüntülemek için kampanya ismine tıklayabilirsiniz.",
      button: "Kampanya Ekle",
      buttonText:
        "Yeni kampanya ekle butonuna tıklayarak ilk kampanyanızı oluşturabilirsiniz.",
      modal: "Kampanya Tipi",
      modalText:
        "Bu alanda oluşturabileceğiniz kampanya türlerini görüntüleyebilirsiniz.",
      type: "Sponsorlu Ürün",
      typeText:
        "Ürünlerinizi arama sonuçlarında veya kategori sayfalarında öne çıkarabileceğiniz bir Sponsorlu Ürün kampanyası oluşturabilirsiniz.",
    },
  },
  pages: {
    acc: {
      campaigns: {
        button: "Yeni Kampanya Ekle",
        placeholder: "Kampanyalarda Ara",
        errorTitle: "Reklamveren Seçilmedi",
        errorText: "Lütfen bir reklamveren seçiniz.",
        table: {
          standart: "Otomatik",
          max_acos: "Otomatik",
          fixed: "Sabit",
          daily: "Günlük",
          flexible: "Günlük",
          total: "Toplam",
          budgetLimit: "{{value}} Sınırlı",
          stopped: "Kampanyayı durdurdunuz.",
          product: "Sponsorlu Ürün",
          display: "Görsel Reklam",
          store: "Sponsorlu Mağaza",
          default: "Varsayılan Görünüm",
          checkUp: "Hata Yakalama Görünümü",
          video: "Video Reklam",
          display_with_product: "Ürünle Görsel Reklam",
          video_with_product: "Ürünle Video Reklam",
        },
      },
      campaignDetails: {
        subtext: "Kampanya bilgilerini düzenleyebilirsiniz.",
        products: "Ürünler",
        keywords: "Anahtar Kelimeler",
        negativeKeywords: "Negatif Kelimeler",
        targeting: "Kitleler",
        city: "Şehir",
        age: "Yaş",
        gender: "Cinsiyet",
        platform: "Platform",
        customers: "Müşteriler",
        placements: "Reklam Yerleşimleri",
        in_search: "Arama Sonuçları",
        in_category: "Kategoriler",
        placementPlaceholder: "Reklam Yerleşiminde Ara",
        date: "Başlangıç-Bitiş Tarihi:",
        bid: "Teklif:",
        maxAcos: "% Maksimum ACoS",
        fixed: "Sabit",
        standart: "Otomatik",
        daily: "Günlük",
        total: "Toplam",
        flexible: "Günlük",
        none: "Yok",
        keywordsBtn: "Anahtar Kelime Ekle",
        keywordsPlaceholder: "Kelimelerde Ara",
        keywordsSub:
          "Reklamınızın gösterilmesini istediğiniz anahtar kelimeleri ekleyiniz.",
        negative_keywords_btn: "Negatif Kelime Ekle",
        negative_keywords_placeholder: "Negatif Kelimelerde Ara",
        negative_categories_btn: "Negatif Kategori Ekle",
        negative_keywords_modal_subtitle:
          "Reklamınızın gösterilmesini istemediğiniz kelimeleri ekleyiniz.",
        negative_categories_placeholder: "Negatif Kategorilerde Ara",
        negative_categories_modal_subtitle:
          "Reklamınızın gösterilmesini istemediğiniz kategorileri ekleyiniz.",
        productsBtn: "Reklam için Ürün Ekle",
        productsPlaceholder: "Ürünlerde Ara",
        productsSub: "Reklam yapmak istediğiniz ürünleri seçiniz.",
        cities_placeholder: "Şehirlerde Ara",
        age_ranges_placeholder: "Yaşlarda Ara",
        genders_placeholder: "Cinsiyetlerde Ara",
        platforms_placeholder: "Platformlarda Ara",
        customers_placeholder: "Müşterilerde Ara",
        new: "Yeni Müşteriler",
        present: "Mevcut Müşteriler",
        keywordTitle: "Hedefleme",
        keywordText:
          "Reklamınız için anahtar kelimeler otomatik olarak hedefleniyor. Hedeflemek istemediğiniz anahtar kelimeleri yayından kaldırabilirsiniz.",
        maxAcosLabel: "Maks. ACoS",
        fixedBidLabel: "Varsayılan Teklif",
        productsError: "En az 1 ürünün yayınlanması gerekir.",
        keywordsError: "En az 1 anahtar kelimenin yayınlanması gerekir.",
        categoriesError: "En az 1 kategorinin yayınlanması gerekir.",
        categories: "Kategoriler",
        categoriesBtn: "Kategori Ekle",
        categoriesPlaceholder: "Kategorilerde Ara",
        categoriesSub: "Hedefleyeceğiniz kategorileri ekleyebilirsiniz.",
        negativeCategories: "Negatif Kategoriler",
        buyboxError: "Ürün buybox kazananı değil.",
        ratingError: "Ürün puanı minimum puanın altında.",
        stockError: "Ürün stoğu minimum stoğun altında.",
        notActiveError: "Ürün aktif değil.",
        creatives: "Kreatifler",
        creativesBtn: "Kreatif Ekle",
        creativesPlaceholder: "Kreatiflerde Ara",
        creativesSub: "Reklamınızda göstermek istediğiniz kreatifleri ekleyin.",
        creativesLinkTitle: "Yönlendirme Bağlantısı",
        creativesLinkText:
          "Reklamınıza tıklayan kullanıcılar {{value}} adresine yönleniyor.",
        winner: "Kazanan",
        loser: "Kaybeden",
        creativesError: "En az 1 kreatifin yayınlanması gerekir.",
        pendingError: "Kreatif onaylanmadan yayınlanamaz.",
        rejectedError: "Reddedilen kreatif yayınlanamaz.",
        in_product_detail_page: "Ürün Detay Sayfaları",
        in_home_page: "Anasayfa",
        in_collection: "Koleksiyon",
        allTimes: "Tüm Zamanlar",
        scheduled: "Özelleştirilmiş",
        dayParting:
          "Kampanyanızın aktif kalacağı gün ve saat aralıklarını seçebilirsiniz.",
        video: "Videolar",
        vastTag: "VAST Reklam Etiketi",
        videoFile: "Video Dosyası",
        region: "Bölge:",
        regionLength: "{{value}} Bölge",
        regionsDrawerTitle: "Bölgeleri Düzenle",
        languages: "{{value}} Dil",
        segments: "Segmentler",
        segments_placeholder: "Segment Ara",
      },
      noMatch: {
        title: "Aradığınız sayfa bulunamadı.",
        btn: "Anasayfaya Dön",
      },
      reports: {
        button: "Yeni Rapor Ekle",
        placeholder: "Raporlarda Ara",
        name: "Rapor İsmi",
        table: {
          type: "Rapor Türü",
        },
        form: {
          title: "Raporum 1",
          goBack: "Geri Git",
          create: "Rapor Oluştur",
        },
      },
      reportDetails: {
        result: "Rapor Sonucu",
        static: "Sabit",
        scheduled: "Zamanlanmış",
        ready: "Hazır Rapor",
        startDate: "Başlangıç Tarihi",
        endDate: "Bitiş Tarihi",
        frequency: "Gönderim Türü",
        firstDate: "İlk Gönderim Tarihi",
        month: "Ayın İlk Günü",
        emails: "E-postalar",
        export: "Dosya Formatı",
        predefined: "Hazır Rapor Türü",
        total: "Toplam Değerleri Göster",
      },
      invoice: {
        balance: "Bakiye",
        coupon: "Kupon",
        lastPayment: "Son Yüklenen Bakiye",
        searchPlaceholder: "Faturalarda Ara",
        duplicateCouponsError:
          "Çoklu kupon yüklemesi algılandı. Lütfen bekleyin.",
        multipleTopUpsError:
          "Çoklu bakiye yüklemesi algılandı. Lütfen bekleyin.",
        limitError:
          "Yüksek tutarda bir yükleme yapmaya çalışıyorsunuz. Lütfen tutarı kontrol ediniz.",
        minAmountError: "Minimum yükleme tutarı {{value}} olmalıdır.",
        data: {
          typeBudget: "Bakiye Yüklemesi",
          typeAd: "Reklam Harcaması",
          typeBudgetExplanation: "Reklam bakiyesi yüklendi.",
          typeAdExplanation: "Reklam harcaması yapıldı.",
        },
        table: {
          type: "İşlem Türü",
        },
        modal: {
          button: "Bakiye Yükle",
          subtitle: "Reklam bakiyenize aktarmak istediğiniz tutarı giriniz. ",
          loadingAmount: "Yükleme Tutarı",
          differentAmount: "Bir tutar giriniz.",
          label: "Özel Bütçe",
        },
      },
      academy: {
        more: "Daha Fazla Bilgi Al",
        copy: "Bağlantıyı Kopyala",
        helpTitle: "Daha fazla yardıma mı ihtiyacınız var? ",
        helpText:
          "Sorularınız için destek ekibine mail yoluyla ulaşabilirsiniz.",
        contact: "İletişime Geç",
        highlights: "Öne Çıkan Yazı",
        read: "Makalenin Devamını Oku",
        description:
          "{{value}} Reklam Rehberi'nin yardımcı makaleleri ile platform hakkında bilmeniz gereken her şeyi öğrenebilir ve rahatlıkla reklamlarınızı yayınlamaya başlayabilirsiniz.",
      },
    },
    admin: {
      advertisers: {
        updateSubtext:
          "Reklamverenleri ajanslara atayarak gruplandırabilirsiniz.",
        modalPlaceholder: "Ajans arayın...",
        search: "Reklamverenlerde Ara",
        select_advertiser_subtitle:
          "Lütfen adına reklam vermek istediğiniz reklamvereni seçin.",
        userSubtext:
          "Reklamveren hesabını kullanacak kullanıcıları davet edebilirsiniz.",
        help: "Kullanıcının e-posta adresini ekleyin.",
        deleteSuccess: "Ajans başarıyla kaldırıldı.",
        create: "Reklamveren Ekle",
        update: "Reklamveren Düzenle",
        type: "Reklamveren Tipi:",
        custom: "Özelleştirilmiş",
        customText:
          "Platform içerisinde paylaşılabilir olarak ayarlanmış satıcıların ürünleri ile oluşturulan reklamveren tipidir.",
        name: "Reklamveren Adı",
        nameHelp: "Reklamvereninizin sizin tarafınızdan görüntülenecek adıdır.",
        agency: "Ajans",
        agencyHelp: "Reklamverenin bağlı olduğu ajanstır.",
        email: "E-Mail Adresi",
        emailHelp:
          "Davetiyenin iletileceği mail adresini giriniz. Satıcı platforma giriş yaparken girdiğiniz mail adresini kullanır.",
        product: "Ürün İsmi",
        brand: "Marka",
        retailer_taxonomy: "Ürün Kategorisi",
        advertiser_id: "Reklamveren ID",
        filters: "Filtreler:",
        filterWarning: "Lütfen en az bir tane filtre ekleyiniz.",
        typeTitle: "Reklamveren Tipi",
        typeSubtext:
          "Yukaridaki seçili reklamverenin tipini aşağıdakilerden hangisine çevirmek istiyorsun?",
        default: "Varsayılan",
        defaultText:
          "Platform içerisinde kendi adı ile doğrudan satış yapan satıcı hesapları için kullanılır.",
        shareable: "Paylaşılabilir",
        shareableText:
          "Platform içerisinde başkaları adına satış yapan ve asıl satıcılara dağıtılması gereken satıcı hesapları için kullanılır.",
        generalTitle: "Genel Bilgiler",
        generalSubtext: "Reklamveren ile ilgili genel bilgiler",
        successText:
          "Seçtiğiniz satıcının marka ile eşleşen tüm ürünleri eklendi.",
        advertiser: "Reklamveren:",
        drawerFilterWarning: "Eklediğiniz filtreler geri alınamaz!",
        filterTitle: "Filtrele",
        filterText:
          "Reklamverenlere uygulamak istediğiniz filtreyi belirleyin.",
        keyLabel: "Filtre Seçin",
        opLabel: "Koşul Seçin",
        valueLabel: "Değer Girin",
        categorySelectLabel: "Kategori Seçin",
        filterKeyError: "Filtre seçmeniz gerekmektedir.",
        filterOpError: "Koşul seçmeniz gerekmektedir.",
        filterValueError: "Değer girmeniz gerekmektedir.",
        filterCategoryError: "Kategori seçmeniz gerekmektedir.",
      },
      advertiserSettings: {
        title: "Reklamveren Ayarları",
        subtext: "Reklamverenleriniz ile ilgili ayarlar",
        status: "Varsayılan Reklamveren Durumu",
        statusText:
          "Platforma eklenen reklamverenlerin varsayılan durumu kapalı ise sisteme giriş yapabilmeleri için durumu reklamverenler sayfası üzerinden “aktif” hale getirmeniz gerekir.",
        welcome: "Hoşgeldin Kuponu",
        welcomeText:
          "Reklamverenin platforma ilk geldiğinde kullanabileceği bir hoşgeldin kuponu tanımlayabilirsiniz.",
        warning:
          "Eğer “0” girerseniz reklamverenlere hoşgeldin kuponu tanımlanmaz.",
        label: "Kupon Miktarı",
        language: "Dil",
        languageText:
          "Reklamverenler için varsayılan arayüz dilidir. Reklamverenler dil seçimini kendi arayüzleri üzerinden değiştirilebilirler.",
        balance: "Bakiye Yükleme Yetkisi",
        balanceText:
          "Reklamverenlerin hakedişlerinden reklam bakiyelerine arayüz üzerinden aktarım yapabilme yetkisidir.",
        open: "Açık",
        closed: "Kapalı",
        error_title: "Bir değer girmeniz gerekmektedir.",
        error_text:
          "Kupon tanımlamak istemiyorsanız “0” girerek kaydet butonuna tıklayabilirsiniz.",
        error_text_input: "Bir değer girmeniz gerekmektedir.",
      },
      agencies: {
        label: "Ajans İsmi",
        subtext: "Ajans ismi giriniz",
        subtitle: "Reklamverenleri gruplamak için ajans ekleyin",
        search: "Ajanslarda Ara",
        edit: "Ajans ismini düzenleyin",
      },
      auctionSettings: {
        title: "Açık Artırma Ayarları",
        subtext: "Açık artırma ile ilgili ayarlar",
        type: "Açık Artırma Tipi",
        typeText:
          "Reklamverenlerin tekliflerinin hangi açık artırma tipine göre değerlendirileceğini seçin. Açık artırma tipleri için detaylı bilgiye buradan ulaşabilirsiniz.",
        warning:
          "Yapılan değişiklikler platformda yapılan reklam harcamalarını etkileyecektir.",
        firstOpt: "İlk Fiyat",
        firstOptText:
          "Kazanan reklamverenler verilen teklif kadar ödeme yaparlar.",
        secondOpt: "İkinci Fiyat",
        secondOptText:
          "Kazanan reklamverenler ikinci en yüksek tekliften 1 kuruş fazla ödeme yaparlar.",
        link: "Açık Artırma Tipleri",
      },
      campaignSettings: {
        title: "Kampanya Ayarları",
        subtext: "Kampanya kurulumu ile ilgili ayarlar",
        conversionRange: "Dönüşüm Aralığı",
        conversionRangeText:
          "Kullanıcının satın alım faaliyetinin reklam tıklamasıyla ilişkilendirileceği zaman aralığını belirleyebilirsiniz. Dönüşüm aralığını 7 gün olarak belirlediğinizde kullanıcı reklamı tıklar ve 7 gün içerisinde ürünü satın alırsa bu satış kampanya ile ilişkilendirilir..",
        product: "Ürün Puanı",
        productText:
          "Reklamverenlerin kampanyalarına ekleyecekleri ürünler için minimum puan koşulu ekleyebilir, bu koşulu sağlamayan ürünler için davranışları özelleştirebilirsiniz.",
        minimum: "Minimum Ürün Puanı",
        minimumText:
          "Kampanyalara eklenecek ürünler için minimum ürün puanı giriniz.",
        behaviour: "Davranış Belirle",
        behaviourText:
          "Kampanyalara eklenecek ürünler için minimum ürün puanı giriniz.",
        placeholder: "Davranış Seçiniz",
        stock: "Ürün Stoğu",
        stockText:
          "Reklamverenlerin kampanyalarına ekleyecekleri ürünler için minimum stok koşulu ekleyebilir, bu koşulu sağlamayan ürünler için davranışları özelleştirebilirsiniz.",
        minimumStock: "Minimum Ürün Stoğu",
        minimumStockText:
          "Kampanyalara eklenecek ürünler için minimum stok sayısı giriniz.",
        stockBehaviourText:
          "Stok sayısı belirlediğiniz minimum stoğun altına düştüğünde gerçekleşecek davranışı belirleyiniz.",
        alert: "Sadece Uyar",
        block: "Uyar ve Reklam Girilmesini Engelle",
        targeting: "Demografik Hedefleme",
        targetingText:
          "Kampanya kurulumunda, demografik hedefleme adımının açık olup olmayacağını seçin. Etkinleştirildiğinde, kampanya kurulumuna bir adım daha eklenir ve reklamverenler hedeflemelerini yapabilirler.",
        all: "Tüm Reklamverenler",
        none: "Hiçbir Reklamveren",
        selected: "Seçili Reklamverenler",
        format: "Reklam Formatları",
        sponsored: "Sponsorlu Ürün",
        brand: "Sponsorlu Marka",
        ad: "Görsel Reklam",
        rating_warn:
          "Ürün puanınız çok düşük! Bu ürünü kampanyanıza eklemek performansı düşürür.",
        rating_block:
          "Ürün puanınız çok düşük! Bu ürünü kampanyanıza ekleyemezsiniz!",
        stock_warn:
          "Ürün stoğunuz çok düşük! Bu ürünü kampanyanıza eklemek performansı düşürür.",
        stock_block:
          "Ürün stoğunuz çok düşük! Bu ürünü kampanyanıza ekleyemezsiniz!",
        buybox_warn:
          "Ürün buy box kazananı değil. Bu ürünü kampanyanıza eklemek performansı düşürür.",
        buybox_block:
          "Ürün buy box kazananı değil. Bu ürünü kampanyanıza ekleyemezsiniz!",
        buyBoxTitle: "Buy Box Koşulu",
        buyBoxText:
          "Reklamverenlerin kampanyalarına ekleyecekleri ürünler için Buy Box kazananı olma koşulu ekleyebilirsiniz.",
        buyBoxSubtext:
          "Kampanyalara eklenecek ürünler buy box kazananı olmadığında gerçekleşecek davranışı belirleyiniz.",
        noWorning: "Uyarma",
        warn: "Yapılan değişiklik yayınlanan bütün kampanyaları etkileyecektir.",
        rangeError:
          "Bir değer girmeniz gerekmektedir. Tavsiye edilen değer 7 gündür.",
        rangeErrorInput: "1-14 gün aralığında bir değer girilmelidir.",
        chooseBehaviour: "Bir davranış seçmelisiniz.",
        stockError: "Minimum stok girmelisiniz.",
        allStockError: "Bir minimum stok girmeli ve davranış seçmelisiniz.",
        ratingError: "Minimum puan girmelisiniz.",
        allRatingError: "Bir minimum puan girmeli ve davranış seçmelisiniz.",
        serverBlock: "Uyar ve Reklam Gösterilmesini Engelle",
        advertisers: "Seçili Reklamverenler",
        advertisersText:
          "Demografik hedefleme yalnızca seçtiğiniz reklamverenler tarafından kullanılabilecektir.",
        advertisersBtn: "Reklamveren Ekle",
        advertisersSub:
          "Demografik hedeflemeyi kullanabilecek reklamverenleri seçiniz.",
        advertisersPlaceholder: "Reklamveren Seçiniz",
        advertisersLength: "{{value}} reklamveren seçildi.",
        advertisersTitle: "Reklamverenler",
        general: "Genel",
        bid: "Teklif Kısıtlamaları",
        conversion: "Dönüşüm",
        conversionSubtext:
          "Platformdaki dönüşüm hedefleriniz ile ilgili ayarlar",
        biddingTypes: "Sponsorlu Ürün Teklif Tipi Ayarlamaları",
        biddingTypesText:
          "Kampanya oluşturma adımında reklamverenlerin kullanabileceği teklif tiplerini belirleyebilirsiniz.",
        biddingTypesWarning: "En az bir seçenek açık kalmalıdır.",
        auto: "Otomatik Teklif",
        autoText:
          "Tekliflerin otomatik olarak belirlendiği teklif türüdür. Harcamalar satılan ürün fiyatı ve belirlediğiniz hedef ACoS kullanılarak hesaplanır.",
        acos: "Hedef ACoS",
        acosText:
          "Teklifler reklamverenlerin girdiği ACoS oranı ve satılan ürünlerin fiyatı üzerinden otomatik olarak hesaplanır.",
        fixed: "Sabit Teklif",
        fixedText:
          "Teklifler reklamveren tarafından belirlenir. Reklamverenler kampanya genelinde geçerli bir teklif belirleyebileceği gibi kelime ve kategori başına teklif de verebilir.",
        bidWarning: "En az bir teklif türü seçilmedilir.",
        bidText:
          "Platform genelinde uygulanacak teklif kurallarına ilişkin ayarlar",
        bidLabelTitle: "Genel Minimum CPC",
        bidLabelText:
          "Tüm kategori ve anahtar kelimelerde girilmesi gereken tıklama başına minimum teklif tutarıdır.",
        bidLabel: "Minimum CPC",
        cpmTitle: "Genel Minimum CPM",
        cpmText:
          "Tüm kategori ve anahtar kelimelerde girilmesi gereken gösterim başına minimum teklif tutarıdır.",
        cpmLabel: "Minimum CPM",
        targetAcosTitle: "Genel Hedef ACoS",
        targetAcosText:
          "Açık artırmalarda varsayılan olarak hedeflenecek maksimum ACoS yüzdesini belirleyebilirsiniz.",
        targetAcosLabel: "Maks. Acos Oranı",
        categoryRelevanceTitle: "Genel Kategori Alaka Seviyesi",
        categoryRelevanceText:
          "Reklam gösterimlerinde sponsorlu ürünler ile organik listelenen ürünler arasında olması gereken alaka seviyesini belirler.",
        categoryRelevanceLabel: "Kategori Alaka Seviyesi",
        bidTabWarning:
          "Yapılan değişiklikler platformda yapılan reklam harcamalarını ve gösterimleri etkileyecektir.",
        inputErrorZero: "Sıfırdan büyük bir değer girmeniz gerekmektedir.",
        inputErrorAcos: "0-100 arası bir sayı girmelisiniz.",
        inputErrorRelevance: "Sıfırdan büyük bir tam sayı girmelisiniz.",
        inputError: "Bir değer girmelisiniz.",
        productLink: "Ürün Puanı Kısıtlaması",
        stockLink: "Ürün Stoğu Kısıtlaması",
        targetingLink: "Demografik Hedefleme Özelliği",
        bidLink: "Teklif Tipleri",
        minThreshold: "Minimum Satış Eşiği",
        minThresholdText:
          "Ürünlerin açık artırmaya dahil edilebilmesi için gereken minimum satış eşiğidir.",
        minThresholdLabel: "Min. Satış Eşiği",
        minThresholdError: "Lütfen bir tam sayı girin.",
        formatTitle: "Kampanya Tipine Göre Dönüşüm Ayarları",
        formatText:
          "Temas noktaları, farklı kampanya formatlarındaki etkinliklerini belirlemek amacıyla ölçülür. Formata bağlı olarak, gösterim seçimi görünürlüğü takip ederken, tıklamalar doğrudan etkileşim yoluyla dönüşümlerin ölçülmesine odaklanır.",
        sponsoredProductTitle: "Sponsorlu Ürün",
        sponsoredProductText:
          "Ürün reklamlarında dönüşümler, kullanıcının reklamla etkileşimi üzerinden değerlendirilir; burada etkileşim ya da maruz kalmanın istenen sonuca ulaşmada ne kadar etkili olduğu ön plana çıkar.",
        sponsoredDisplayTitle: "Görsel Reklam",
        sponsoredDisplayText:
          "Görsel reklamlar, yalnızca görünürlüğün kullanıcı eylemlerini tetikleyip tetiklemediğini veya daha derin bir etkileşime ihtiyaç olup olmadığını analiz ederek dönüşümleri ölçer.",
        sponsoredVideoTitle: "Video Reklam",
        sponsoredVideoText:
          "Video reklamlarında, dönüşüm ölçümü, yalnızca içeriğin izlenmesinin mi yoksa aktif etkileşimin mi hedefe ulaşmada daha etkili olduğunu anlamaya odaklanır.",
        postView: "Görüntüleme Sonrası",
        postClick: "Tıklama Sonrası",
        reportTitle: "Raporlama Bazında Dönüşüm Ayarları",
        reportText:
          "Bu seçenek, raporlama amaçları için dönüşümün nasıl uygulanacağını tanımlar ve verilerin seçilen yaklaşıma göre nasıl düzenlenip sunulacağını şekillendirir.",
        marketplaceReport: "Pazar Yeri Raporlamada Metrikleri Ayırma",
        marketplaceReportText:
          "Bu seçeneği etkinleştirmek, pazar yeri raporundaki metrikleri dönüşüme göre böler ve her bir temas noktasının performansı hakkında daha net içgörüler sağlar.",
        advertiserReport: "Reklamveren Raporlamada Metrikleri Ayırma",
        advertiserReportText:
          "Bu seçeneği açmak, reklamveren raporundaki metrikleri dönüşüme göre ayırır ve farklı kampanya etkileşimlerinin etkisini daha iyi anlamanıza yardımcı olur.",
        widgetTitle: "Widget İçin Dönüşüm Ayarları",
        widgetText:
          "Widget dönüşümü, kullanıcı etkileşimlerini dönüşüm yolculuğu boyunca değerlendirir. Gösterimleri izlemeyi seçmek, reklam görünürlüğünü yakalarken, tıklamaları izlemek, katılımı bir dönüşüm göstergesi olarak ölçer.",
      },
      console: {
        add: "Yeni Veri Kaynağı Ekle",
        advertiser: "Reklamveren",
        product: "Ürün",
        google_feed_url_scheduler: "Google Feed URL",
        google_feed_file: "Google Feed Dosyası Yükleme (XML)",
        google_feed_body: "Google Feed Doğrudan XML",
        meta_feed_url_scheduler: "Meta Feed URL",
        minutes_15: "15 Dakika",
        minutes_30: "30 Dakika",
        hour_1: "1 Saat",
        hours_2: "2 Saat",
        hours_3: "3 Saat",
        hours_4: "4 Saat",
        hours_6: "6 Saat",
        hours_12: "12 Saat",
        hours_24: "24 Saat",
        history: "Veri Kaynağı Geçmişi",
        logs: "Logları Göster",
        drawer: "Log Görüntüleme",
        error: "Hata",
        warning: "Uyarı",
        success: "Başarılı",
        info: "Bilgi",
        prev: "Önceki 50 Log",
        next: "Sonraki 50 Log",
        api: "API",
        impression: "Görüntülenme",
        click: "Tıklama",
        sale: "Satış",
        test: "Test",
        curlTest: "Test Etme",
        curlWarning:
          "Önemli: Bu işlemi üretim ortamında çalıştırmaktan kaçının!",
        request: "İstek",
        run: "Çalıştır",
        response: "Yanıt",
        hide: "Ham Veriyi Gizle",
        show: "Ham Veriyi Göster",
        auctionLogs: "Açık Artırma Logları",
      },
      coupons: {
        add: "Kupon Tanımla",
        title: "Reklamveren Bilgileri",
        subtext:
          "Reklamverenlerin kampanya harcamalarında kullanabilmeleri için kupon tanımlayabilirsiniz.",
        label: "Son Kullanma Tarihi",
        warning:
          "Reklamverenlerin kuponu girilen tarihe kadar kullanması gerekir. Eğer son kullanma tarihi girmezseniz kupon reklamverenlerin bakiyesine kalıcı olarak eklenir ve reklamverenler kuponu diledikleri zaman kullanırlar.",
        gift_coupon: "Hediye Kupon",
        welcome_coupon: "Hoşgeldin Kuponu",
        gift_coupon_desc: "Hediye kupon tanımlandı.",
        welcome_coupon_desc: "Hoşgeldin kupon tanımlandı.",
        approve: "İşleme devam etmek ister misiniz?",
        note: "Kupon Açıklaması",
        noteHelp:
          "Kuponun tanımlanma nedenini ekleyin. Örn. Black Friday kuponu",
      },
      createDatasource: {
        title: "Veri Kaynağı Oluştur",
        save: "Kaydet ve Çalıştır",
        existsErr:
          "Bu URL ile bir veri kaynağı zaten mevcut. Lütfen farklı bir URL kullanın.",
        feedErr:
          "Bu tür entegrasyon için feed URL'si boş olamaz. Lütfen geçerli bir URL girin.",
        cycleErr:
          "Bu tür entegrasyon için döngü boş olamaz. Lütfen geçerli bir aralık seçin.",
        permissionErr:
          "Sağlanan URL'ye erişim sağlanamıyor. Lütfen URL'yi veya izinleri kontrol edin.",
        generalErr: "Bir hata meydana geldi.",
        validateSuccess: "Doğrulama Başarılı",
        validateSuccessText:
          "Gerekli tüm alanlar doğru şekilde eşlendi. Devam etmeye hazır.",
      },
      creatives: {
        all: "Tümü",
        accepteds: "Onaylananlar",
        rejecteds: "Reddedilenler",
        pendings: "Bekleyenler",
        pending: "Onay Bekliyor",
        accepted: "Yayınlanıyor",
        rejected: "Reddedildi",
        adModalTitle: "Reklam  Önizleme",
        creativeModalTitle: "Kreatif Önizleme",
        creative: "Kreatif",
        name: "Kreatif İsmi",
        search: "Kreatiflerde Ara",
        videoModalTitle: "Video Önizleme",
        rejectSubtitle:
          "Kreatifin neden reddedildiğini girebilir veya boş bırakıp devam edebilirsiniz.",
        note: "Kreatif reddedilme sebebi giriniz.",
      },
      datasourceDetails: {
        entity: "Veri Kaynağı:",
        import: "İçe Aktarma Yöntemi:",
        cycle: "Döngü:",
        url: "Feed URL:",
        success: "Başarılı",
        failure: "Başarısız",
        in_progress: "Devam Ediyor",
        partial_success: "Kısmi Başarı",
      },
      generalInfo: {
        title: "Genel Bilgiler",
        subtext: "Pazaryerine ait genel bilgiler",
        market: "Pazaryeri Adı",
        marketText:
          "Mağazanın arayüzde görünen adıdır. Bu bilgiyi değiştirmek için hesap yöneticiniz ile iletişim kurmanız gerekir.",
        url: "URL",
        urlText:
          "Mağazanın arayüzde ve yönlendirme için kullanılacak linktir. Bu bilgiyi değiştirmek için hesap yöneticiniz ile iletişim kurmanız gerekir.",
        country: "Ülke",
        countryText:
          "Şehir hedeflemesinde kullanılacak ülkedir. Bu bilgiyi değiştirmek icin hesap yöneticiniz ile iletişim kurmanız gerekir.",
        zone: "Saat Dilimi",
        zoneText:
          "Arayüzlerde kullanılacak varsayılan saat dilimidir. Bu bilgiyi değiştirmek icin hesap yöneticiniz ile iletişim kurmanız gerekir.",
        currency: "Para Birimi",
        currencyText:
          "Arayüzlerde kullanılacak para birimidir. Bu bilgi değiştirilemez.",
        zoneIstanbul: "Avrupa/İstanbul",
        email: "Destek E-Posta Adresi",
        emailText: "Satıcılarınızın iletişime geçeceği mail adresidir.",
        emailLabel: "E-Posta Adresi",
        emailError: "E-posta adresi girmeniz gerekmektedir.",
        seller: "Satıcı Paneli Adresi",
        sellerText:
          "Satıcılarınızın arayüz üzerinden doğrudan panelinize yönlenmesini sağlayacak adrestir.",
        sellerLabel: "Panel Adresi",
        marketError: "Pazaryeri adı girmeniz gerekmektedir.",
        urlError: "URL girmeniz gerekmektedir.",
      },
      inspect: {
        campaign: "Kampanya:",
        advertiser: "Reklamveren:",
        url: "URL:",
        size: "Boyut:",
        status: "Durum:",
        keywords: "Anahtar Kelimeler",
        categories: "Kategoriler",
        vastTag: "VAST Reklam Etiketi URL:",
        languages: "Dil:",
        regions: "Bölgeler:",
      },
      integrationInfo: {
        title: "Entegrasyon Bilgileri",
        subtext:
          "Veri aktarımı gerçekleştirebilmeniz için gerekli entegrasyon bilgileri",
        api: "API Güvenlik Kodu",
        apiText: "Platforma API ile erişmek için gerekli güvenlik kodudur.",
        ftp: "FTP Güvenlik Sertifikası",
        ftpText: "FTP sunucusuna erişim sağlamak için gerekli sertifikadır.",
        download: "İndir",
        copy: "Kopyalamak için tıklayınız.",
        copied: "Kopyalandı",
        auto: "Otomatik Gösterim",
        autoText:
          "Herhangi bir event entegrasyonu yapılmadan tüm gösterimlerin otomatik olarak kaydedilmesini sağlayan bir ayardır. Ayar açık olduğunda reklam cevabında dönen tüm ürünlerin sitenizde gösterildiğini kabul edilerek her bir ürün için birer görüntülenme metriği kaydedilir. Ayar kapalı olduğunda ise istenilen formata uygun şekilde her bir görüntülenme için bir event isteği gönderilmelidir.",
        link: "Gösterim Eventi",
        apiLink: "API Endpointleri",
      },
      transactions: {
        subtext:
          "Reklamverenlerin kampanya harcamalarında kullanabilmeleri için bakiye tanımlayabilirsiniz.",
        warning: "Dilerseniz fatura numarasını ekleyebilirsiniz.",
        balance_updated: "Bakiye Yüklemesi",
        gift_coupon: "Hediye Kuponu",
        welcome_coupon: "Hoşgeldin Kuponu",
        refund: "İade",
        spend: "Reklam Harcaması",
        ad_balance_loaded_desc: "Reklam bakiyesi yüklendi.",
        spend_desc: "Reklam harcaması yapıldı.",
        gift_coupon_desc: "Hediye kupon tanımlandı.",
        welcome_coupon_desc: "Hoşgeldin kuponu tanımlandı.",
        ad_balance_refunded_desc: "Harcama iadesi yapıldı.",
        credit_card_desc: "Kredi kartı ile yükleme gerçekleşti.",
        detailsSubtext:
          "Reklam harcamanızla ilgili detayları görüntüleyebilirsiniz.",
        spendTab: "Kampanya Harcamaları",
        budgetTab: "Bütçe Kaynakları",
        advertiser_budget: "Bakiye",
        approve: "İşleme devam etmek ister misiniz?",
        advertiser: "Reklamveren:",
        balance: "Yüklenecek Bakiye:",
        update: "Fatura numarası ekleyebilir ya da düzenleyebilirsiniz.",
        title: "Fatura Bilgileri",
        payment: "Ödeme",
        success: "Başarılı",
        waiting: "Bekliyor",
        failure: "Başarısız",
        invoiceWarning:
          "Fatura numarasını maksimum 30 karakter girebilirsiniz.",
        note: "Bakiye Yükleme Notu",
        noteHelp: "Bu bakiyeyi yükleme nedenini ekleyin. Örn. Ağustos Bakiyesi",
      },
      users: {
        label: "E-Posta",
        subtext: "Davet için onay maili gönderilecektir.",
        invite: "Davetiyenin gönderileceği e-posta adresini giriniz.",
        warn: "Kendi hesabınızı durduramazsınız.",
        error: "Varolan bir mail adresi eklediniz.",
        search: "Kullanıcılarda Ara",
        select: "Eklemek istediğiniz kullanıcı tipini seçiniz.",
        advertiser: "Reklamveren",
        advertiserText:
          "Yalnızca seçeceğiniz reklamveren hesabına erişebilir, reklam faaliyetlerini yürütecek kişidir.",
        marketplace: "Admin",
        marketplaceText:
          "Reklam platformundaki tüm yetkinliklere sahiptir, kullanıcılara erişim izni verebilir veya yönetebilir.",
        limited: "Kısıtlı Erişim",
        full: "Tam Erişim",
      },
      invited: {
        error:
          "Aynı hesap için aynı maile en fazla 10 dakikada bir davet gönderilebilir.",
        success: "Kullanıcı yeniden davet edildi.",
        search: "Davet Edilmiş Kullanıcılarda Ara",
        title: "Davetiye Detayları",
        info: "Genel Bilgiler",
        mail: "E-Posta:",
        advertiser: "Reklamveren:",
        urlTitle: "Davetiye Bağlantısı",
        link: "Reklamveren kullanıcısı nasıl eklenir?",
        single: "Tek Kullanıcı",
        multiple: "Toplu Kullanıcı",
        advertiserText: "Reklam veren seçiniz.",
        draggerTitle:
          "Toplu E-Posta yüklemek için bir <strong>.CSV</strong> dosyası seçin",
        draggerText: "veya buraya sürükleyip bırakın.",
        csvError: "Dosya yüklenirken bir hata oluştu.",
        csvInfo:
          "Kullanıcıları farklı reklamveren hesaplarına bir CSV dosyası kullanarak toplu olarak davet edebilirsiniz. CSV dosyasının formatı ile ilgili bilgilere aşağıdaki linki kullanarak ulaşabilirsiniz.",
        restriction: "Kısıtlı Erişim",
        restrictionText:
          "Bu seçeneği açtığınızda kullanıcıya sadece raporlama yetkisi verilir.",
        edit: "Düzenle",
        role: "Rol:",
        MARKETPLACE: "Admin",
        MARKETPLACE_REPORTER: "Kısıtlı Admin",
        ADVERTISER: "Reklamveren",
        ADVERTISER_REPORTER: "Kısıtlı Reklamveren",
        archived: "Davet etmek istediğiniz reklamveren arşivlenmiştir.",
      },
      sync: {
        file: "Dosya",
        api: "API",
        feed: "Feed",
        products_created: "Ürünler oluşturuldu.",
        products_updated: "Ürünler güncellendi.",
        products_deactivated: "Ürünler durduruldu.",
        products_activated: "Ürünler aktifleştirildi.",
        missing_advertisers: "Reklamverenler eksik.",
        advertisers_created: "Reklamverenler oluşturuldu.",
        advertisers_updated: "Reklamverenler güncellendi.",
        advertisers_deactivated: "Reklamverenler durduruldu.",
        advertisers_activated: "Reklamverenler aktifleştirildi.",
        categories_created: "Kategoriler oluşturuldu.",
      },
      placement: {
        add: "Yeni Reklam Yerleşimi Ekle",
        update: "Reklam Yerleşimini Düzenle",
        display: "Görsel Reklam",
        product: "Sponsorlu Ürün",
        settings: "Genel Ayarlar",
        settingsText: "Reklam yerleşiminizin genel ayarları",
        name: "Reklam Yerleşimi İsmi",
        nameText: "Bu isim yalnızca sizin tarafınızdan görünür.",
        format: "Reklam Formatı",
        formatText: "Reklam yerleşiminizin görüntüleneceği format",
        sponsoredText:
          "İlgili anahtar kelimeler ve kategorilerde ürün gösterimi",
        displayText:
          "İlgili anahtar kelime ve kategorilerde görsel reklam gösterimi",
        suggestedTitle: "Önerilen Boyutlar",
        suggestedText:
          "Reklam yerleşiminizin asıl boyutudur. Bu boyut reklamverenler tarafından görüntülenecektir.",
        suggestedError: "En fazla 1 boyut ekleyebilirsiniz.",
        acceptedTitle: "Kabul Edilen Boyutlar",
        acceptedText:
          "Reklam yerleşiminizin içerisinde gösterilebilecek diğer reklam boyutlarıdır. Boyutlar reklamverenler tarafından görüntülenecektir.",
        acceptedError: "Reklam boyutu seçiniz.",
        formatError: "Format seçiniz.",
        cross_sell: "Cross-Sell",
        crossText:
          "İlgili ürün detay sayfalarında reklam gösterimi, listelenen ürünlerin reklam olarak gösterilmesi engellenir.",
        maxAds: "Maksimum Reklam Sayısı",
        maxAdsText: "Yerleşimin sahip olabileceği maksimum reklam sayısıdır.",
        crossTitle: "Reklam Engelleme Davranışı",
        crossSub:
          "Reklam yerleşiminizin asıl boyutudur. Bu boyut reklamverenler tarafından görüntülenecektir.",
        crossPlaceholder: "Bir tip seçiniz.",
        crossProduct: "Listelenen Ürünü Engelle",
        crossBlock: "Reklamı Engelleme",
        crossError: "Davranış seçiniz.",
        targeting: "Hedefleme",
        targetingText:
          "Reklam yerleşiminizin hedefleneceği yerleşim grubunu seçiniz.",
        typeError: "Hedefleme seçiniz.",
        targetingPlaceholder: "Hedef Yerleşim",
        search: "Arama Sonuçları",
        category: "Kategori Sayfaları",
        homepage: "Anasayfa",
        detail: "Ürün Detay Sayfaları",
        collection: "Koleksiyon",
        video: "Video Reklam",
        videoText:
          "İlgili anahtar kelime ve kategorilerde video reklam gösterimi",
        minAds: "Minimum Reklam Sayısı",
        minAdsText: "Yerleşimin sahip olabileceği minimum reklam sayısıdır.",
        minBid: "Minimum Teklif",
        minBidText: "Bu yerleşim için verilebilecek minimum teklif rakamıdır.",
        videoWarning:
          "Video reklam için maksimum reklam sayısı <strong>“1”</strong>dir.",
        promoteText:
          "Ürün promosyonu açıkken en fazla 1 reklam ekleyebilirsiniz.",
        displayWithProduct: "Ürünle Görsel Reklam",
        videoWithProduct: "Ürünle Video Reklam",
        display_with_product: "Ürünle Görsel Reklam",
        video_with_product: "Ürünle Video Reklam",
      },
      restrictions: {
        category: "Kategori",
        keyword: "Kelime",
        categoryTitle: "Kategori Bazlı Teklif & ACoS",
        categoryText:
          "Yalnızca belirlediğiniz kategorilerde geçerli olacak bir minimum teklif ve hedef ACoS girebilir, kategori alaka seviyesini özelleştirebilirsiniz. Bu alanda yaptığınız ayarlamalar genel ayarlamalara göre önceliklidir.",
        keywordTitle: "Anahtar Kelime Kara Listesi",
        keywordText:
          "Belirlediğiniz anahtar kelimelerde reklam gösterimini kısıtlayabilirsiniz.  Exclusive tipi ile kısıtlanan arama sonuçlarında yalnızca içinde bu kelimenin geçtiği ürünlerin reklamı yapılır. Blok tipi ile kısıtlanan arama sonuçlarında hiçbir reklam gösterimi yapılmaz.",
        add: "Yeni Kelime Ekle",
        placeholder: "Bir kelime giriniz.",
        info: "Genel Bilgiler",
        label: "Anahtar Kelime",
        help: "Arama sonuçlarını kısıtlamak istediğiniz anahtar kelime",
        type: "Kısıtlama Tipi",
        typeText:
          "Anahtar kelimeniz ile yapılacak arama sonuçlarına uygulanacak kısıtlama tipi",
        exclusive: "Exclusive",
        exclusiveText:
          "Arama sonuçlarında yalnızca içerisinde anahtar kelimenizin geçtiği ürünlerin reklamı yapılır.",
        block: "Block",
        blockText: "Arama sonuçlarında reklamların tümü bloklanır.",
        all: "Tümünü Göster",
        edited: "Düzenlenen Kategorileri Göster",
        expand: "Tümünü Genişlet",
        collapse: "Tümünü Daralt",
      },
    },
    auth: {
      title: "{{value}} Reklam Platformu",
      accounts: {
        title: "Hesap Seç",
        text: "Giriş yapmak istediğiniz hesabı seçin.",
        logout: "Çıkış Yap",
        noAccount: "Aktif Hesap Bulunamadı!",
        noAccountText:
          "Kullanıcınızla ilişkili aktif bir hesap bulunamadı. Lütfen <strong>{{value}}</strong> üzerinden hesap yöneticiniz ile iletişime geçiniz:",
        noAccountTextNoEmail:
          "Kullanıcınızla ilişkili aktif bir hesap bulunamadı. Lütfen hesap yöneticiniz ile iletişime geçiniz.",
        contact: "İletişime Geç",
        login: "Anasayfaya Dön",
      },
      login: {
        title: "Giriş Yap",
        text: "Tekrar hoşgeldin! Lütfen bilgilerinizi giriniz.",
        remember: "Beni Hatırla",
        forget: "Şifremi Unuttum?",
        button: "Giriş Yap",
        mail: "E-Posta",
        password: "Şifre",
        mailErr: "Lütfen e-postanızı giriniz.",
        passwordErr: "Lütfen şifrenizi giriniz.",
        incorrectPassword: "Şifreniz hatalı!",
        incorrectPasswordText: "Lütfen yeniden deneyiniz.",
        recaptcha: "reCAPTCHA doğrulama hatası.",
        recaptchaText: "Lütfen tekrar deneyin.",
        notActive: "Hesabınız askıya alındı.",
        notActiveText: "Lütfen destek ekibiyle görüşün.",
        multipleAccountErr: "Yeni hesap oluşturulurken bir hata meydana geldi.",
        notHaveAnAccount: "Varolan bir hesabın yok mu?",
        signup: "Kayıt Ol",
      },
      onboarding: {
        help: "Demo Talep Et",
        login: "Giriş yapın",
        to: "ve",
        retail: "perakende medya",
        infrastructure:
          "altyapınız <strong>saniyeler içinde hazır olsun.</strong>",
        linkedInText: "Lorem ipsum dolor sit amet consectetur.",
        linkedInButton: "LinkedIn ile Giriş Yap",
        linkedInTextAccept: "<1>Üyelik şartlarını</1> kabul etmiş olursunuz.",
        firstStepText:
          "Teknik geliştirmeler veya aylar süren entegrasyonlara ihtiyaç duymadan, perakende medya özelliğini platformunuza birkaç dakika içinde ekleyebilirsiniz. Satıcılarınız birkaç dakika içinde web sitenizde reklam verebilecek.",
        changeAccount: "Hesap Değiştir",
        createTitle: "Kendi Reklam Platformunuzu Oluşturun",
        createSubtitle: "Platform bilgilerini girin ve kurulumu tamamlayın!",
        websiteLabel: "Şirket Web Sitesi",
        websiteHelp:
          "Uzantılar veya parametreler olmadan geçerli bir web sitesi URL'si girin. Örnek: www.gowit.com",
        passwordLabel: "Şifre",
        passwordHelp: "Şifreniz en az 8 karakterden oluşmalıdır.",
        typeLabel: "Örnek Ürün Türü",
        typeHelp: "Platforma eklenecek örnek ürün türünü seçin.",
        clothing: "Giyim",
        tech: "Teknoloji",
        grocery: "Market",
        continue: "Devam Et",
        thirdStepText:
          "Kampanyaya özel hedefleme, teklif verme ve bütçe ayarlarınızı kolay kurulumla yapın ve kampanyanızı başlatın!",
        campaignTitle: "İlk Kampanyanızı Oluşturun",
        campaignText:
          "Bir görsel yükleyerek ilk perakende medya kampanyanızı oluşturun. Hazır görseliniz yoksa örnek görseli kullanabilirsiniz.",
        autoTitle: "Otomatik Gösterim",
        autoText:
          "Reklamlar web sitenizin üst kısmında otomatik olarak açılır, kodlama bilgisine ihtiyaç duyulmaz.",
        autoTag: "Teknik bilgisi olmayan kullanıcılar için uygundur.",
        customTitle: "Özel Gösterim",
        customText:
          "Reklam yerleşimini ve boyutunu seçin. Temel kodlama gerektirir.",
        customTag: "Teknik bilgisi olan kullanıcılar için uygundur.",
        sampleBtn: "Örnek Görseli Kullan",
        uploadBtn: "Görsel Yükle",
        sampleText:
          "Aşağıdaki görsel reklam tercihinizde örnek olarak kullanılacaktır.",
        uploadText: "Bu görselin yalnızca 970x50px boyutuna izin verilir!",
        uploadInnerTitle: "<strong>.JPG ya da.PNG</strong> dosyası yükleyin!",
        uploadInnerText: "veya bir görsel sürükleyip bırakın.",
        errorTitle: "Hatalı görsel!",
        errorText: "Lütfen 970x50px boyutunda bir görsel yükleyin.",
        errorBtn: "Yeni Bir Görsel Yükle",
        errorWarning: "Yüklediğiniz görsel 970x50 piksel boyutunda olmalıdır!",
        create: "Kampanya Oluştur",
        fourthStepText:
          "Oluşturulan kodu sitenize yerleştirerek, reklamlarınızı web sitenizde zahmetsizce göstermeye başlayın!",
        embedCodeTitle: "Kodu websiteye yerleştirme",
        embedCodeText:
          "Reklamınızı yayınlamak için aşağıdaki kodu web sitenizin HTML'ine ekleyin veya bir etiket yöneticisi aracılığıyla entegre edin.",
        pending: "Trafik Bekleniyor",
        live: "Canlı",
        activityTitle: "Kod Durumu:",
        connectionText: "Bağlantı otomatik olarak kurulmazsa butona tıklayın!",
        checkConnection: "Kontrol Et",
        finishOnboarding: "Panele Git",
        redirectURL: "Reklamınızın Yönlendirme Bağlantısı",
        redirectHelp:
          "Lütfen kullanıcıların reklamınıza tıkladığında yönlendirileceği URL'yi girin.",
        sampleBanner: "Örnek Görseli Kullan",
        sampleBannerText: "Reklamınız web sitenizde bu şekilde görünecektir.",
        account: "Varolan bir hesabınız var mı?",
        link: "Giriş Yap",
        uploading: "Yükleniyor...",
        custom: "Kendi Sağlayıcım",
        gtm: "Google Tag Manager",
        mobile: {
          title: "En iyi deneyim için masaüstü kullanın!",
          text: "<strong>15 saniye</strong> içinde GoWit web sitesine yönlendirileceksiniz...",
          link: "Web Sitesine Git",
        },
      },
      signUp: {
        title: "Kayıt Ol",
        text: "Hoşgeldin, {{value}}",
        password: "Şifre",
        passwordErr: "Lütfen şifrenizi giriniz.",
        warning:
          "İçerisinde rakam, sayı, noktalama işareti, büyük harf ve küçük harf içeren en az 8 karakterden oluşan bir şifre giriniz.",
        already: "Varolan bir hesabın var mı?",
        successBtn: "Giriş Yap",
        success: "Kayıt Başarılı!",
        successText:
          "{{value}} mailiyle ilerlediğin kaydın başarıyla tamamlandı. Panele devam etmek için giriş yap butonuna tıklayınız.",
        help: "Nasıl Yardımcı Olabiliriz?",
        err: "Lütfen geçerli bir şifre giriniz.",
        userExistErr:
          "Mail adresine tanımlı bir kullanıcı bulunmaktadır. Erişebileceğiniz hesapları görmek için lütfen giriş yapınız.",
        privacyErr:
          "Kaydınızı tamamlamak için lütfen gerekli tüm sözleşmeleri kabul edin.",
        error: "Davetiye bulunamadı.",
        errorText:
          "Kayıt olabilmek için bir davetiyeye ihtiyacınız var. Lütfen {{value}} üzerinden hesap yöneticiniz ile iletişime geçiniz.",
        errorTextNoEmail:
          "Kayıt olabilmek için bir davetiyeye ihtiyacınız var. Lütfen hesap yöneticiniz ile iletişime geçiniz.",
        errorBtn: "Anasayfaya Dön",
      },
      password: {
        title: "Şifreni mi Unuttun?",
        text: "Parolanı sıfırlayabilmen için sana bir link göndereceğiz. Lütfen platformda kullandığın e-posta adresini gir.",
        mail: "E-Posta",
        mailInputErr: "Lütfen mail adresi giriniz.",
        reset: "Şifreni Sıfırla",
        return: "Giriş Sayfasına Git",
        error: "Lütfen şifresini sıfırlamak istediğiniz mail adresini giriniz.",
        recaptchaError:
          "Güvenlik nedeniyle şifre sıfırlama girişimi başarısız oldu, lütfen sayfayı yenileyip tekrar deneyin.",
        check: "E-Posta’nı Kontrol Et",
        message: "{{value}} adresine bir sıfırlama e-postası gönderdik.",
      },
      newPassword: {
        title: "Yeni Şifre Belirle",
        text: "Yeni şifren eski kullandığın şifrelerden farklı olmalıdır.",
        help: "İçerisinde rakam, sayı, noktalama işareti, büyük harf ve küçük harf içeren en az 8 karakterden oluşan bir şifre giriniz.",
        password: "Şifre",
        passwordInputErr: "Lütfen şifrenizi giriniz.",
        again: "Şifre Tekrar",
        check: "Şifre Sıfırlama Başarılı",
        message: "Belirlediğin yeni şifren ile giriş yapabilirsin.",
        errTitle: "Lütfen geçerli bir şifre giriniz.",
      },
      welcome: {
        title: "Hoşgeldin!",
        loginText:
          "Hesabın varsa giriş yap butonuna tıklayarak bilgilerini girebilirsin.",
        loginBtn: "Giriş Yap",
        signupText:
          "Henüz bir hesabın yoksa kayıt olmak için aşağıdaki butona tıklayarak şifreni belirleyebilir ve sonrasında hesabına giriş yapabilirsin.",
        signupBtn: "Kayıt Ol",
      },
    },
  },
  common: {
    save: "Kaydet",
    selectAll: "Hepsini Seç",
    search: "Ara",
    edit: "Düzenle",
    export: "Dışarı Aktar",
    remove: "Kaldır",
    add: "Ekle",
    column: "Kolon",
    archive: "Arşivle",
    removeArchive: "Arşivden Çıkar",
    delete: "Sil",
    filter: "Filtrele",
    continue: "Devam Et",
    cancel: "Vazgeç",
    cancellation: "İptal",
    okay: "Tamam",
    create: "Oluştur",
    goBack: "Geri Dön",
    listed: "Listelenenler",
    archived: "Arşivlenenler",
    invited: "Davet Edilenler",
    exact: "Tam Eşleşme",
    phrase: "Sıralı Eşleşme",
    broad: "Geniş Eşleşme",
    turkey: "Türkiye",
    abd: "ABD",
    try: "Türk Lirası",
    usd: "Amerikan Doları",
    iqd: "Irak Dinarı",
    twd: "Yeni Tayvan Doları",
    hkd: "Hong Kong Doları",
    krw: "Güney Kore Wonu",
    jpy: "Japon Yeni",
    zone: "Avrupa/İstanbul",
    clear: "Temizle",
    completed: "Tamamlandı",
    notCompleted: "Tamamlanmadı",
    now: "Şimdi",
    checkAll: "Tümünü Seç",
    keyword: "Anahtar Kelime",
    negative: "Negatif Anahtar Kelime",
    tr: "Türkçe",
    en: "İngilizce",
    day: "Gün",
    invite: "Davet Et",
    accept: "Onayla",
    reject: "Reddet",
    pageTotal: "Gösterilecek satır sayısı:",
    addUser: "Kullanıcı Ekle",
    reinvite: "Tekrar Davet Et",
    advertiser: "Reklamveren",
    admin: "Admin",
    new_customer: "Yeni Müşteriler",
    existing_customer: "Varolan Müşteriler",
    category_buyer: "Kategori Müşterileri",
    invite_url: "Davet Bağlantısını Gör",
    copy: "Kopyasını Oluştur",
    vastTag: "VAST Reklam Etiketini Görüntüle",
    next: "İlerle",
    watch: "İzle",
    or: "veya",
    exit: "Çık",
    docs: "Entegrasyon ve reklam gösterme hakkında bilgi edinmek için API dokümantasyonumuzu inceleyin!",
    apply: "Uygula",
    reset: "Sıfırla",
    read: "Okundu olarak işaretle",
    unread: "Okunmadı olarak işaretle",
    view: "Görüntüle",
    days: {
      monday: "Pazartesi",
      tuesday: "Salı",
      wednesday: "Çarşamba",
      thursday: "Perşembe",
      friday: "Cuma",
      saturday: "Cumartesi",
      sunday: "Pazar",
    },
    languages: {
      arabic: "Arapça",
      english: "İngilizce",
    },
    table: {
      name: "İsim",
      status: "Aktif",
      state: "Durum",
      agency_name: "Ajans",
      type: "Reklamveren Türü",
      resource_id: "Satıcı ID",
      last_login: "Son Giriş Tarihi",
      balance: "Bakiye",
      sum_coupons_amount: "Kupon",
      total_balance: "Toplam Bakiye",
      impressions: "Görüntülenme",
      viewable_impressions: "Aktif Görüntülenme",
      clicks: "Tıklama",
      CTR: "Tıklama Oranı",
      spend: "Reklam Harcaması",
      sale_amount: "Satış",
      direct_sale_amount: "Doğrudan Satış",
      indirect_sale_amount: "Dolaylı Satış",
      sale: "Satış Adedi",
      direct_sale: "Doğrudan Satış Adedi",
      indirect_sale: "Dolaylı Satış Adedi",
      ACoS: "ACoS",
      CVR: "Dönüşüm Oranı",
      campaign_name: "İsim",
      advertiser_name: "Reklamveren",
      ad_type: "Kampanya Türü",
      start_date: "Başlangıç Tarihi",
      end_date: "Bitiş Tarihi",
      budget_type: "Bütçe",
      bid_type: "Teklif Türü",
      fixed_bid: "Teklif",
      custom_key: "ID",
      ad_format: "Reklam Formatı",
      max_ads: "Maks. Reklam Sayısı",
      recommended_size: "Önerilen Boyut",
      sizes: "Kabul Edilen Boyutlar",
      image_url: "Kreatif",
      policy_status: "Durum",
      transaction_date: "Tarih",
      invoice_number: "Fatura No",
      transaction_type: "İşlem Türü",
      description: "Açıklama",
      created_by: "Oluşturan",
      amount: "Tutar",
      spend_details: "Detay",
      note: "Not",
      expire_date: "Son Kullanma Tarihi",
      account_user_status: "Aktif",
      user_email: "E-Posta",
      account_user_role: "Yetki",
      user_last_login: "Son Giriş Tarihi",
      account_user_created_at: "Oluşturulma Tarihi",
      email: "E-Posta",
      account_role: "Yetki",
      created_at: "Davet Edilme Tarihi",
      report_name: "İsim",
      report_start_date: "Başlangıç Tarihi",
      report_end_date: "Bitiş Tarihi",
      report_type: "Rapor Türü",
      report_delivery_frequency: "Sıklık",
      report_created_at: "Oluşturulma Tarihi",
      text: "İsim",
      minimum_bid: "Min. CPC",
      minimum_cpm: "Min. CPM",
      relevance: "Seviye",
      restriction_type: "Kısıtlama Tipi",
      row_count: "Satır Sayısı",
      message: "İşlem Mesajı",
      sync_type: "Senk. Tipi",
      file_name: "Dosya Adı",
      product: "Ürün",
      sku: "SKU",
      buybox: "Buybox",
      city: "Şehir",
      age_range: "Yaş",
      gender: "Cinsiyet",
      platform: "Platform",
      customer: "Müşteriler",
      category: "Kategori",
      keyword: "Anahtar Kelime",
      match_type: "Eşleşme Türü",
      bid: "Teklif",
      category_volume: "Görüntülenme Hacmi",
      active: "Aktif",
      paused: "Durduruldu",
      invited: "Davet Edildi",
      uninvited: "Davet Edilmedi",
      accepted: "Kabul Edildi",
      archived: "Arşivlendi",
      on: "Yayınlanıyor",
      stopped: "Durduruldu",
      open: "Açık",
      closed: "Kapalı",
      coupon: "Kupon",
      sync_log_created_at: "Tarih",
      category_text: "Kategori",
      keyword_text: "Anahtar Kelime",
      creative_name: "İsim",
      invoice: "Fatura No",
      acos: "ACoS",
      sum_click: "Tıklama",
      sum_spend: "Reklam Harcaması",
      sum_impression: "Görüntülenme",
      sum_viewable_impression: "Aktif Görüntülenme",
      sum_sale: "Satış Adedi",
      sum_direct_sale: "Doğrudan Satış Adedi",
      sum_indirect_sale: "Dolaylı Satış Adedi",
      sum_direct_sale_amount: "Doğrudan Satış",
      sum_indirect_sale_amount: "Dolaylı Satış",
      sum_sale_amount: "Satış",
      invite_status: "Davet Durumu",
      spending_type: "Harcama Dağılımı",
      min_ads: "Min. Reklam Sayısı",
      min_bid: "Min. Teklif",
      languages: "Dil",
      video_start: "Video Başlangıçları",
      video_complete: "Tamamlanan Videolar",
      VCR: "VCR",
      entity: "Veri Kaynağı",
      import_method: "İçe Aktarma Yöntemi",
      schedule_interval: "Döngü",
      finish_date: "Bitiş Tarihi",
      id: "ID",
      uuid: "UUID",
    },
    equality: {
      contains: "İçerir",
      not_contains: "İçermez",
      equal: "Eşittir",
      not_equal: "Eşit Değildir",
      less_than: "Küçüktür",
      grater_than: "Büyüktür",
      greater_or: "Büyük Eşittir",
      less_or: "Küçük Eşittir",
      any_of: "Bunlardan Herhangi Biri",
      none_of: "Bunların Hiçbiri",
    },
    filters: {
      campaignName: "İsim",
      campaignStartDate: "Başlangıç Tarihi",
      campaignEndDate: "Bitiş Tarihi",
      campaignStatus: "Aktif",
      campaignState: "Durum",
      campaignBudget: "Bütçe",
      campaignBudgetType: "Bütçe Türü",
      campaignBidType: "Teklif Türü",
      campaignMaxAcos: "Maksimum ACoS",
      campaignFixedBid: "Sabit Teklif",
      campaignAdType: "Kampanya Türü",
      sumSale: "Satış Adedi",
      sumSpend: "Reklam Harcaması",
      ACoS: "ACoS",
      productName: "Ürün",
      productCampaignStatus: "Durum",
      sumClick: "Tıklama",
      CVR: "Dönüşüm Oranı",
      keywordCampaignStatus: "Durum",
      keywordText: "Anahtar Kelime",
      keywordMatchType: "Eşleşme Türü",
      negativeKeywordCampaignStatus: "Durum",
      negativeKeywordText: "Negatif Anahtar Kelime",
      negativeKeywordMatchType: "Eşleşme Türü",
      cityName: "Şehir",
      cityCampaignStatus: "Durum",
      ageRangeText: "Yaş",
      ageRangeCampaignStatus: "Durum",
      genderText: "Cinsiyet",
      genderCampaignStatus: "Durum",
      platformText: "Platform",
      platformCampaignStatus: "Durum",
      customerTargetingText: "Müşteri Türü",
      customerTargetingCampaignStatus: "Durum",
      placementText: "Reklam Yerleşimi",
      placementCampaignStatus: "Durum",
      reportName: "Rapor İsmi",
      reportStartDate: "Başlangıç Tarihi",
      reportEndDate: "Bitiş Tarihi",
      reportType: "Rapor Türü",
      reportDeliveryFrequency: "Gönderim Türü",
      reportCreatedAt: "Oluşturulma Tarihi",
      agencyName: "Ajans İsmi",
      advertiserName: "Reklamveren",
      advertiserStatus: "Reklamveren Durumu",
      sumSaleAmount: "Satış",
      sumDirectSale: "Doğrudan Satış",
      sumIndirectSale: "Dolaylı Satış",
      sumDirectSaleAmount: "Doğrudan Satış Adedi",
      sumIndirectSaleAmount: "Dolaylı Satış Adedi",
      sumImpression: "Görüntülenme",
      productBrand: "Marka",
      CTR: "Tıklama Oranı",
      categoryName: "Kategori",
      advertiserId: "Reklamveren ID",
      agencyId: "Ajans ID",
      name: "Ürün İsmi",
      brand: "Marka",
      retailer_taxonomy: "Ürün Kategorisi",
      advertiser_id: "Reklamveren ID",
      transaction_type: "İşlem Türü",
      advertiser_name: "Reklamveren",
      created_by: "Oluşturan",
      campaignDailyBudget: "Günlük Bütçe",
      campaignTotalBudget: "Toplam Bütçe",
    },
    equalityValues: {
      ct: "İçerir",
      ni: "İçermez",
      eq: "Eşittir",
      ne: "Eşit Değildir",
      in: "Bunlardan Herhangi Biri",
      notin: "Bunların Hiçbiri",
      lt: "Küçüktür",
      gt: "Büyüktür",
      ge: "Büyük Eşittir",
      le: "Küçük Eşittir",
    },
    values: {
      ACTIVE: "Yayınlanıyor",
      PAUSED: "Durduruldu",
      DAILY: "Günlük",
      TOTAL: "Toplam",
      STANDART: "Varsayılan",
      FIXED: "Sabit",
      MAX_ACOS: "Gelişmiş",
      WEEKLY: "Haftalık",
      MONTHLY: "Aylık",
      EXACT: "Tam Eşleşme",
      PHRASE: "Sıralı Eşleşme",
      BROAD: "Geniş Eşleşme",
      BALANCE_UPDATED: "Bakiye Yüklemesi",
      GIFT_COUPON: "Hediye Kuponu",
      WELCOME_COUPON: "Hoşgeldin Kuponu",
      SPEND: "Reklam Harcaması",
      NOT_STARTED: "Kampanya henüz başlamadı.",
      DATE_PASSED: "Kampanya süresi bitti.",
      STATUS_PAUSED: "Kampanya durduruldu.",
      OUT_OF_DAILY_BUDGET: "Kampanyanın günlük bütçesi tükendi.",
      OUT_OF_TOTAL_BUDGET: "Kampanyanız toplam bütçe sınırına ulaştı.",
      PRODUCTS_STOPPED: "Hiçbir ürün yayınlanmıyor.",
      KEYWORDS_STOPPED: "Hiçbir kelime yayınlanmıyor.",
      CATEGORIES_STOPPED: "Hiçbir kategori yayınlanmıyor.",
      STATUS_ARCHIVED: "Kampanyayı arşivlediniz.",
      CREATIVES_STOPPED: "Aktif kreatif bulunmamaktadır.",
      INSUFFICIENT_BALANCE: "Bakiyeniz yetersiz. Lütfen bakiye yükleyin.",
      LOW_BID: "Teklifiniz minimum teklifin altında.",
      ADVERTISER_PAUSED: "Reklamveren aktif değil.",
      REGIONS_STOPPED: "Hiçbir bölge yayınlanmıyor.",
      PRODUCT: "Sponsorlu Ürün",
      BRAND: "Sponsorlu Marka",
      DISPLAY: "Görsel Reklam",
      STORE: "Sponsorlu Mağaza",
      VIDEO: "Video Reklam",
      DISPLAY_WITH_PRODUCT: "Ürünle Görsel Reklam",
      VIDEO_WITH_PRODUCT: "Ürünle Video Reklam",
    },
    month: {
      may: "Mayıs",
      june: "Haziran",
      september: "Eylül",
      october: "Ekim",
      december: "Kasım",
    },
  },
};

export default tr;
