import { Input } from "antd";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import cc from "classcat";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ReactComponent as EditOutlined } from "assets/icons/editWhiteOutlined.svg";
import { ReactComponent as MoneyOutlined } from "assets/icons/moneyOutlined.svg";
import { useCurrency } from "context/CurrencyProvider";
import useTableBulkEdit from "hooks/useTableBulkEdit";
import { validateNumber } from "utils/helpers";
import RetailFormInput from "components/Form/RetailFormInput";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailInfoTag from "components/Tag/RetailInfoTag";
import cm from "./style.module.scss";

export interface RetailBulkUpdateBarProps {
  tableConfig: any;
  selectedRowKeys: any;
  handleRowkeys(): void;
  archived?: boolean;
}

const RetailBulkUpdateBar = ({
  archived,
  tableConfig,
  selectedRowKeys,
  handleRowkeys,
}: RetailBulkUpdateBarProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const { currencySymbol } = useCurrency();

  const { changeValue, mutateBids } = useTableBulkEdit(
    tableConfig,
    selectedRowKeys,
    handleRowkeys
  );

  const [updateBidVisible, setUpdateBidVisible] = useState(false);

  const [updatedBidValue, setUpdatedBidValue] = useState<any>(null);

  const isForFixedBid = tableConfig?.isRelation && tableConfig?.isFixedBid;

  const bidInputStatus =
    updatedBidValue && updatedBidValue < tableConfig.min ? "error" : "";

  const bidModalButtonDisabled =
    updatedBidValue === null || updatedBidValue < tableConfig.min;

  const openUpdateBidModal = () => setUpdateBidVisible(true);

  const archive = () => changeValue("ARCHIVED");

  const pause = () => {
    switch (pathname) {
      case "/admin/creatives":
        return changeValue("REJECTED");
      default:
        return changeValue("PAUSED");
    }
  };

  const activate = () => {
    switch (pathname) {
      case "/admin/creatives":
        return changeValue("ACCEPTED");
      default:
        return changeValue("ACTIVE");
    }
  };

  const StatusUpdateButtons = () => {
    return (
      <>
        <button className={cc([cm.btn, cm.stopBtn])} onClick={pause}>
          {t("components.bulkUpdate.pause")}
        </button>
        <button className={cc([cm.btn, cm.activeBtn])} onClick={activate}>
          {t("components.bulkUpdate.activate")}
        </button>
      </>
    );
  };

  const ArchiveButtons = () => {
    return (
      <>
        {archived ? (
          <button className={cc([cm.btn, cm.listBtn])} onClick={activate}>
            {t("components.bulkUpdate.list")}
          </button>
        ) : (
          <button className={cc([cm.btn, cm.archiveBtn])} onClick={archive}>
            {t("components.bulkUpdate.archive")}
          </button>
        )}
      </>
    );
  };

  const BidButton = () => {
    return (
      <button
        className={cc(["flex", cm.btn, cm.editBtn])}
        onClick={openUpdateBidModal}
      >
        <EditOutlined />
        {t("components.bulkUpdate.bid")}
      </button>
    );
  };

  const UpdateButtons = () => {
    if (pathname.includes("campaigns") || pathname.includes("users")) {
      return (
        <Col className={cm.btnContainer}>
          <ArchiveButtons />
          <StatusUpdateButtons />
        </Col>
      );
    }
    if (isForFixedBid) {
      return (
        <Col className={cm.btnContainer}>
          <BidButton />
          <StatusUpdateButtons />
        </Col>
      );
    }
    if (pathname.includes("reports") || pathname.includes("agencies"))
      return <ArchiveButtons />;
    else return <StatusUpdateButtons />;
  };

  const handleBidUpdate = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setUpdatedBidValue(target.value);

  const handleClose = () => {
    setUpdateBidVisible(false);
    setUpdatedBidValue(null);
  };

  const onOk = () => {
    mutateBids(updatedBidValue);
    handleClose();
  };

  //Render different help messages for different cases when updating bids.
  const bidInputHelpText = () => {
    if (bidInputStatus === "error")
      return t("components.table.keywords.warning", {
        value: `${currencySymbol}${tableConfig.min}`,
      });
    else if (selectedRowKeys.length > 0)
      return t("components.bulkUpdate.bidHelp");
    else return t("components.bulkUpdate.updateAllBidsHelp");
  };

  return (
    <>
      {/**
       * General Bulk Update Bar is here.
       */}
      {selectedRowKeys.length > 0 && (
        <Row className={cc(["flex", cm.container])}>
          <RetailInfoTag type="FILTER" closable={false}>
            {t("components.bulkUpdate.selected", {
              value: selectedRowKeys.length,
            })}
          </RetailInfoTag>
          <UpdateButtons />
        </Row>
      )}
      {/**
       * Update Bulk All Rows Button is here.
       */}
      {isForFixedBid && selectedRowKeys.length === 0 && (
        <Col className={cc(["flex", cm.bidCol])}>
          <button
            onClick={openUpdateBidModal}
            className={cc(["flex", cm.directUpdateBtn])}
          >
            <MoneyOutlined />
            {t("components.bulkUpdate.updateAllBids")}
          </button>
          <p>{t("components.bulkUpdate.updateAllBidsText")}</p>
        </Col>
      )}

      {/**
       * Update Bulk Bid Modal is here.
       */}
      {updateBidVisible && (
        <CampaignDetailsModal
          type="UPDATE_BID"
          visible={updateBidVisible}
          onOk={onOk}
          onCancel={handleClose}
          disabled={bidModalButtonDisabled}
        >
          {selectedRowKeys.length > 0 ? (
            <p className={cc(["flex", cm.text])}>
              <span>{t("components.bulkUpdate.count")}</span>
              <span className={cm.underlined}>
                {t("components.bulkUpdate.row", {
                  value: selectedRowKeys?.length,
                })}
              </span>
            </p>
          ) : (
            <p className={cm.text}>
              {t("components.bulkUpdate.updatingAllBids")}
            </p>
          )}

          <RetailFormInput
            isFocused={updatedBidValue !== null}
            label={t("components.bulkUpdate.bidLabel")}
            name="bid"
            className={cc(["floating", updatedBidValue ? "" : cm.hidePrefix])}
            validateStatus={bidInputStatus}
            help={bidInputHelpText()}
          >
            <Input
              className={cc(["number-input floating", cm[bidInputStatus]])}
              prefix={currencySymbol}
              onChange={handleBidUpdate}
              onKeyDownCapture={(e) => validateNumber(e)}
              value={updatedBidValue}
            />
          </RetailFormInput>
        </CampaignDetailsModal>
      )}
    </>
  );
};

export default RetailBulkUpdateBar;
