import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import moment from "moment";
import useApi from "api";
import useWidget from "hooks/useWidget";
import { ReactComponent as CampaignSettings } from "assets/icons/campaignSettings.svg";
import { ReactComponent as CheckOutlined } from "assets/icons/checkOutlined.svg";
import { ReactComponent as UpOutlined } from "assets/icons/upOutlined.svg";
import { numFormatter } from "utils/helpers";
import { Widgets } from "utils/types";
import RetailLineChart from "components/Charts/RetailLineChart";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import RetailCampaignSelectableCard from "./RetailCampaignSelectableCard";
import RetailCampaignWidgetCard from "./RetailCampaignWidgetCard";
import RetailCurrency from "components/Column/RetailCurrency";

import cc from "classcat";
import cm from "./style.module.scss";

interface SelectedOption {
  [key: string]: any;
}

export interface RetailCampaignWidgetProps {
  page: Widgets;
}

const RetailCampaignWidget = ({ page }: RetailCampaignWidgetProps) => {
  const { t, i18n } = useTranslation();

  const { data, error, loading, selectedWidgets, changeValue, dataType } =
    useWidget(page);

  const rowRef = useRef<HTMLDivElement>(null);

  const [hasScroll, setHasScroll] = useState(false);

  const [visible, setVisible] = useState(false);

  const [selectable, setSelectable] = useState(false);

  const [selected, setSelected] = useState<any>([]);

  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);

  const { roleForDashboard } = useApi();

  const isMetricExist = (key: string) => i18n.exists(`common.table.${key}`);

  const renderMetric = (key: string) =>
    isMetricExist(key)
      ? t(`common.table.${key}`)
      : t(`marketplaceDependentTable.${key}`);

  const handleOpen = (title: string) => {
    const selected = selectedOptions.find(
      (widget: any) => widget[0] === title
    )!;
    setSelected([
      {
        id: renderMetric(selected[0]),
        data: selected[1].data.map((item: any) => ({
          x: moment(item.date).format("DD/MM/YYYY"),
          y: item.value,
        })),
      },
    ]);
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setSelected([]);
  };

  const handleSelect = (title: string) => {
    if (selectedWidgets?.data[dataType()] === null) changeValue([title]);
    changeValue([...selectedWidgets?.data[dataType()], title]);
  };

  const handleDelete = (title: string) => {
    changeValue(
      selectedWidgets?.data[dataType()].filter((item: string) => item !== title)
    );
  };

  const handleClick = () => {
    setSelectable((prev) => !prev);
    handleClose();
  };

  useEffect(() => handleClose(), [i18n.language]);

  useEffect(() => {
    setSelectedOptions(
      data?.data &&
        selectedWidgets?.data[dataType()] &&
        Object.entries(data?.data.result).filter((item) => {
          return selectedWidgets?.data[dataType()].indexOf(item[0]) !== -1;
        })
    );
  }, [data?.data, selectedWidgets?.data, dataType()]);

  //Graph Component
  const graph = (
    <Row className={cm.container}>
      <Col span={24} className={cm.header}>
        <Col>
          <RetailTitle className={cm.graphTitle}>
            {selected.length > 0 ? selected[0].id : ""}
          </RetailTitle>
          <RetailText size="xs" className={cm.text}>
            {t("sider.user.campaign")}
          </RetailText>
        </Col>
        <UpOutlined className={cm.up} onClick={handleClose} />
      </Col>
      <Col span={24}>
        {selected && (
          <RetailLineChart height={116} data={selected} areaBaselineValue={0} />
        )}
      </Col>
    </Row>
  );

  const switchPrefix = (title: string, amount: string) => {
    switch (title) {
      case "sum_spend":
      case "sum_sale_amount":
      case "sum_direct_sale_amount":
      case "CPC":
      case "CPM":
        return <RetailCurrency amount={amount} />;
      case "CTR":
        return `%${amount}`;
      default:
        return amount;
    }
  };

  const LoadingSkeleton = () => (
    <Row
      className={cc([
        cm.skeletonRow,
        roleForDashboard === "ADVERTISER" && page === "campaigns"
          ? cm.advertiserRow
          : "",
      ])}
    >
      {[1, 2, 3, 4, 5].map((key) => (
        <Card className={cm.skeletonCard} key={key}>
          <Skeleton.Input
            active
            style={{
              width: 180,
              height: 22,
              marginBottom: 6,
            }}
          />
          <Skeleton.Input
            active
            style={{
              minWidth: 120,
              width: 120,
              height: 18,
              marginBottom: 4,
              backgroundColor: "var(--secondary-1)",
            }}
          />
          <Skeleton.Input
            active
            style={{
              minWidth: 150,
              width: 150,
              height: 14,
              lineHeight: 1,
              backgroundColor: "var(--secondary-1)",
            }}
          />
        </Card>
      ))}
    </Row>
  );

  /* const ErrorDisplay = () => (
    <Alert
      message={t("components.notification.statusError")}
      type="error"
      showIcon
    />
  ); */

  const handleScroll = () => {
    const element = rowRef.current;
    if (element) {
      const isNotAtEnd =
        Math.abs(
          element.scrollWidth - element.clientWidth - element.scrollLeft
        ) > 1;
      setHasScroll(isNotAtEnd);
    }
  };

  useLayoutEffect(() => {
    if (selectedOptions && selectedOptions.length > 0) {
      const scrollable =
        window.innerWidth / (selectedOptions?.length * 234) < 1.2;
      setHasScroll(scrollable);
    }
  }, [selectedOptions]);

  if (loading || selectedOptions === undefined || selectedOptions.length === 0)
    return <LoadingSkeleton />;
  if (error) return <></>;

  return (
    <>
      <Row
        className={cc([
          cm.row,
          roleForDashboard === "ADVERTISER" && page === "campaigns"
            ? cm.advertiserRow
            : "",
          hasScroll ? cm.scroll : "",
        ])}
        ref={rowRef}
        onScroll={handleScroll}
        id="widgets"
      >
        {selectable && data?.data.result
          ? Object.entries(data?.data.result).map(
              (widget: any, index: number) => (
                <RetailCampaignSelectableCard
                  title={widget[0]}
                  text={
                    widget[1].amount !== undefined
                      ? switchPrefix(
                          widget[0],
                          numFormatter(
                            widget[1].amount,
                            widget[0] === "sum_spend"
                          )
                        )
                      : "0"
                  }
                  key={index}
                  handleSelect={() => handleSelect(widget[0])}
                  handleDelete={handleDelete}
                  selectedOptions={selectedOptions}
                />
              )
            )
          : !loading &&
            selectedOptions?.map((widget: any, index: number) => (
              <RetailCampaignWidgetCard
                widget={widget}
                text={
                  widget[1].amount !== undefined
                    ? switchPrefix(
                        widget[0],
                        numFormatter(
                          widget[1].amount,
                          widget[0] === "sum_spend"
                        )
                      )
                    : "0"
                }
                selected={selected}
                key={index}
                handleOpen={() => handleOpen(widget[0])}
                handleClose={handleClose}
              />
            ))}
        <Card
          className={cc([cm.card, selectable ? cm.selectable : ""])}
          onClick={handleClick}
        >
          {selectable ? (
            <CheckOutlined className={cm.icon} />
          ) : (
            <CampaignSettings className={cm.icon} />
          )}
        </Card>
      </Row>
      {visible ? graph : null}
    </>
  );
};

export default RetailCampaignWidget;
